import React from 'react';

import data from '../../../common/stores/base/_data';
import { EmailInput, PasswordInput } from '../FormInput';
import BasicButton from '../BasicButton';

export default hot(module)(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static displayName = 'ResetPasswordPage';

  state = {
      newPassword: '',
      repeatNewPassword: '',
  };

  componentDidMount() {
      API.trackPage(Constants.pages.RESET_PASSWORD_PAGE);

      const { token } = Utils.fromParam();
      if (!token) {
          this.context.router.history.replace('/');
      } else {
          this.setState({ token });
          this.passwordInput.focus();
      }
  }

  isValid = () => {
      const { newPassword, repeatNewPassword } = this.state;
      if (!newPassword || !repeatNewPassword) return false;

      return true;
  }

  reset = () => {
      const { newPassword, repeatNewPassword, token } = this.state;
      if (newPassword !== repeatNewPassword) {
          this.setState({ error: 'Passwords do not match', newPassword: '', repeatNewPassword: '' });
          this.passwordInput.focus();
          return;
      }

      this.setState({ isSaving: true });
      data.post(`${Project.api}auth/resetpassword?token=${token}`, { new_password: newPassword })
          .then(() => {
              this.context.router.history.replace('/');
              toast('Password reset successfully');
          })
          .catch(() => this.setState({ isSaving: false, error: 'There was a problem resetting your password. Please try again later.' }));
  }

  render = () => {
      const { newPassword, repeatNewPassword, error, isSaving } = this.state;
      return (
          <div className="app-container centered-container">
              <div className="form-container">
                  <h2 className="text-center form-container__title">Reset Password</h2>
                  <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.reset();
                    }}
                    style={{ width: 300 }}
                  >
                      <Column className="mb-4">
                          <label className="label"> New Password</label>
                          <PasswordInput
                            className="full" placeholder="New Password..." value={newPassword}
                            onChange={e => this.setState({ newPassword: Utils.safeParseEventValue(e) })}
                            ref={c => this.passwordInput = c}
                          />
                      </Column>
                      <Column className="mb-4">
                          <label className="label">Repeat New Password</label>
                          <PasswordInput
                            className="full" placeholder="Repeat New Password..." value={repeatNewPassword}
                            onChange={e => this.setState({ repeatNewPassword: Utils.safeParseEventValue(e) })}
                          />
                      </Column>
                      <Column className="text-center">
                          {isSaving ? <Loader /> : (
                              <BasicButton
                                type="submit" disabled={!this.isValid()} buttonText="Confirm"
                                className="btn--full mt-4"
                              />
                          )}
                      </Column>
                  </form>
                  {error ? (
                      <Column className="text-center"><p className="text-center mt-3">{error}</p></Column>
                  ) : null}
              </div>
          </div>
      );
  }
});
