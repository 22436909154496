const Actions = Object.assign({}, require('./base/_action-constants'), {
    'GET_PROJECTS': 'GET_PROJECTS',
    'GET_PROJECT': 'GET_PROJECT',
    'ON_LOGIN': 'ON_LOGIN',
    'ON_LOGOUT': 'ON_LOGOUT',
    'GET_ACCOUNT_STATS': 'GET_ACCOUNT_STATS',
    'GET_INSTANCE_STATS': 'GET_INSTANCE_STATS',
    'GET_USERS_FOR_ACCOUNT': 'GET_USERS_FOR_ACCOUNT',
    'GET_DOCUMENT_ASSETS': 'GET_DOCUMENT_ASSETS',
    'GET_DOCUMENT_STATS': 'GET_DOCUMENT_STATS',
    'GET_USERS': 'GET_USERS',
    'GET_USERS_FOR_ITEM': 'GET_USERS_FOR_ITEM',
    'GET_USER': 'GET_USER',
    'GET_USER_DOCUMENTS': 'GET_USER_DOCUMENTS',
    'GET_USER_INSTANCES': 'GET_USER_INSTANCES',
    'CREATE_ACCOUNT': 'CREATE_ACCOUNT',
    'CREATE_PROJECT': 'CREATE_PROJECT',
    'CREATE_COLLECTION': 'CREATE_COLLECTION',
    'CREATE_DOCUMENT': 'CREATE_DOCUMENT',
    'EDIT_COLLECTION': 'EDIT_COLLECTION',
    'EDIT_PROJECT': 'EDIT_PROJECT',
    'EDIT_ACCOUNT': 'EDIT_ACCOUNT',
    'EDIT_DOCUMENT': 'EDIT_DOCUMENT',
    'DELETE_DOCUMENT': 'DELETE_DOCUMENT',
    'UPDATE_DOCUMENT_ASSETS': 'UPDATE_DOCUMENT_ASSETS',
    'DELETE_DOCUMENT_ASSETS': 'DELETE_DOCUMENT_ASSETS',
    'DELETE_COLLECTION': 'DELETE_COLLECTION',
    'DELETE_PROJECT': 'DELETE_PROJECT',
    'PUBLISH_DOCUMENT': 'PUBLISH_DOCUMENT',
    'UNPUBLISH_DOCUMENT': 'UNPUBLISH_DOCUMENT',
    'UPDATE_ACCOUNT_PERMISSIONS_FOR_USER': 'UPDATE_ACCOUNT_PERMISSIONS_FOR_USER',
    'UPDATE_ITEM_PERMISSIONS_FOR_USER': 'UPDATE_ITEM_PERMISSIONS_FOR_USER',
    'INVITE_USERS_TO_ACCOUNT': 'INVITE_USERS_TO_ACCOUNT',
    'INVITE_USERS_TO_ITEM': 'INVITE_USERS_TO_ITEM',
    'GET_DOCUMENT': 'GET_DOCUMENT',
    'DEACTIVATE_USERS': 'DEACTIVATE_USERS',
    'ACTIVATE_USERS': 'ACTIVATE_USERS',
    'REMOVE_ACCOUNT_USERS': 'REMOVE_ACCOUNT_USERS',
    'REMOVE_ACCOUNT_PERMISSIONS_FOR_USERS': 'REMOVE_ACCOUNT_PERMISSIONS_FOR_USERS',
    'REMOVE_ITEM_PERMISSIONS_FOR_USERS': 'REMOVE_ITEM_PERMISSIONS_FOR_USERS',
    'SET_USER_ROLE': 'SET_USER_ROLE',
    'TOGGLE_USER_ADMIN_STATUS': 'TOGGLE_USER_ADMIN_STATUS',
    'JOIN_ACCOUNT': 'JOIN_ACCOUNT',
    'REFRESH_USER': 'REFRESH_USER',
    'GET_ACCOUNT_HIERARCHY': 'GET_ACCOUNT_HIERARCHY',
    'TOGGLE_PRESENTATION_MODE': 'TOGGLE_PRESENTATION_MODE',
    'GET_ALL_DOCUMENT_DATA': 'GET_ALL_DOCUMENT_DATA',
    'MAKE_DOCUMENT_PUBLIC': 'MAKE_DOCUMENT_PUBLIC',
    'MAKE_DOCUMENT_PRIVATE': 'MAKE_DOCUMENT_PRIVATE',
});

global.Actions = Actions;
module.exports = Actions;
