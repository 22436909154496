const BaseStore = require('./base/_store');

const store = Object.assign({}, BaseStore, {
    id: 'config',
    presentationMode: false,
    presentationModeEnabled: () => {
        return store.presentationMode;
    },
});

const controller = {
    get() {
        store.loading();
        bulletTrain.init({
            environmentID: Project.bulletTrain,
            onChange: controller.loaded,
        });
    },
    loaded(oldFlags) { // Occurs whenever flags are changed
        const hasLoaded = !store.model;
        store.model = bulletTrain.getAllFlags();
        if (hasLoaded) {
            store.loaded();
        } else {
            store.changed();
        }
    },
    togglePresentationMode() {
        store.presentationMode = !store.presentationMode;
        if (store.presentationMode) {
            AsyncStorage.setItem('presentationMode', 'true');
        } else {
            AsyncStorage.removeItem('presentationMode');
        }
        store.changed();
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from	handleViewAction

    switch (action.actionType) {
        case Actions.GET_CONFIG:
            controller.get();
            break;
        case Actions.TOGGLE_PRESENTATION_MODE:
            controller.togglePresentationMode();
            break;
        default:
    }
});

controller.store = store;
module.exports = controller.store;
