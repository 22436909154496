/**
 * Created by andrewmoffatt on 12/03/2019.
 */
import React from 'react';

import { TextInput } from '../FormInput';
import BasicButton from '../BasicButton';
import PageController from '../PageController';
import InviteUsers from '../InviteUsers';
import TableActionButton from '../TableActionButton';

export default hot(module)(class extends React.Component {
    static displayName = 'CreateCollectionModal';

    static propTypes = {
        accountId: propTypes.number.isRequired,
        projectId: propTypes.number.isRequired,
        collectionId: propTypes.number,
        edit: propTypes.bool,
        collection: propTypes.object,
        onDelete: propTypes.func,
    }

    state = {
        name: '',
    };

    componentWillMount() {
        if (this.props.edit) {
            this.setState(Object.assign({}, this.state, this.props.collection));
        }
    }

    create = () => {
        const { collectionId, accountId } = this.props;
        AppActions.createCollection(accountId, collectionId, this.state);
    }

    edit = () => {
        AppActions.editCollection(this.props.collection.id, this.state);
    }

    valid = () => {
        const { name } = this.state;
        return name.length;
    }

    changed = () => {
        let changed = false;
        const { props: { collection }, state: { name } } = this;
        if (name !== collection.name) {
            changed = true;
        }

        return changed;
    }

    onSave = (collectionId) => {
        if (this.props.edit || !AccountStore.isAdmin()) {
            closeModal();
            return;
        }
        this.setState({ invite: !this.state.invite, projectId: this.props.projectId, collectionId });
    }

    render = () => {
        const { state: { invite, name }, props: { accountId, projectId, collectionId, edit, onDelete } } = this;
        return (
            <div className="fullscreen--modal content-pane">
                <ProjectProvider projectId={projectId} id={collectionId || projectId} onSave={this.onSave}>
                    {({ isSaving, error }) => (
                        <React.Fragment>
                            <Row className="justify-content-end">
                                <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                                    <img className="" src="/images/icons/remove.svg" alt="Remove" />
                                </button>
                            </Row>
                            <div className="col-lg-8 m-auto">
                                <div className="fullscreen-modal__content">
                                    <Row>
                                        <h2 className="flex-1 mb-1 mb-sm-5 mt-4 mt-sm-0 ">{`${edit ? 'Edit' : 'Create'} Collection`}</h2>
                                        {!edit ? <PageController pageCount={2} activePageIndex={invite ? 1 : 0}/> : null}
                                    </Row>
                                    {!invite ? (
                                        <form
                                          className="form" onSubmit={(e) => {
                                              e.preventDefault();
                                              if (edit) {
                                                  this.edit();
                                              } else {
                                                  this.create();
                                              }
                                          }}
                                        >
                                            <div className="form__content">
                                                <Column className="flex-1 mb-4 mt-4 mx-0">
                                                    <label>Collection Details</label>
                                                    <TextInput
                                                      placeholder="Collection Name..." onChange={e => this.setState({ name: Utils.safeParseEventValue(e) })}
                                                      value={name}
                                                    />
                                                </Column>
                                            </div>

                                            {edit ? (
                                                <TableActionButton
                                                  labelText="Delete" labelSubtext="this collection" src="/images/icons/trash--red.svg"
                                                  alt="Delete" onClick={onDelete} className="mt-5 mr-4 btn--negative"
                                                />
                                            ) : null}

                                            <div className="form__footer mt-5">
                                                {error ? (
                                                    <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                                                ) : null}
                                                <Row className="justify-content-center">
                                                    <BasicButton buttonText="Cancel" className="btn--transparent flex-grow-1 flex-sm-grow-0 order-1 order-sm-0" onClick={() => closeModal()} />
                                                    {edit ? (
                                                        <BasicButton
                                                          type="submit"
                                                          buttonText={isSaving ? 'Saving...' : 'Save Changes'} className=" flex-grow-1 flex-sm-grow-0" disabled={!this.valid() || !this.changed()}
                                                        />
                                                    ) : (
                                                        <BasicButton
                                                          type="submit"
                                                          buttonText={isSaving ? 'Creating...' : 'Create'} className=" flex-grow-1 flex-sm-grow-0" disabled={!this.valid()}
                                                        />
                                                    )}
                                                </Row>
                                            </div>
                                        </form>
                                    ) : (
                                        <InviteUsers accountId={accountId} itemId={this.state.collectionId} />
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </ProjectProvider>
            </div>
        );
    }
});
