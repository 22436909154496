// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export default class StatCard extends PureComponent {
  static displayName = 'StatCard';

  static propTypes = {
      content: propTypes.node.isRequired,
      footer: propTypes.node.isRequired,
      progress: propTypes.number,
      className: propTypes.string,
      onClick: propTypes.func,
      title: propTypes.node,
  };

  render() {
      const { props: {
          title, content, footer, progress, onClick, className,
      } } = this;
      return (
          <div
            className={cn('stat-card', className)}
            role="button"
            onClick={onClick}
            style={onClick && { cursor: 'pointer' }}
          >
              <Row className="stat-card__header mb-2" space>
                  <p className="muted">{title}</p>
                  {!!onClick && (
                  <span className="stat-card__icon" onClick={this.onClick} role="button">
                      <img src="/images/icons/pencil-white.svg" alt="Edit"/>
                  </span>
                  )}
              </Row>
              <div className="stat-card__content">
                  <h2 className="stat-card__metric">{content}</h2>
                  <p className="stat-card__text muted mb-4">{footer}</p>
              </div>
              {!!progress && (
              <div className="stat-card__progress progress">
                  <div className="progress__inner" style={{ width: `${progress}%` }}/>
              </div>
              )}
          </div>
      );
  }
}

export class AllUsersStatCard extends PureComponent {
  static propTypes = {
      users: propTypes.arrayOf(propTypes.shape({
          first_name: propTypes.string.isRequired,
          last_name: propTypes.string.isRequired,
          image: propTypes.string,
      })),
      footer: propTypes.node.isRequired,
      onClick: propTypes.func,
      title: propTypes.node,
  };

  render() {
      const { props: { users = [], ...props } } = this;
      return (
          <StatCard
            content={(
                <Row>
                    {users.length}
                    <Row className="ml-2 d-none d-sm-block">
                        {_.take(users, 10).map(({ id, first_name, last_name, image }) => (
                            <img
                              key={id}
                              alt={`${first_name} ${last_name}`}
                              src={image || Constants.avatar}
                              className="stat-card__member round"
                            />
                        ))}
                    </Row>
                </Row>
              )}
            {...props}
          />
      );
  }
}
export class UsersStatCard extends PureComponent {
  static propTypes = {
      users: propTypes.arrayOf(propTypes.shape({
          first_name: propTypes.string.isRequired,
          last_name: propTypes.string.isRequired,
          avatar_url: propTypes.string,
      })),
      footer: propTypes.node.isRequired,
      onClick: propTypes.func,
      title: propTypes.node,
  };

  render() {
      const { props: { users = [], ...props } } = this;
      return (
          <StatCard
            content={(
                <Row>
                    {users.length}
                    <Row className="ml-2 d-none d-sm-block">
                        {_.take(users, 10).map(({ id, first_name, last_name, avatar_url }) => (
                            <img
                              key={id}
                              alt={`${first_name} ${last_name}`}
                              src={avatar_url || Constants.avatar}
                              className="stat-card__member round"
                            />
                        ))}
                    </Row>
                </Row>
              )}
            {...props}
          />
      );
  }
}
