import React from 'react';
import { EmailInput, PasswordInput } from '../FormInput';
import BasicButton from '../BasicButton';
import ForgotPasswordModal from '../modals/ForgotPassword';
import ConfigProvider from '../../../common/providers/ConfigProvider';

export default hot(module)(ConfigProvider(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static displayName = 'LoginPage';

  static propTypes = {
      getValue: propTypes.func,
  }

  state = Constants.simulate.AUTOFILL_LOGIN ? {
      email: 'john.smith@example.com',
      password: 'password',
  } : {
      email: this.props.getValue('test_username') || '',
      password: this.props.getValue('test_password') || '',
  };

  componentDidMount() {
      API.trackPage(Constants.pages.LOGIN_PAGE);

      const { email } = Utils.fromParam();
      if (email) {
          this.setState({ email, password: '' });
      }
  }

  canLogin = () => {
      return Utils.isValidEmail(this.state.email) && this.state.password;
  }

  forgotPassword = () => {
      openModal({
          body: <ForgotPasswordModal />,
          className: 'modal--default',
      });
  }

  onConfigLoaded = () => {
      if (this.props.getValue('test_username')) {
          this.setState({
              email: this.props.getValue('test_username'),
              password: this.props.getValue('test_password'),
          });
      }
  }

  render = () => {
      const { email, password } = this.state;
      return (
          <div className="app-container centered-container">
              <AccountProvider>
                  {({ isLoading, error }, { login, clearError }) => (
                      <div className="form-container">
                          <div className="text-center">
                              <img src="/images/icons/publicate_logo.png" alt="Publicate"/>
                              <h3 className="text-center form-container__title my-3">Login</h3>
                          </div>
                          <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                clearError();
                                login({ email, password });
                            }}
                            style={{ width: 300 }}
                          >
                              <Column className="mb-2">
                                  <label className="label mb-0 mt-3">Email address</label>
                                  <EmailInput
                                    className="full" placeholder="Email address..." value={email}
                                    onChange={e => this.setState({ email: Utils.safeParseEventValue(e) })}
                                  />
                              </Column>
                              <Column className="mb-4">
                                  <label className="label mb-0 mt-3">Password</label>
                                  <PasswordInput
                                    className="full" placeholder="Password..." value={password}
                                    onChange={e => this.setState({ password: Utils.safeParseEventValue(e) })}
                                  />
                              </Column>
                              <Column className="text-center">
                                  {isLoading ? <Loader /> : (
                                      <React.Fragment>
                                          <BasicButton
                                            type="submit" disabled={!this.canLogin()} buttonText="Login"
                                            className="btn--full mt-4 "
                                          />
                                          <div className="link-style mt-2" role="button" onClick={this.forgotPassword}>Forgot Password?</div>
                                      </React.Fragment>
                                  )}
                              </Column>
                              {error ? (
                                  <Column className="text-center"><p className="text-center mt-3">{error}</p></Column>
                              ) : null}
                          </form>
                      </div>
                  )}
              </AccountProvider>
          </div>
      );
  }
}));
