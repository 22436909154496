import React from 'react';

import data from '../../../common/stores/base/_data';
import BasicButton from '../BasicButton';
import { TextInput, SelectInput } from '../FormInput';

export default hot(module)(class extends React.Component {
    static displayName = 'ForgotPasswordModal';

    static propTypes = {
    }

    state = {
        email: '',
    }

    componentDidMount() {
        setTimeout(this.emailInput.focus, 500);
    }

    sendPasswordReset = () => {
        const { state: { email } } = this;
        this.setState({ isSaving: true });
        data.post(`${Project.api}auth/sendpasswordreset`, { base_password_reset_url: `${window.location.origin}/reset-password`, email })
            .then(() => {
                toast('Sent password reset email successfully');
                closeModal();
            })
            .catch(() => this.setState({ isSaving: false, error: 'Sorry there was a problem sending you a password reset email. Please try again later.' }));
    }

    onSave = () => {
        closeModal();
    }

    render = () => {
        const { state: { email, isSaving, error } } = this;
        return (
            <div className="confirm-content-pane">
                <div className="col-lg-10 m-auto">
                    <div className="text-center">
                        <h2 className="mb-1">Reset Password</h2>
                        <Column className="flex-1 mx-0 mb-4">
                            <label>Please enter your email address and we'll send you an email to reset your password</label>
                            <TextInput
                              placeholder="Email address..." onChange={e => this.setState({ email: Utils.safeParseEventValue(e) })} className="full"
                              value={email} ref={c => this.emailInput = c}
                            />
                        </Column>
                        <Row className="justify-content-center">
                            <BasicButton buttonText="Cancel" className="btn--transparent flex-grow-1 flex-sm-grow-0 order-1 order-sm-0" onClick={() => closeModal()} />
                            <BasicButton
                              buttonText={isSaving ? 'Sending..' : 'Send'} className="flex-grow-1 flex-sm-grow-0" disabled={isSaving || !Utils.isValidEmail(email)}
                              onClick={this.sendPasswordReset}
                            />
                        </Row>
                        {error ? (
                            <Column className="text-center"><p className="text-center mt-3">{error}</p></Column>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
});
