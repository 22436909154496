// import propTypes from 'prop-types';
import React, { PureComponent, Component } from 'react';
import cn from 'classnames';
import { SketchPicker } from 'react-color';
import Select from 'react-select';

export class TextInput extends PureComponent {
  static displayName = 'TextInput';

  static propTypes = {};

  focus = () => {
      this.input.focus();
  }

  render() {
      const { props: { className, ...rest } } = this;
      return (
          <input
            ref={c => this.input = c} type="text" className={cn('input', className)}
            {...rest}
          />
      );
  }
}

export class TextArea extends PureComponent {
    static displayName = 'TextArea';

    static propTypes = {};

    render() {
        const { props: { className, ...rest } } = this;
        return (
            <textarea className={cn('input textarea', className)} {...rest} />
        );
    }
}

export class EmailInput extends PureComponent {
    static displayName = 'EmailInput';

    static propTypes = {};

    render() {
        const { props: { className, ...rest } } = this;
        return (
            <input type="email" className={cn('input', className)} {...rest}/>
        );
    }
}

export class PasswordInput extends PureComponent {
    static displayName = 'PasswordInput';

    static propTypes = {};

    focus = () => {
        this.input.focus();
    }

    render() {
        const { props: { className, ...rest } } = this;
        return (
            <input
              ref={c => this.input = c} type="password" className={cn('input', className)}
              {...rest}
            />
        );
    }
}

export class CheckboxInput extends PureComponent {
    static displayName = 'CheckboxInput';

    static propTypes = {};

    render() {
        const { props: { name, className, ...rest } } = this;
        return (
            <Row>
                <input
                  name={name} type="checkbox" className={cn('checkbox', className)}
                  {...rest}
                />
                <label htmlFor={name} className="ml-2 mb-0 text-small">{name}</label>
            </Row>
        );
    }
}

export class RadioInput extends PureComponent {
    static displayName = 'RadioInput';

    static propTypes = {};

    render() {
        const { props: { className, ...rest } } = this;
        return (
            <input type="radio" className={cn('radio', className)} {...rest}/>
        );
    }
}

export class SelectInput extends PureComponent {
    static displayName = 'SelectInput';

    static propTypes = {};

    render() {
        const { props: { className, ...rest } } = this;
        return (
            <select className={cn('input', className)} {...rest}>
                {this.props.children}
            </select>
        );
    }
}

export class SearchableSelectInput extends PureComponent {
    static displayName = 'SelectInput';

    static propTypes = {
        className: propTypes.string,
        children: propTypes.node,
        defaultValue: propTypes.object,
    };

    render() {
        const { props: { className, children, defaultValue, ...rest } } = this;
        return (
            <Select
              className={cn('input', className)}
              styles={Constants.reactSelectStyles}
              options={children}
              defaultValue={defaultValue}
              {...rest}
            />
        );
    }
}

export class ColourSelectInput extends PureComponent {
    static displayName = 'ColourSelectInput';

    static propTypes = {
        className: propTypes.string,
        colour: propTypes.string,
    };

    render() {
        const { props: { className, colour, ...rest } } = this;
        return (
            <Row>
                <div className="swatch" style={{ backgroundColor: colour }} />
                <select className={cn('input select--swatch', className)} {...rest}>
                    {this.props.children}
                </select>
            </Row>
        );
    }
}

export class ColourInput extends Component {
    static displayName = 'ColourInput';

    static propTypes = {
        className: propTypes.string,
        colour: propTypes.string,
        onChange: propTypes.func,
        onChangeText: propTypes.func,
        popoverClassName: propTypes.string,
    };

    state = {};

    componentDidUpdate(prevProps, prevState) {
        if (document.body.classList.contains('modal-open')) {
            if (!prevState.displayPicker && this.state.displayPicker) {
                $('.modal-content').append('<div class="modal-cover" role="button" />');
                $('.modal-cover').click(() => this.setState({ displayPicker: false }));
            } else if (prevState.displayPicker && !this.state.displayPicker) {
                $('.modal-cover').remove();
            }
        }
    }

    render() {
        const { props: { className, colour, onChange, onChangeText, popoverClassName, ...rest }, state: { displayPicker } } = this;
        return (
            <Row className="colour-input">
                {displayPicker ? (
                    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
                        <div className={`popover${popoverClassName ? ` ${popoverClassName}` : ''}`}>
                            <div className="cover" role="button" onClick={() => this.setState({ displayPicker: false })}/>
                            <SketchPicker color={colour || ''} onChangeComplete={onChange} />
                        </div>
                    </div>
                ) : null}
                <div
                  className="swatch" role="button" onClick={() => this.setState({ displayPicker: !displayPicker })}
                  style={{ backgroundColor: colour }}
                />

                <input
                  type="text" className={cn('input input--swatch', className)} value={colour || ''}
                  onChange={onChangeText}
                  {...rest}
                />
            </Row>
        );
    }
}

export class VersionInput extends Component {
    static displayName = 'VersionInput';

    static propTypes = {
        className: propTypes.string,
        onChange: propTypes.func,
        value: propTypes.string,
    };

    state = {};

    render() {
        const { props: { containerClassName, className, onChange, value, ...rest }, state: { displayPicker } } = this;
        return (
            <Column className={containerClassName}>
                <p className="text-small muted">Version</p>
                <input
                  type="text" placeholder="1.0" className={cn('input input--version', className)}
                  onChange={onChange}
                  value={value}
                  {...rest}
                />
            </Column>
        );
    }
}
