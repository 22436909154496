const BaseStore = require('./base/_store');
const data = require('./base/_data');
const endpoints = require('./endpoints');

const store = Object.assign({}, BaseStore, {
    id: 'item-users',
    getUsers() {
        return bulletTrain.hasFeature('no_users') ? [] : store.model;
    },
});

const controller = {
    getUsersForItem(itemId, isDocument) {
        store.loading();
        if (itemId !== store.itemId) {
            store.model = [];
        }
        store.itemId = itemId;
        return endpoints
            .getUsersForItem(itemId, isDocument)
            .then(controller.loaded)
            .catch((error) => {
                API.handleError(store, error);
            });
    },
    loaded(res) {
        store.model = _.sortBy(res, ({ user }) => user.join_date);
        store.loaded();
    },
    inviteUsers(id, users, isDocument) {
        store.saving();
        const func = isDocument ? endpoints.inviteUsersToDocument : endpoints.inviteUsersToInstance;
        func(id, {
            base_invite_url: `${window.location.origin}/register`,
            invites: users,
        })
            .then(() => {
                toast(`User${users.length > 1 ? 's' : ''} invited successfully`);
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    updatePermissionsForUser(itemId, userId, permissionType, isDocument) {
        store.saving();
        const func = isDocument ? endpoints.updateDocumentPermissionsForUser : endpoints.updateInstancePermissionsForUser;
        func(itemId, userId, permissionType)
            .then(() => controller.getUsersForItem(itemId, isDocument))
            .then(() => {
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    removePermissionsForUsers(itemId, users, isDocument) {
        store.saving();
        const func = isDocument ? endpoints.removeDocumentPermissionsForUsers : endpoints.removeInstancePermissionsForUsers;
        func(itemId, users)
            .then(() => controller.getUsersForItem(itemId, isDocument))
            .then(() => {
                toast(`Removed user${users.length > 1 ? 's' : ''} successfully`);
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_USERS_FOR_ITEM:
            controller.getUsersForItem(action.itemId, action.isDocument, action.force);
            break;
        case Actions.INVITE_USERS_TO_ITEM:
            controller.inviteUsers(action.id, action.users, action.isDocument);
            break;
        case Actions.UPDATE_ITEM_PERMISSIONS_FOR_USER:
            controller.updatePermissionsForUser(action.itemId, action.userId, action.permissionType, action.isDocument);
            break;
        case Actions.REMOVE_ITEM_PERMISSIONS_FOR_USERS:
            controller.removePermissionsForUsers(action.itemId, action.users, action.isDocument);
            break;
        case Actions.ON_LOGOUT:
            store.model = null;
            store.error = null;
            break;
        default:
    }
});

// AsyncStorage.getItem('projects', (err, res) => {
//     if (res) {
//         store.model = JSON.parse(res);
//     }
// });

controller.store = store;
module.exports = controller.store;
