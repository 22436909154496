import React, { Component, PropTypes } from 'react';
import AccountHierarchyStore from '../stores/account-hierarchy-store';

const TheComponent = class extends Component {
    static displayName = 'AccountHierarchyProvider'

    static propTypes = {
        accountId: propTypes.number,
    }

    constructor(props, context) {
        super(props, context);
        this.state = { // Project List Store State
            isLoading: !AccountHierarchyStore.model,
            hierarchy: AccountHierarchyStore.getHierarchy(),
        };
    }

    componentDidMount() { // Get Projects on mount, listen to store changing
        ES6Component(this);
        if (!AccountHierarchyStore.model || AccountHierarchyStore.accountId !== this.props.accountId) {
            AppActions.getAccountHierarchy(this.props.accountId);
        }
        this.listenTo(AccountHierarchyStore, 'change', () => {
            this.setState({
                isLoading: AccountHierarchyStore.isLoading,
                error: AccountHierarchyStore.error,
                hierarchy: AccountHierarchyStore.getHierarchy(),
            });
        });
    }

    refresh = () => {
        this.setState({ isRefreshing: true }, () => {
            AppActions.getAccountHierarchy(this.props.accountId);
        });
    };

    render() { // Render with project store state and sort function
        const { isLoading, hierarchy, isRefreshing, error } = this.state;
        const { sort, refresh } = this;
        return (
            this.props.children({
                isLoading,
                error,
                isRefreshing,
                hierarchy,
            }, { sort, refresh })
        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
