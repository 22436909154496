import Color from 'color';

const Utils = Object.assign({}, require('./base/_utils'), {

    getTileDimensions(props) {
        let numColumns = 6;

        if (props.isPhone) {
            if (props.isLandscape) {
                numColumns = 6;
            } else {
                numColumns = 2;
            }
        } else if (props.isPortrait) {
            numColumns = 4;
        }

        let portraitWidth = (props.DeviceWidth - styleVariables.tilePadding) / numColumns;
        let landscapeWidth = (props.DeviceWidth - styleVariables.tilePadding) / (numColumns) * 2;
        let height = portraitWidth * (4 / 3);
        if (height > props.DeviceHeight) {
            height = props.DeviceHeight - 75;
            portraitWidth = (height * 3 / 4);
            landscapeWidth = (height * 3 / 4) * 2;
        }
        return {
            height,
            portraitWidth,
            landscapeWidth,
            numColumns,
            orientation: props.isLandscape,
            DeviceWidth: props.DeviceWidth,
            DeviceHeight: props.DeviceHeight,
        };
    },

    getFractionalDimensions(props) {
        let { height, portraitWidth, landscapeWidth, numColumns, orientation, DeviceWidth, DeviceHeight } = props;
        // portraitWidth *= 1.25;
        // landscapeWidth *= 1.25;
        portraitWidth *= 1.25;
        landscapeWidth *= 1.25;
        height = portraitWidth * (4 / 3);
        if (height > props.DeviceHeight) {
            height = props.DeviceHeight - 75;
            portraitWidth = (height * 3 / 4);
            landscapeWidth = (height * 3 / 4) * 2;
        }

        return { height, portraitWidth, landscapeWidth, numColumns, orientation, DeviceWidth, DeviceHeight };
    },

    convertToTheme(settings) {
        if (!settings) return {};

        return Object.assign({
            primary: settings && settings.brand_colour,
            primaryEnd: Utils.getGradientColor(settings && settings.brand_colour),
            theme: settings.theme,
        });
    },
    getRgb(hex) {
        const color = Color(hex).rgb().array();
        return [color[0] / 255, color[1] / 255, color[2] / 255, 1];
    },
    getGradientColor(hex) {
        const color = Color(hex);
        const lum = color.luminosity();
        if (lum == 0) {
            return '#333333';
        }
        return lum < 0.2 ? color.lighten(0.5).hex() : color.darken(0.5).hex();
    },
    isValidHexColour(color) {
        if (!color || typeof color !== 'string') return false;

        // Validate hex values
        if (color.substring(0, 1) === '#') color = color.substring(1);

        switch (color.length) {
            case 3: return /^[0-9A-F]{3}$/i.test(color);
            case 6: return /^[0-9A-F]{6}$/i.test(color);
            case 8: return /^[0-9A-F]{8}$/i.test(color);
            default: return false;
        }
    },
    isFolderDrop(files) {
        if (files[0].path[0] === '/') {
            const folderName = `/${files[0].path.split('/')[1]}`;
            return _.every(files, file => file.path.indexOf(folderName) === 0);
        }
        return false;
    },
    parseDocumentFilePath(path, isFolderDrop) {
        return !isFolderDrop ? _.trimStart(path, '/') : path.substr(path.substr(1).indexOf('/') + 2);
    },
    parseDocumentAssetFilename(file_location, type) {
        const match = file_location.match(`(documents\\/${type.toLowerCase()}\\/\\d+\\/)(.+)`, 'g');
        return (match && match.length >= 3) ? match[2] : '';
    },
    getAccountId(props) {
        const user = AccountStore.getUser();
        return (AccountStore.hasMultipleAccounts() || AccountStore.isAdmin()) ? parseInt(props.accountId) : user.accounts.length ? user.accounts[0].account.id : null;
    },
    getAccountName(props) {
        const user = AccountStore.getUser();
        return AccountStore.hasMultipleAccounts() ? props.accountId ? _.find(user.accounts, ({ account }) => account.id === parseInt(props.accountId)).account.name : '' : user.accounts.length ? user.accounts[0].account.name : '';
    },
    getItemsFromHierarchy(data, documents) {
        let items = [];
        _.each(data.collections || data.folders, (collection) => {
            items = items.concat(Utils.getItemsFromHierarchy(collection, documents));
        });
        if (documents) {
            items = items.concat(data.documents || []);
        } else {
            items = items.concat(data.collections || data.folders || []);
        }
        return items;
    },
});

global.Utils = Utils;
module.exports = Utils;
