/**
 * Created by andrewmoffatt on 14/03/2019.
 */
// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

import BasicButton from '../BasicButton';
import DocumentTable, { FileUpdateRow } from '../DocumentTable';

export default class UpdateDocumentChanges extends PureComponent {
    static displayName = 'UpdateDocumentChanges';

    static propTypes = {
        files: propTypes.array,
        next: propTypes.func,
        isPublishing: propTypes.bool,
        error: propTypes.string,
    };

    render() {
        const { props: { className, files, next, isPublishing, error, ...rest } } = this;
        const numFilesUpdated = _.filter(files, file => !file.ignore).length;
        return (
            <div className={cn('content', className)} {...rest}>
                <div className="col-md-8 offset-md-2 px-0">
                    <p className="text-left mb-4 mt-4 mt-sm-0">
Great news! We've updated
                        {' '}
                        {numFilesUpdated}
                        {' '}
                        {numFilesUpdated > 1 ? 'files' : 'file'}
                        {' '}
successfully.
                    </p>
                    <DocumentTable uploaded>
                        {_.map(files, file => (
                            <FileUpdateRow key={file.path} file={file} />
                        ))}
                    </DocumentTable>
                    <p className="text-center mt-5">A draft version has been created with these changes but this has not yet been published to replace your live document.  Do you wish to publish these updates now?</p>
                </div>
                <div className="form__footer mt-5">
                    {error ? (
                        <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                    ) : null}
                    <Row className="justify-content-center">
                        <BasicButton
                          buttonText="I'll do it later" className="btn--transparent order-1 order-sm-0" onClick={() => closeModal()}
                          disabled={isPublishing}
                        />
                        <BasicButton buttonText={isPublishing ? 'Publishing..' : 'Publish now'} onClick={next} disabled={isPublishing} />
                    </Row>
                </div>
            </div>
        );
    }
}
