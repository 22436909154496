import React from 'react';

import ProjectStore from '../../../common/stores/project-store';
import ProjectListStore from '../../../common/stores/project-list-store';
import ProfileHero from '../ProfileHero';
import Table, { CollectionRow, DocumentRow, ProjectRow } from '../ItemTable';
import RecentActivityTable, { ActivityRow } from '../RecentActivityTable';
import DeleteItemModal from '../modals/DeleteItem';
import EditProfileModal from '../modals/EditProfile';

export default hot(module)(class extends React.Component {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static propTypes = {
        userId: propTypes.string,
    }

    static displayName = 'ProfilePage';

    state = {};

    componentDidMount() {
        API.trackPage(Constants.pages.PROFILE_PAGE);
        ES6Component(this);
        this.listenTo(ProjectStore, 'saved', () => {
            this.userProvider.refresh();
        });
        this.listenTo(ProjectListStore, 'saved', () => {
            this.userProvider.refresh();
        });
    }

    onError = (error) => {
        this.context.router.history.replace('/dashboard');
        toast(error);
    }

    onUserNotFound = () => {
        this.context.router.history.replace('/dashboard');
        toast(Constants.errors.NO_USER);
    }

    deleteProject = (project) => {
        openModal({
            body: <DeleteItemModal name={project.name} type="project" onConfirm={() => AppActions.deleteProject(project.id)} />,
            className: 'modal--default',
        });
    }

    deleteCollection = (collection) => {
        openModal({
            body: <DeleteItemModal name={collection.name} type="collection" onConfirm={() => AppActions.deleteCollection(collection.id)} />,
            className: 'modal--default',
        });
    }

    deleteDocument = (document) => {
        openModal({
            body: <DeleteItemModal name={document.name} type="document" onConfirm={() => AppActions.deleteDocument(document.id)} />,
            className: 'modal--default',
        });
    }

    onEdit = () => {
        openModal({
            body: <EditProfileModal />,
            className: 'modal--fullscreen',
        });
    }

    render = () => {
        const { userId } = this.props;
        return (
            <div className="full">
                <UserProvider
                  ref={c => this.userProvider = c}
                  userId={userId}
                  onError={this.onError}
                  onUserNotFound={this.onUserNotFound}
                >
                    {({ isLoading, error, user, documents, instances }) => {
                        if (error) return <p>Error retriving user</p>;
                        if (!user) return <Loader />;
                        return (
                            <div className="mb-5">
                                <ProfileHero user={user} documentCount={documents.length} onEdit={this.onEdit} />
                                <div className="container app-container mt-5">
                                    <h4>Document Access</h4>
                                    <div className="row mb-5">
                                        <div className="col-md-12">
                                            <Table>
                                                {_.map(instances, ({ instance, permission_type }) => {
                                                    return instance.project
                                                        ? (
                                                            <ProjectRow
                                                              key={instance.id} project={instance} permissionType={permission_type}
                                                              onDelete={() => this.deleteProject(instance)}
                                                            />
                                                        )
                                                        : (
                                                            <CollectionRow
                                                              key={instance.id} collection={instance} permissionType={permission_type}
                                                              onDelete={() => this.deleteCollection(instance)}
                                                            />
                                                        );
                                                })}
                                                {_.map(documents, ({ document, permission_type }) => (
                                                    <DocumentRow
                                                      key={`doc-${document.id}`} document={document} permissionType={permission_type}
                                                      onDelete={() => this.deleteDocument(document)}
                                                    />
                                                ))}
                                            </Table>
                                        </div>
                                    </div>
                                    <h4>Recent Activity</h4>
                                    <div className="row mb-5">
                                        <div className="col-md-12">
                                            <RecentActivityTable />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </UserProvider>
            </div>
        );
    }
});
