const getQueryString = (params) => {
    const esc = encodeURIComponent;
    return Object.keys(params)
        .map(k => `${esc(k)}=${esc(params[k])}`)
        .join('&');
};

const timeRestrictedPromise = (func, timeout) => {
    let rejected = false;
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject('Request timed out.');
            rejected = true;
        }, timeout);
        return func()
            .then((res) => {
                if (!rejected) {
                    resolve(res);
                } else {
                    reject();
                }
            });
    });
};


module.exports = {
    token: '',
    type: '',
    lastRefreshed: null,
    status(response) { // handle ajax requests
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response);
        }
        response.clone().text() // cloned so response body can be used downstream
            .then((err) => {
                API.log(response.url, response.status, err);
            });
        return Promise.reject(response);
    },

    get(url, data) {
        return this._request('get', url, data || null);
    },

    dummy(data) {
        return function () {
            return new Promise(((resolve) => {
                resolve(data);
            }));
        };
    },

    put(url, data) {
        return this._request('put', url, data);
    },

    post(url, data, headers) {
        return this._request('post', url, data, headers);
    },

    delete(url, data) {
        return this._request('delete', url, data);
    },

    checkRefreshing() {
        return this.isRefreshing ? new Promise((resolve) => {
            var interval = setInterval(() => {
                if (!this.isRefreshing) {
                    clearInterval(interval);
                    AsyncStorage.getItem('lastRefreshed', ((err, res) => {
                        resolve(res);
                    }));
                }
            }, 100);
        }) : Promise.resolve();
    },

    checkAuth(url) {
        const token = this.token;
        // don't apply this check on refresh / login / register url
        if (!token || url.indexOf('auth/refresh') !== -1 || url.indexOf('auth/login') !== -1 || url.indexOf('auth/register') !== -1) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            AsyncStorage.getItem('lastRefreshed', (err, res) => {
                let shouldRefresh = true;

                if (res) { // Token was set previously, check time since last refresh
                    const timeSinceLastRefresh = new Date().valueOf() - parseFloat(res);
                    if (timeSinceLastRefresh < Constants.refreshAfter) { // Refresh timeout hasn't been reached, don't refresh the token
                        shouldRefresh = false;
                    }
                }

                let prom = Promise.resolve(shouldRefresh);

                if (shouldRefresh && !this.isRefreshing) { // mark data as refreshing to block other calls
                    this.isRefreshing = true;
                } else if (shouldRefresh && this.isRefreshing) {
                    // to ensure multiple calls do not refresh the token simultaneously wait until previous requests are complete
                    prom = this.checkRefreshing()
                        .then((res) => {
                            const timeSinceLastRefresh = new Date().valueOf() - parseFloat(res);
                            if (timeSinceLastRefresh < Constants.refreshAfter) {
                                return false;
                            }
                            return true;
                        });
                }

                return prom.then((shouldRefresh) => {
                    if (!shouldRefresh) {
                        resolve();
                        return true;
                    }

                    const request = () => {
                        return fetch(`${Project.api}auth/refresh?token=${token}`)
                            .then(this.status);
                    };

                    return timeRestrictedPromise(request, 5000)
                        .then(res => res.text())
                        .then((res) => {
                            res = JSON.parse(res);
                            if (res && res.token) {
                                // console.log("Refreshing token");
                                this.onRefresh();
                                this.setToken(res.token);
                            }
                            setTimeout(() => {
                                this.isRefreshing = false;
                            }, 100);
                            resolve();
                            return true;
                        })
                        .catch((e) => {
                            this.isRefreshing = false;
                            reject(e);
                        });
                });
            });
        });
    },

    _request(method, url, data, headers) {
        if (Constants.showAPICalls) {
            API.log(method, url, data);
        }

        if (bulletTrain.hasFeature('offline')) {
            return Promise.reject();
        }
        const options = {
            timeout: 20,
            method,
            headers: Object.assign({}, {
                'Accept': 'application/json',
            }, headers || {}),
        };


        let req;


        var qs = '';

        if (method != 'get' && options.headers['Content-Type'] == null) options.headers['Content-Type'] = 'application/json; charset=utf-8';

        if (data) {
            if (method == 'get') {
                var qs = getQueryString(data);
                url += url.indexOf('?') !== -1 ? `&${qs}` : `?${qs}`;
            } else if (options.headers['Content-Type'].indexOf('application/json') !== -1) {
                options.body = JSON.stringify(data);
            } else {
                options.body = data;
            }
        } else if (method == 'post' || method == 'put') {
            options.body = '{}';
        }

        req = () => {
            if (this.token) { // add auth tokens to headers of all requests
                options.headers.Authorization = `Bearer ${this.token}`;
            }
            return fetch(url, options);
        };
        return this.checkAuth(url) // check whether the token needs refreshing
            .then(req) // perform the request
            .then(this.status) // resolve / reject promise based on request status code
            .then((response) => { // always return json
                let contentType = response.headers.get('content-type');
                if (!contentType) {
                    contentType = response.headers.get('Content-Type');
                }
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    return response.json()
                        .then((res) => {
                            return res;
                        });
                }
                return {};
            })
            .then((response) => {
                if (Constants.showAPICalls) {
                    API.log(url, 200, response);
                }
                return response;
            });
    },

    onRefresh() {
        this.isRefreshing = false;
        return AsyncStorage.setItem('lastRefreshed', `${new Date().valueOf()}`);
    },

    setToken(_token) { // set the token for future requests
        this.token = _token;
        if (_token) {
            AsyncStorage.setItem('user', JSON.stringify(Object.assign({}, AccountStore.model, { token: _token })));
        }
    },
};
