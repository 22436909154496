const data = require('./base/_data');

module.exports = {
    login(details) {
        return data.post(`${Project.api}auth/login`, details);
    },
    register(details) {
        return data.post(`${Project.api}auth/register`, details);
    },
    getProject(instanceId) {
        return data.get(`${Project.api}instance/${instanceId}`);
    },
    getAccounts() {
        return data.get(`${Project.api}account`);
    },
    getProjects(accountId) {
        return data.get(`${Project.api}account/${accountId}/instance`);
    },
    getDocument(id) {
        return data.get(`${Project.api}documents/${id}`);
    },
    getPublicDocuments(instanceId) {
        return data.get(`${Project.api}instance/${instanceId}/document/public`)
            .then((res) => {
                return res.documents;
            });
    },
    getDocuments(instanceId, folderId) {
        return folderId
            ? data.get(`${Project.api}instance/${folderId || instanceId}/document`)
            : data.get(`${Project.api}instance/${folderId || instanceId}/document`);
    },
    getSubCollections(instanceId, folderId) {
        return (folderId
            ? data.get(`${Project.api}instance/${instanceId}/hierarchy`)
            : data.get(`${Project.api}instance/${instanceId}/hierarchy`)
        );
    },
    getUsers() {
        return data.get(`${Project.api}users`);
    },
    getUsersForAccount(accountId) {
        return data.get(`${Project.api}account/${accountId}/users`);
    },
    getUsersForItem(itemId, isDocument) {
        return data.get(`${Project.api}${isDocument ? 'documents' : 'instance'}/${itemId}/users`);
    },
    getDocumentAssets(id) {
        return data.get(`${Project.api}documents/${id}/assets`);
    },
    getDocumentStats(id) {
        return data.get(`${Project.api}documents/${id}/statistics`);
    },
    getUser(userId) {
        return data.get(`${Project.api}users/${userId}`);
    },
    getUserDocuments(userId) {
        return data.get(`${Project.api}users/${userId}/documents`);
    },
    getUserInstances(userId) {
        return data.get(`${Project.api}users/${userId}/instances`);
    },
    createAccount(details) {
        return data.post(`${Project.api}account`, details);
    },
    createInstance(accountId, details) {
        return data.post(`${Project.api}account/${accountId}/instance`, details);
    },
    createDocument(instanceId, details) {
        return data.post(`${Project.api}instance/${instanceId}/document`, details);
    },
    editInstance(id, details) {
        return data.put(`${Project.api}instance/${id}`, details);
    },
    editAccount(id, details) {
        return data.put(`${Project.api}account/${id}`, details);
    },
    editDocument(id, details) {
        return data.put(`${Project.api}documents/${id}`, details);
    },
    deleteAsset(id) {
        return data.delete(`${Project.api}assets/${id}`);
    },
    deleteDocument(id) {
        return data.delete(`${Project.api}documents/${id}`);
    },
    deleteInstance(id) {
        return data.delete(`${Project.api}instance/${id}`);
    },
    publishDocument(id) {
        return data.post(`${Project.api}documents/${id}/publish`);
    },
    unpublishDocument(id) {
        return data.post(`${Project.api}documents/${id}/unpublish`);
    },
    getInstanceStats(id) {
        return data.get(`${Project.api}instance/${id}/statistics`);
    },
    updateAccountPermissionsForUser(id, userId, permissionType) {
        return data.put(`${Project.api}account/${id}/permissions`, { user_id: userId, permission_type: permissionType });
    },
    updateInstancePermissionsForUser(id, userId, permissionType) {
        return data.put(`${Project.api}instance/${id}/permissions`, { user_id: userId, permission_type: permissionType });
    },
    updateDocumentPermissionsForUser(id, userId, permissionType) {
        return data.put(`${Project.api}documents/${id}/permissions`, { user_id: userId, permission_type: permissionType });
    },
    inviteUsersToAccount(id, users) {
        return data.post(`${Project.api}account/${id}/invite`, users);
    },
    upload(file, imageFor) {
        const formData = new FormData();
        formData.append('file', file);
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${Project.api}upload?image_for=${imageFor}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${data.token}`);

            xhr.upload.onerror = (uploadError) => {
                reject(uploadError);
            };

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if ((xhr.status === 200 || xhr.status === 201)) {
                        resolve(JSON.parse(xhr.responseText));
                    } else {
                        reject(xhr.responseText);
                    }
                }
            };

            xhr.send(formData);
        });
    },
    updateUser(id, details) {
        return data.put(`${Project.api}users/${id}`, details);
    },
    deactivateUsers(users) {
        return data.post(`${Project.api}users/deactivate`, { user_ids: users });
    },
    activateUsers(users) {
        return data.post(`${Project.api}users/activate`, { user_ids: users });
    },
    removeAccountPermissionsForUsers(id, users) {
        return data.delete(`${Project.api}account/${id}/permissions`, { user_ids: users });
    },
    removeAccountUsers(id, users) {
        return data.delete(`${Project.api}account/${id}/removeusers`, { user_ids: users });
    },
    removeInstancePermissionsForUsers(id, users) {
        return data.delete(`${Project.api}instance/${id}/permissions`, { user_ids: users });
    },
    removeDocumentPermissionsForUsers(id, users) {
        return data.delete(`${Project.api}documents/${id}/permissions`, { user_ids: users });
    },
    setUserRole(id, name) {
        return data.post(`${Project.api}users/${id}/roles`, { role_name: name });
    },
    getAccountStats(id) {
        return data.get(`${Project.api}account/${id}/statistics`);
    },
    toggleUserAdminStatus(accountId, userId) {
        return data.post(`${Project.api}account/${accountId}/users/${userId}/admin`);
    },
    inviteUsersToInstance(id, users) {
        return data.post(`${Project.api}instance/${id}/invite`, users);
    },
    inviteUsersToDocument(id, users) {
        return data.post(`${Project.api}documents/${id}/invite`, users);
    },
    joinAccount(invite_token) {
        return data.post(`${Project.api}account/join`, { invite_token });
    },
    getCurrentUser() {
        return data.get(`${Project.api}users/current`);
    },
    getAccountHierarchy(id) {
        return data.get(`${Project.api}account/${id}/hierarchy`);
    },
    getDocumentLinks(id) {
        return data.get(`${Project.api}documents/${id}/links`);
    },
    makeDocumentPublic(id, password) {
        return data.post(`${Project.api}documents/${id}/make-public`, { password: password || '' });
    },
    makeDocumentPrivate(id) {
        return data.post(`${Project.api}documents/${id}/make-private`);
    },
};
