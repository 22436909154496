import React from 'react';

import data from '../../../common/stores/base/_data';

export default hot(module)(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static propTypes = {
      route: propTypes.shape({
          match: propTypes.shape({
              params: propTypes.shape({
                  documentId: propTypes.string,
              }),
          }),
      }),
      location: propTypes.shape({
          pathname: propTypes.string,
          state: propTypes.shape({
              url: propTypes.string,
              documentType: propTypes.string,
          }),
      }),
  }

  static displayName = 'DocumentViewerPage';

  state = {};

  componentDidMount() {
      API.trackPage(Constants.pages.DOCUMENT_VIEWER_PAGE);

      const documentId = _.get(this.context.router, 'route.match.params.documentId');
      if (documentId && this.props.location.pathname.indexOf('/view') !== -1) {
          data.get(`${Project.api}documents/${documentId}`)
              .then(document => this.setState({ document }))
              .catch(() => {
                  this.context.router.history.replace('/dashboard');
              });
      }
  }

  renderPDF = privateDocumentViewing => (
      <iframe
        src={privateDocumentViewing ? this.state.document.viewer_url : _.get(this.props.location, 'state.url')} title="PDF Viewer" width="100%"
        height="100%"
      />
  )

  renderHype = privateDocumentViewing => (
      <iframe
        src={privateDocumentViewing ? this.state.document.viewer_url : _.get(this.props.location, 'state.url')} title="Hype Document Viewer"
        height="100%" style={{ width: 'calc(100% - 60px)' }}
      />
  )

  render = () => {
      const { document } = this.state;
      const documentId = _.get(this.context.router, 'route.match.params.documentId');
      const privateDocumentViewing = documentId && this.props.location.pathname.indexOf('/view') !== -1;
      if (privateDocumentViewing && !document) { // Private link redirect loading document
          return (
              <Flex>
                  <Loader />
              </Flex>
          );
      }

      const documentType = privateDocumentViewing ? document.type : _.get(this.props.location, 'state.documentType');
      let doc;
      switch (documentType) {
          case 'PDF':
              doc = this.renderPDF(privateDocumentViewing); break;
          case 'HYPE':
              doc = this.renderHype(privateDocumentViewing); break;
          default:
              doc = null;
      }
      const history = this.context.router.history;
      return (
          <Flex>
              <aside className="aside--viewer">
                  <div className="flex-column text-center clickable" role="button" onClick={() => (privateDocumentViewing ? history.push('/dashboard') : history.goBack())}>
                      <img src="/images/icons/remove.svg" alt="close"/>
                  </div>
              </aside>
              {doc}
          </Flex>
      );
  }
});
