// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { SelectInput, CheckboxInput } from './FormInput';

export class ItemMemberRow extends PureComponent {
    static displayName = 'ItemMemberRow';

    static propTypes = {
        isDocument: propTypes.bool,
        permissionType: propTypes.string,
        user: propTypes.shape({
            image_path: propTypes.string,
            first_name: propTypes.string,
            last_name: propTypes.string,
            roles: propTypes.arrayOf(propTypes.shape({
                display_name: propTypes.string,
            })),
            last_logged_in_at: propTypes.string,
        }),
        onClick: propTypes.func,
        onChangePermissions: propTypes.func,
        onRemovePermissions: propTypes.func,
        selected: propTypes.bool,
        onUserSelected: propTypes.func,
    };

    render() {
        const {
            props: {
                isDocument,
                user: {
                    id,
                    image_path,
                    first_name = '',
                    last_name = '',
                    roles = [{ display_name: 'User' }],
                    last_logged_in_at,
                    email,
                },
                onClick,
                permissionType,
                onChangePermissions,
                onRemovePermissions,
                onUserSelected,
                selected,
            } } = this;

        return (
            <tr className="table__row" onClick={onClick}>
                {/* Name */}
                <td className="table__cell" onClick={e => e.stopPropagation()}>
                    <CheckboxInput checked={selected} onChange={(e) => { e.stopPropagation(); onUserSelected(id, Utils.safeParseEventValue(e)); }} />
                </td>
                <td className="table__cell link-style">
                    <Row>
                        {image_path ? (
                            <img
                              className="avatar avatar--small round mr-2"
                              alt={`${first_name} ${last_name} Avatar`}
                              src={image_path}
                            />
                        ) : (
                            <div className="avatar__text avatar__text--small round bold mr-1">
                                {first_name[0].toUpperCase()}
                            </div>
                        )}
                        <Column>
                            <p className={'mb-0'}>{`${first_name} ${last_name}`}</p>
                            <p>{email}</p>
                        </Column>
                    </Row>
                </td>
                {/* Role */}
                <td className="table__cell">
                    {permissionType === 'OWNER' ? 'Admin' : 'User'}
                </td>
                {/* Permissions */}
                <td className="table__cell">
                    {AccountStore.getUser().id !== id ? (
                        <SelectInput
                          className="select--table" onClick={e => e.stopPropagation()}
                          onChange={onChangePermissions}
                          value={permissionType}
                        >
                            <option value="OWNER">Owner</option>
                            <option value="READ">Read</option>
                            <option value="EDIT">Read / Write</option>
                        </SelectInput>
                    ) : <span>Owner</span>}
                </td>
                {/* Status */}
                {isDocument ? <td className="table__cell">Downloaded</td> : null}
                {/* Last Seen */}
                <td className="table__cell">{Format.lastLoggedInAtDate(last_logged_in_at)}</td>
                {/* Actions */}
                <td className="table__cell">
                    {AccountStore.getUser().id !== id ? (
                        <div role="button" onClick={(e) => { e.stopPropagation(); onRemovePermissions(); }} className="btn-icon">
                            <img src="/images/icons/remove.svg" alt="Remove" className=""/>
                        </div>
                    ) : null}
                </td>
            </tr>
        );
    }
}

export default class extends PureComponent {
    static displayName = 'ItemMemberTable';

    static propTypes = {
        selectAll: propTypes.bool,
        onSelectAll: propTypes.func,
    };

    render() {
        const { props: { children, className, isDocument, selectAll, onSelectAll, ...rest } } = this;
        return (
            <table
              className={cn('table', className)} {...rest}
            >
                <thead className="table__head">
                    <tr>
                        <th className="table__head__cell"><CheckboxInput checked={selectAll} onChange={e => onSelectAll(Utils.safeParseEventValue(e))} /></th>
                        <th className="table__head__cell">Name</th>
                        <th className="table__head__cell">Company Role</th>
                        <th className="table__head__cell">Permissions</th>
                        {isDocument ? <th className="table__head__cell">Status</th> : null}
                        <th className="table__head__cell">Last seen</th>
                        <th className="table__head__cell">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        );
    }
}
