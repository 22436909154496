import React from 'react';

import ProjectStore from '../../../common/stores/project-store';
import Table, { CollectionRow, DocumentRow } from '../ItemTable';
import StatCard, { UsersStatCard, AllUsersStatCard } from '../StatCard';
import DeleteItemModal from '../modals/DeleteItem';

export default hot(module)(ConfigProvider(class extends React.Component {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static displayName = 'CompanyHierarchyPage';

    state = {};

    componentDidMount() {
        API.trackPage(Constants.pages.COMPANY_HIERARCHY_PAGE);
        ES6Component(this);
        this.listenTo(ProjectStore, 'saved', () => {
            this.hierarchyProvider.refresh();
        });
    }

    onError = (error) => {
        this.context.router.history.replace('/dashboard');
        toast(error);
    }

    onAccountNotFound = () => {
        this.context.router.history.replace('/dashboard');
        toast(Constants.errors.NO_ACCOUNT);
    }

    deleteCollection = (collection) => {
        openModal({
            body: <DeleteItemModal name={collection.name} type="collection" onConfirm={() => AppActions.deleteCollection(collection.id)} />,
            className: 'modal--default',
        });
    }

    deleteDocument = (document) => {
        openModal({
            body: <DeleteItemModal name={document.name} type="document" onConfirm={() => AppActions.deleteDocument(document.id)} />,
            className: 'modal--default',
        });
    }

    renderRows = (hierarchy) => {
        // Get all collections or documents from an array
        const documentsOnly = document.location.pathname.indexOf('documents') !== -1;
        const projects = _.flatMap(hierarchy, project => ({ projectId: project.id, items: Utils.getItemsFromHierarchy(project, documentsOnly) }));
        const accountId = Utils.getAccountId(this.props);
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const isAdmin = AccountStore.isAdmin();
        return _.map(projects, ({ projectId, items }) => {
            return _.map(items, (item) => {
                if (!item.document) {
                    return (
                        <CollectionRow
                          key={item.id} collection={item} permissionType={item.permission_type}
                          onDelete={() => this.deleteCollection(item)}
                          onClick={() => this.context.router.history.push(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : '/dashboard'}/project/${projectId}/${item.id}`)}
                        />
                    );
                }
                const document = item.document;
                return (
                    <DocumentRow
                      key={`doc-${document.id}`} document={document} permissionType={item.permission_type}
                      onDelete={() => this.deleteDocument(document)}
                      onClick={() => this.context.router.history.push(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : '/dashboard'}/project/${document.instance.project ? document.instance.id : projectId}${!document.instance.project ? `/${document.instance.id}` : ''}/document/${document.id}`)}
                    />
                );
            });
        });
    }

    render = () => {
        const isAdmin = AccountStore.isAdmin();
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const accountId = Utils.getAccountId(this.props);
        const { presentationMode } = this.props;

        return (
            <div className="content-pane">
                <AccountHierarchyProvider
                  ref={c => this.hierarchyProvider = c}
                  onAccountNotFound={this.onAccountNotFound}
                  onError={this.onError}
                  accountId={accountId}
                >
                    {({ isLoading, error, hierarchy }) => {
                        if (error) return <p>{`Error retriving company ${document.location.pathname.indexOf('documents') !== -1 ? 'documents' : 'collections'}`}</p>;
                        if (isLoading || !hierarchy) return <Loader />;
                        return (
                            <div>
                                <h4>Hierarchy</h4>
                                <ProjectsProvider accountId={accountId} onError={this.onError}>
                                    {({ isLoading: projectsLoading, error: projectsError, noProjectsFound, stats }, { refresh }) => {
                                        if (projectsError || noProjectsFound) return null;
                                        if (!stats || projectsLoading) return <Loader />;
                                        const user = AccountStore.getUser();
                                        const accountName = Utils.getAccountName(this.props);
                                        const isAccountAdmin = isAdmin || accountId ? _.find(user.accounts, ({ account }) => account.id === parseInt(accountId)).admin : user.accounts[0].account.admin;
                                        return (
                                            <div>
                                                {!presentationMode ? (<div className="row">
                                                    {stats && stats.num_documents != null ? (
                                                        <div className="col-6 col-md-3 mb-3 mb-lg-5">
                                                            <StatCard
                                                                title="Documents"
                                                                content={stats.num_documents} // TODO document_count always zero on old API
                                                                footer={`${stats.num_new_documents} New documents created this month`}
                                                                onClick={() => this.context.router.history.push(`/account/${Utils.getAccountId(this.props)}/documents`)}
                                                            />
                                                        </div>
                                                    ) : null}
                                                    {stats && stats.num_collections != null ? (
                                                        <div className="col-6 col-md-3 mb-3 mb-lg-5">
                                                            <StatCard
                                                                title="Collections"
                                                                content={stats.num_collections}
                                                                footer={`${stats.num_new_collections} New collections created this month`}
                                                                onClick={() => this.context.router.history.push(`/account/${Utils.getAccountId(this.props)}/collections`)}
                                                            />
                                                        </div>
                                                    ) : null}
                                                    {stats && stats.members != null ? (
                                                        <div className="col-6 col-md-3 mb-3 mb-lg-5">
                                                            <UsersStatCard
                                                                title="Members"
                                                                users={stats.members}
                                                                footer={`${stats.num_new_members || 0} new members joined ${accountName} this month`}
                                                                onClick={isAccountAdmin ? () => this.context.router.history.push(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : ''}/members`) : null}
                                                            />
                                                        </div>
                                                    ) : null}
                                                    {stats && stats.size != null ? (
                                                        <div className="col-6 col-md-3 mb-3 mb-lg-5">
                                                            <StatCard
                                                                title="Usage"
                                                                content={filesize(stats.size)}
                                                                footer={`${accountName} has 7.08GB storage space`}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>) : null}

                                            </div>
                                        );
                                    }}
                                </ProjectsProvider>
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <Table>
                                            {this.renderRows(hierarchy)}
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </AccountHierarchyProvider>
            </div>
        );
    }
}));
