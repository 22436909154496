const BaseStore = require('./base/_store');
const endpoints = require('./endpoints');

const controller = {

    delete(id) {
        delete (store.progress[id]);
        store.trigger(`change${id}`);
        controller.updateProgress();
    },

    cancel(id) {
        delete (store.progress[id]);
        controller.updateProgress();
        API.cancelDocument(id);
        store.changed();
    },

    download(id, document, isUpdate) {
        if (!document.assets || !document.assets.length) return;

        store.progress = store.progress || {};

        if (store.progress[id] && store.progress[id].isDownloading) return;

        store.error = null;
        store.saving();

        if (isUpdate) {
            store.progress[id] = Object.assign({}, store.progress[id]);
        } else {
            store.progress[id] = { progress: 0, total: 0, document, isDownloading: true };
        }
        if (isUpdate) {
            store.progress[id].isUpdating = true;
        }
        API.downloadDocument(document, isUpdate, (progress) => {
            if (API.cancelledDocuments[id]) {
                return;
            }
            store.progress[id].progress = progress;

            store.trigger(`change${id}`);
            controller.updateProgress();
        })
            .then(() => {
                store.error = null;
                store.progress[id].progress = 0;
                store.progress[id].isDownloading = false;
                store.progress[id].isDownloaded = true;
                store.progress[id].isUpdating = false;
                store.progress[id].lastUpdated = new Date().valueOf();
                if (isUpdate) {
                    store.progress[id].document = document;
                }
                store.trigger('saved', id);
                store.trigger(`change${id}`);
                store.changed();
                AsyncStorage.setItem('download-progress', JSON.stringify(store.progress));
            })
            .catch((e) => {
                if (store.progress[id]) {
                    store.progress[id].isDownloading = false;
                    store.progress[id].isUpdating = false;
                    controller.updateProgress();
                }
                store.trigger(`change${id}`);
                API.handleError(store, e);
            });
    },
    update(id, document) {
        controller.download(id, document, true);
    },
    updateProgress: _.throttle(() => {
        store.changed();
        AsyncStorage.setItem('download-progress', JSON.stringify(store.progress));
    }, 200),
    getAssets(id) {
        store.loading();
        endpoints.getDocumentAssets(id)
            .then((res) => {
                store.assets = res;
                store.loaded();
            })
            .catch(e => API.handleError(store, e));
    },
    updateAssets(id, assets) {
        // Update is performed using XMLHTTPRequest so all we are doing here is replacing the model
        store.assets = assets;
        store.saved();
    },
    deleteAssets(assetIds) {
        store.saving();
        Promise.all(_.map(assetIds, assetId => endpoints.deleteAsset(assetId)))
            .then(() => {
                store.assets = _.filter(store.assets, asset => assetIds.indexOf(asset.id) === -1);
                store.saved();
            })
            .catch(e => API.handleError(store, e));
    },
    getStats(id) {
        store.loading();
        endpoints.getDocumentStats(id)
            .then((res) => {
                store.stats = res;
                store.loaded();
            })
            .catch(e => API.handleError(store, e));
    },
    get(id) {
        store.loading();
        endpoints.getDocument(id)
            .then((res) => {
                store.model = res;
                store.loaded();
            })
            .catch(e => API.handleError(store, e));
    },
    getAllData(id) {
        store.loading();
        Promise.all([endpoints.getDocument(id), endpoints.getDocumentStats(id), endpoints.getDocumentAssets(id), AccountStore.isAdmin() ? endpoints.getDocumentLinks(id) : Promise.resolve(null)])
            .then(([document, stats, assets, links]) => {
                store.assets = assets;
                store.stats = stats;
                store.model = document;
                store.links = links;
                store.loaded();
            })
            .catch(e => API.handleError(store, e));
    },
    makeDocumentPublic(id, password) {
        store.loading();
        endpoints.makeDocumentPublic(id, password)
            .then((res) => {
                store.model = res;
                return endpoints.getDocumentLinks(id);
            })
            .then((links) => {
                store.links = links;
                store.loaded();
            })
            .catch(e => API.handleError(store, e));
    },
    makeDocumentPrivate(id) {
        store.loading();
        endpoints.makeDocumentPrivate(id)
            .then(() => endpoints.getDocumentLinks(id))
            .then((links) => {
                store.links = links;
                store.loaded();
            })
            .catch(e => API.handleError(store, e));
    },
};


const store = Object.assign({}, BaseStore, {
    id: 'document',
    isDownloaded(id) {
        return store.progress && store.progress[id] && store.progress[id].isDownloaded;
    },
    isDownloading(id) {
        return store.progress && store.progress[id] && store.progress[id].isDownloading;
    },
    isUpdating(id) {
        return store.progress && store.progress[id] && store.progress[id].isUpdating;
    },
    getProgress(id) {
        return (store.progress && store.progress[id] && store.progress[id].progress) || 0;
    },
    getDownloaded() {
        const filtered = store.progress && _.filter(store.progress, 'isDownloaded');
        return filtered && _.map(filtered, 'document');
    },
    hasUpdate(id, lastUpdated) {
        if (bulletTrain.hasFeature('force_updates')) return true;
        const lastDownloaded = store.progress && store.progress[id] && store.progress[id].lastUpdated;
        return lastUpdated > lastDownloaded;
    },
    getUpdating() {
        const filtered = store.progress && _.filter(store.progress, 'isUpdating');
        return filtered && _.map(filtered, 'document');
    },
    getInProgress(includeUpdates) {
        const filtered = store.progress && _.filter(store.progress, d => d.progress && (includeUpdates || !d.isUpdating));
        return filtered && _.map(filtered, 'document');
    },
    getTotalProgress() {
        const filtered = store.progress && _.filter(store.progress, d => d.progress && !d.isUpdating);
        if (filtered && filtered.length) {
            let file_size = 0;
            let progress = 0;
            _.each(filtered, (f) => {
                file_size += f.document.file_size;
                progress += f.progress;
            });
            return { file_size, progress };
        }
        return null;
    },
    getDownloadState(item) {
        return {
            hasUpdate: store.hasUpdate(item.id, item.updated_at_value), // todo
            isDownloading: store.isDownloading(item.id),
            isDownloaded: store.isDownloaded(item.id),
            progress: store.getProgress(item.id),
        };
    },
    getDocument() {
        return store.model;
    },
});


store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from	handleViewAction

    switch (action.actionType) {
        case Actions.DOWNLOAD_DOCUMENT:
            controller.download(action.id, action.document);
            break;
        case Actions.UPDATE_DOCUMENT_DOWNLOAD:
            controller.update(action.id, action.document);
            break;
        case Actions.CANCEL_DOCUMENT_DOWNLOAD:
            controller.cancel(action.id);
            break;
        case Actions.DELETE_DOCUMENT_DOWNLOAD:
            controller.delete(action.id);
            break;
        case Actions.GET_DOCUMENT_ASSETS:
            controller.getAssets(action.id);
            break;
        case Actions.UPDATE_DOCUMENT_ASSETS:
            controller.updateAssets(action.id, action.assets);
            break;
        case Actions.DELETE_DOCUMENT_ASSETS:
            controller.deleteAssets(action.assetIds);
            break;
        case Actions.GET_DOCUMENT_STATS:
            controller.getStats(action.id);
            break;
        case Actions.GET_DOCUMENT:
            controller.get(action.id);
            break;
        case Actions.GET_ALL_DOCUMENT_DATA:
            controller.getAllData(action.id);
            break;
        case Actions.MAKE_DOCUMENT_PUBLIC:
            controller.makeDocumentPublic(action.id, action.password);
            break;
        case Actions.MAKE_DOCUMENT_PRIVATE:
            controller.makeDocumentPrivate(action.id);
            break;
        case Actions.ON_LOGOUT:
            store.error = null;
            break;
        default:
    }
});

AsyncStorage.getItem('download-progress', (err, res) => {
    if (res) {
        let progress = JSON.parse(res);
        progress = _.each(progress, (r, id) => progress[id] = Object.assign({}, r, { isDownloading: false }));
        store.progress = progress;
    }
});
controller.store = store;
module.exports = controller.store;
