// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { SelectInput, CheckboxInput } from './FormInput';

export class MemberRow extends PureComponent {
    static displayName = 'MemberRow';

    static propTypes = {
        user: propTypes.shape({
            image_path: propTypes.string,
            first_name: propTypes.string,
            last_name: propTypes.string,
            roles: propTypes.arrayOf(propTypes.shape({
                display_name: propTypes.string,
                name: propTypes.string,
                id: propTypes.number,
            })),
            created_at: propTypes.string,
            active: propTypes.bool,
            accounts: propTypes.arrayOf(propTypes.shape({
                account: propTypes.object,
                admin: propTypes.bool,
            })),
        }),
        selected: propTypes.bool,
        onClick: propTypes.func,
        onDeactivate: propTypes.func,
        onUserSelected: propTypes.func,
        onActivate: propTypes.func,
        onChangeRole: propTypes.func,
    };

    render() {
        const {
            props: {
                user: {
                    id,
                    image_path,
                    first_name = '',
                    last_name = '',
                    roles = [{ display_name: 'User' }],
                    created_at,
                    active,
                    accounts,
                },
                onUserSelected,
                selected,
                onClick,
                onDeactivate,
                onActivate,
                onChangeRole,
            } } = this;
        return (
            <tr className={`table__row ${!active ? 'table__row--inactive' : ''}`} onClick={onClick}>
                {/* Name */}
                <td className="table__cell table__cell--input" onClick={e => e.stopPropagation()}>
                    <CheckboxInput checked={selected} onChange={(e) => { e.stopPropagation(); onUserSelected(id, Utils.safeParseEventValue(e)); }} />
                </td>
                <td className="table__cell table__cell--user">
                    <Row>
                        {image_path ? (
                            <img
                              className="avatar avatar--small round mr-2"
                              alt={`${first_name} ${last_name} Avatar`}
                              src={image_path}
                            />
                        ) : (
                            <div className="text-avatar round bold mr-2">
                                {first_name[0].toUpperCase()}
                            </div>
                        )}
                        <p>{`${first_name} ${last_name}`}</p>
                    </Row>
                </td>
                {/* Company */}
                <td className="table__cell" data-toggle="tooltip" title={_.map(accounts, 'name').join(', ')}>
                    {_.map(_.take(accounts, 1), ({ account }) => account.name).join(', ')}
                    {(accounts.length > 1 ? <span className="table__cell__sup">{` +  ${accounts.length - 1} more`}</span> : '')}
                </td>
                {/* Role */}
                <td className="table__cell">
                    {!active ? 'Deactivated' : (
                        AccountStore.getUser().id === id ? <span>Admin</span> : (
                            <SelectInput
                              className="select--table" onClick={e => e.stopPropagation()}
                              onChange={onChangeRole}
                              value={roles[0].name}
                            >
                                <option value="admin" className="option">Admin</option>
                                <option value="user" className="option">User</option>
                            </SelectInput>
                        )
                    )}

                </td>
                {/* Created */}
                <td className="table__cell">{Format.createdDate(created_at)}</td>
                {/* Actions */}
                <td className={`table__cell ${!active ? 'table__cell--active' : ''}`}>
                    {AccountStore.getUser().id !== id ? active ? (
                        <div
                          role="button" onClick={(e) => { e.stopPropagation(); onDeactivate(); }} className="btn-icon"
                          data-toggle="tooltip" title="Deactivate user"
                        >
                            <img src="/images/icons/remove.svg" alt="Deactivate" className=""/>
                        </div>
                    ) : (
                        <div
                          role="button" onClick={(e) => { e.stopPropagation(); onActivate(); }} className="btn-icon"
                          data-toggle="tooltip" title="Activate user"
                        >
                            <img src="/images/icons/reactivate.svg" alt="Activate" className=""/>
                        </div>
                    ) : null}
                </td>
            </tr>
        );
    }
}

export default class extends PureComponent {
    static displayName = 'MemberTable';

    static propTypes = {
        selectAll: propTypes.bool,
        onSelectAll: propTypes.func,
    };

    render() {
        const { props: { children, className, selectAll, onSelectAll, ...rest } } = this;
        return (
            <div className="table-container">
                <div className="table-responsive">
                    <table
                      className={cn('table', className)} {...rest}
                    >
                        <thead className="table__head">
                            <tr>
                                <th className="table__head__cell"><CheckboxInput checked={selectAll} onChange={e => onSelectAll(Utils.safeParseEventValue(e))}/></th>
                                <th className="table__head__cell">
                                    {'Name '}
                                    <img src="/images/icons/triangle--down.svg" alt="Sort" className="ml-1"/>
                                </th>
                                <th className="table__head__cell">
                                    {'Company '}
                                    <img src="/images/icons/filter--white.svg" alt="Filter" className="ml-1"/>
                                </th>
                                <th className="table__head__cell">Role </th>
                                <th className="table__head__cell">
                                    {'Created '}
                                    <img src="/images/icons/triangle--down.svg" alt="Sort" className="ml-1"/>
                                </th>
                                <th className="table__head__cell">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {children}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
