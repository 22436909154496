// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { CheckboxInput } from './FormInput';

export class CompanyRow extends PureComponent {
    static displayName = 'CompanyRow';

    static propTypes = {
        account: propTypes.object,
        onClick: propTypes.func,
    }

    render() {
        const { account: { name, user_count, updated_at, created_at }, onClick } = this.props;
        return (
            <tr className="table__row" onClick={onClick}>
                <td className="table__cell">
                    <p>{name}</p>
                </td>
                {/* File type */}
                <td className="table__cell">
                    <p>{user_count}</p>
                </td>
                {/* Last updated */}
                <td className="table__cell">{Format.updatedDate(updated_at)}</td>
                {/* Created */}
                <td className="table__cell">{Format.createdDate(created_at)}</td>
                {/* Actions */}
                <td className="table__cell">
                    <img src="/images/icons/delete.svg" alt="delete" className=""/>
                </td>
            </tr>
        );
    }
}

export default class extends PureComponent {
    static displayName = 'TableRow';

    static propTypes = {};

    render() {
        const { props: { children, className, ...rest } } = this;
        return (
            <div className="table-container">
                <div className="table-responsive">
                    <table
                      className={cn('table', className)} {...rest}
                    >
                        <thead className="table__head">
                            <tr>
                                <th className="table__head__cell">Company name</th>
                                <th className="table__head__cell">Members</th>
                                <th className="table__head__cell">Last updated</th>
                                <th className="table__head__cell">Created</th>
                                <th className="table__head__cell">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {children}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
