import React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from './App';
import SidebarContainer from './components/Sidebar';

// App Wrapper
// eslint-disable-next-line
const withPage = Page => props => (
    <Page {...props.match.params} {...Utils.fromParam()}/>
);
withPage.displayName = 'withPage';

// eslint-disable-next-line
const withSidebar = Page => ConfigProvider(props => (
    <div className={props.presentationMode ? 'container-fluid aside-content aside-content--presenting' : 'container-fluid aside-content'}>
        <div className="row">
            <SidebarContainer {...props.match.params} key="sidebar"/>
            <div className="page-content flex-1 px-0">
                <Page {...props.match.params} {...Utils.fromParam()}/>
            </div>
        </div>
    </div>
));
withSidebar.displayName = 'withSidebar';

export default (
    <App>
        {/* Render one of these routes */}
        <Switch>
            <Route path="/" exact component={require('./components/pages/LoginPage').default}/>
            <Route path="/register" exact component={require('./components/pages/RegistrationPage').default}/>
            <Route path="/reset-password" exact component={require('./components/pages/ResetPasswordPage').default}/>
            <Route path="/dashboard" exact component={withSidebar(require('./components/pages/DashboardPage').default)}/>
            <Route path="/dashboard/documents" exact component={withSidebar(require('./components/pages/CompanyHierarchyPage').default)}/>
            <Route path="/dashboard/collections" exact component={withSidebar(require('./components/pages/CompanyHierarchyPage').default)}/>
            <Route path="/account/:accountId/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/account/:accountId/documents" exact component={withSidebar(require('./components/pages/CompanyHierarchyPage').default)}/>
            <Route path="/account/:accountId/collections" exact component={withSidebar(require('./components/pages/CompanyHierarchyPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/documents" exact component={withSidebar(require('./components/pages/ProjectHierarchyPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/collections" exact component={withSidebar(require('./components/pages/ProjectHierarchyPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/document/:documentId/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/:collectionId/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/:collectionId/document/:documentId/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/project/:projectId/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/project/:projectId/document/:documentId/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/project/:projectId/:collectionId/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/project/:projectId/:collectionId/document/:documentId/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/account/:accountId" exact component={withSidebar(require('./components/pages/DashboardPage').default)}/>
            <Route path="/account/:accountId/project/:projectId" exact component={withSidebar(require('./components/pages/ProjectPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/document/:documentId" exact component={withSidebar(require('./components/pages/DocumentPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/document/:documentId/preview" exact component={require('./components/pages/DocumentViewerPage').default}/>
            <Route path="/account/:accountId/project/:projectId/:collectionId" exact component={withSidebar(require('./components/pages/ProjectPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/:collectionId/documents" exact component={withSidebar(require('./components/pages/ProjectHierarchyPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/:collectionId/collections" exact component={withSidebar(require('./components/pages/ProjectHierarchyPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/:collectionId/document/:documentId" exact component={withSidebar(require('./components/pages/DocumentPage').default)}/>
            <Route path="/account/:accountId/project/:projectId/:collectionId/document/:documentId/preview" exact component={require('./components/pages/DocumentViewerPage').default}/>
            <Route path="/project/:projectId" exact component={withSidebar(require('./components/pages/ProjectPage').default)}/>
            <Route path="/project/:projectId/documents" exact component={withSidebar(require('./components/pages/ProjectHierarchyPage').default)}/>
            <Route path="/project/:projectId/collections" exact component={withSidebar(require('./components/pages/ProjectHierarchyPage').default)}/>
            <Route path="/project/:projectId/document/:documentId" exact component={withSidebar(require('./components/pages/DocumentPage').default)}/>
            <Route path="/project/:projectId/document/:documentId/preview" exact component={require('./components/pages/DocumentViewerPage').default}/>
            <Route path="/project/:projectId/:collectionId" exact component={withSidebar(require('./components/pages/ProjectPage').default)}/>
            <Route path="/project/:projectId/:collectionId/documents" exact component={withSidebar(require('./components/pages/ProjectHierarchyPage').default)}/>
            <Route path="/project/:projectId/:collectionId/collections" exact component={withSidebar(require('./components/pages/ProjectHierarchyPage').default)}/>
            <Route path="/project/:projectId/:collectionId/document/:documentId" exact component={withSidebar(require('./components/pages/DocumentPage').default)}/>
            <Route path="/project/:projectId/:collectionId/document/:documentId/preview" exact component={require('./components/pages/DocumentViewerPage').default}/>
            <Route path="/members" exact component={withSidebar(require('./components/pages/ManageMembersPage').default)}/>
            <Route path="/markup" exact component={require('./components/pages/__MarkupPage__').default}/>
            <Route path="/profile/:userId" exact component={withSidebar(require('./components/pages/ProfilePage').default)}/>
            <Route path="/document/invite/:documentId/:token" exact component={require('./components/pages/DocumentInvitePage').default}/>
            <Route path="/document/viewer/:documentId/:token" exact component={require('./components/pages/DocumentPublicViewerPage').default}/>
            <Route path="/document/viewer/:documentId" exact component={require('./components/pages/DocumentViewerPage').default}/>
            <Route component={require('./components/pages/NotFoundPage').default}/>
        </Switch>
    </App>
);
