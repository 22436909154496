import React from 'react';
import { Progress } from 'react-sweet-progress';

import data from '../../../common/stores/base/_data';
import BasicButton from '../BasicButton';

const successSymbol = (
    <svg version="1.1" viewBox="0 0 512 512" style={{ width: '20px', height: '20px' }}>
        <path
          fill="#FC7000"
          d="M489,255.9c0-0.2,0-0.5,0-0.7c0-1.6,0-3.2-0.1-4.7c0-0.9-0.1-1.8-0.1-2.8c0-0.9-0.1-1.8-0.1-2.7  c-0.1-1.1-0.1-2.2-0.2-3.3c0-0.7-0.1-1.4-0.1-2.1c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5-0.1-1.1-0.1-1.6c-0.1-1.3-0.3-2.6-0.4-4  c0-0.3-0.1-0.7-0.1-1C474.3,113.2,375.7,22.9,256,22.9S37.7,113.2,24.5,229.5c0,0.3-0.1,0.7-0.1,1c-0.1,1.3-0.3,2.6-0.4,4  c-0.1,0.5-0.1,1.1-0.1,1.6c-0.1,1.2-0.2,2.4-0.3,3.6c0,0.7-0.1,1.4-0.1,2.1c-0.1,1.1-0.1,2.2-0.2,3.3c0,0.9-0.1,1.8-0.1,2.7  c0,0.9-0.1,1.8-0.1,2.8c0,1.6-0.1,3.2-0.1,4.7c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0.1s0,0,0,0.1c0,0.2,0,0.5,0,0.7c0,1.6,0,3.2,0.1,4.7  c0,0.9,0.1,1.8,0.1,2.8c0,0.9,0.1,1.8,0.1,2.7c0.1,1.1,0.1,2.2,0.2,3.3c0,0.7,0.1,1.4,0.1,2.1c0.1,1.2,0.2,2.4,0.3,3.6  c0,0.5,0.1,1.1,0.1,1.6c0.1,1.3,0.3,2.6,0.4,4c0,0.3,0.1,0.7,0.1,1C37.7,398.8,136.3,489.1,256,489.1s218.3-90.3,231.5-206.5  c0-0.3,0.1-0.7,0.1-1c0.1-1.3,0.3-2.6,0.4-4c0.1-0.5,0.1-1.1,0.1-1.6c0.1-1.2,0.2-2.4,0.3-3.6c0-0.7,0.1-1.4,0.1-2.1  c0.1-1.1,0.1-2.2,0.2-3.3c0-0.9,0.1-1.8,0.1-2.7c0-0.9,0.1-1.8,0.1-2.8c0-1.6,0.1-3.2,0.1-4.7c0-0.2,0-0.5,0-0.7  C489,256,489,256,489,255.9C489,256,489,256,489,255.9z" id="XMLID_3_"
        />
        <g
          fill="none" stroke="#FFFFFF" strokeWidth={30}
          strokeMiterlimit={10}
        >
            <line
              x1="213.6" x2="369.7" y1="344.2"
              y2="188.2"
            />
            <line
              x1="233.8" x2="154.7" y1="345.2"
              y2="266.1"
            />
        </g>
    </svg>
);

export default hot(module)(class extends React.Component {
    static displayName = 'UpdateAsset';

    static propTypes = {
        asset: propTypes.object,
        documentId: propTypes.string,
        document_type: propTypes.string,
    }

    state = {
    }

    upload = () => {
        this.setState({ isSaving: true });

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', `${Project.api}documents/${this.props.documentId}/assets`, true);
        xhr.setRequestHeader('Authorization', `Bearer ${data.token}`);

        xhr.upload.onprogress = ({ loaded, total }) => {
            this.setState({ uploadProgress: loaded / total * 100 });
        };
        xhr.upload.onloadstart = () => {
            this.setState({ isUploading: true });
            this.forceUpdate();
        };
        xhr.upload.onerror = (uploadError) => {
            this.setState({ uploadError, isUploading: false });
        };
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200 || xhr.status === 201) {
                    const doc = JSON.parse(xhr.responseText);
                    AppActions.updateDocumentAssets(this.props.documentId, doc.assets);
                    this.setState({ uploadProgress: 100, uploadStatus: 'success' });
                    setTimeout(() => {
                        closeModal();
                    }, 500);
                } else {
                    this.setState({ uploadError: xhr.responseText, isUploading: false });
                }
            }
        };

        const formData = new FormData();
        const metadata = [];
        const file = this.state.file;
        formData.append('files', file);
        metadata.push({
            last_modified: moment(file.lastModified).utc().toISOString(),
            path: Utils.parseDocumentAssetFilename(this.props.asset.file_location, this.props.document_type),
        });
        formData.append('metadata', JSON.stringify(metadata));

        xhr.send(formData);
    }

    render = () => {
        const { state: { file, isSaving, uploadError, uploaded, isUploading, uploadProgress, uploadStatus }, props: { asset } } = this;
        const assetFilename = _.last(asset.file_location.split('/'));
        return (
            <div className="confirm-content-pane">
                <Row className="justify-content-end">
                    <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                        <img className="" src="/images/icons/remove.svg" alt="Remove" />
                    </button>
                </Row>
                <div className="col-lg-10 m-auto">
                    <div className="text-center">
                        <h2 className="mb-1 mt-4 mt-sm-0">Update Asset</h2>
                        <p className="text-small mb-5">{`Choose a new file to replace ${assetFilename}`}</p>
                        <form className="form">
                            <div className="form__content">
                                {uploaded ? (
                                    <tr>
                                        <td className="d-flex align-items-end justify-content-center uploaded-overlay">
                                            <img alt="Upload done" src="/images/upload-done.svg" className="uploaded-overlay__icon"/>
                                        </td>
                                    </tr>
                                ) : isUploading ? (
                                    <tr>
                                        <td className="d-flex align-items-end justify-content-center uploaded-overlay">
                                            <Progress
                                              percent={Math.round(uploadProgress)} status={uploadStatus} theme={{
                                                  default: {
                                                      trailColor: '#ffd6b5',
                                                  },
                                                  active: {
                                                      color: '#FC7000',
                                                      trailColor: '#ffd6b5',
                                                  },
                                                  success: {
                                                      color: '#FC7000',
                                                      symbol: successSymbol,
                                                  },
                                              }}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                                <div className="row mt-5 mb-5 justify-content-center ">
                                    <input type="file" onChange={e => this.setState({ file: e.target.files[0] })} />
                                </div>
                            </div>

                            {(file && file.name !== assetFilename) ? (
                                <Column className="text-center"><p className="text-center mb-3">Selected file must have same name as existing asset filename</p></Column>
                            ) : null}
                            {uploadError ? (
                                <Column className="text-center"><p className="text-center mb-3">{uploadError}</p></Column>
                            ) : null}
                            <div className="form__footer mt-5">
                                <Row className="justify-content-center">
                                    <BasicButton
                                      buttonText="Cancel" className="btn--transparent  flex-grow-1 flex-sm-grow-0 order-1 order-sm-0" onClick={() => closeModal()}
                                      disabled={isSaving}
                                    />
                                    <BasicButton
                                      buttonText="Update" className=" flex-grow-1 flex-sm-grow-0" disabled={!file || file.name !== assetFilename || isSaving}
                                      onClick={this.upload}
                                    />
                                </Row>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
});
