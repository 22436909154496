/**
 * Created by andrewmoffatt on 04/03/2019.
 */

import React, { Component } from 'react';
import cn from 'classnames';

export default class UploadInput extends Component {
    static displayName = 'UploadInput';

    static propTypes = {
        onChange: propTypes.func,
        existingCoverImage: propTypes.string,
        onRemoveExistingCoverImage: propTypes.func,
    };

    state = {};

    onFileChange = (e) => {
        if (!e.target.files || !e.target.files.length) return;
        const { onChange } = this.props;
        const file = e.target.files[0];
        if (this.props.sizeLimit && file.size && file.size > this.props.sizeLimit) {
            toast('This image is too large');
            return;
        }
        const reader = new FileReader();
        reader.onload = (readerEvent) => {
            this.setState({ coverImage: readerEvent.target.result });
            onChange(file);
        };
        reader.readAsDataURL(file);
    }

    remove = () => {
        if (!this.state.coverImage) {
            if (this.props.onRemoveExistingCoverImage) this.props.onRemoveExistingCoverImage();
            return;
        }
        this.setState({ coverImage: null });
        this.props.onChange(null);
        this.fileInput.value = '';
    }

    render() {
        const { props: { className, inputTitle, helperText, onChange, existingCoverImage }, state: { coverImage } } = this;
        return (
            <div className={cn('flex-column upload mx-0', className)}>
                <input
                  type="file" ref={c => this.fileInput = c} name="image"
                  accept="image/*"
                  capture style={{ display: 'none' }}
                  onChange={this.onFileChange}
                />
                <div className="upload__container">
                    {(existingCoverImage || coverImage) ? (
                        <img
                          src={coverImage || existingCoverImage} className="upload__image" onClick={() => this.fileInput.click()}
                          alt="Cover"
                        />
                    ) : (
                        <svg
                          width="200px" height="150px" viewBox="0 0 200 150"
                          version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                          onClick={() => this.fileInput.click()}
                          className="upload__image"
                        >
                            <title>Placeholder-image</title>
                            <desc>Created with Sketch.</desc>
                            <defs />
                            <g
                              id="Placeholder-image" stroke="none" strokeWidth="1"
                              fill="none" fillRule="evenodd"
                            >
                                <rect
                                  fill="#FFFFFF" x="0" y="0"
                                  width="200" height="150"
                                />
                                <text
                                  id="Upload-Cover-image" fontFamily="MyriadPro-Regular, Myriad Pro" fontSize="12"
                                  fontWeight="normal" fill="#192025"
                                >
                                    <tspan x="47.386" y="62">{inputTitle || 'Upload Cover image'}</tspan>
                                </text>
                                <g
                                  id="upload" opacity="0.25" transform="translate(89.000000, 77.000000)"
                                  fill="#000000" fillRule="nonzero"
                                >
                                    <path d="M19.4269949,9.08857396 C19.1086587,9.08857396 18.8539898,9.35429584 18.8539898,9.68644818 L18.8539898,15.1248893 C18.8539898,16.6040744 17.6994907,17.8042516 16.2860781,17.8042516 L3.7139219,17.8042516 C2.29626486,17.8042516 1.14601019,16.5996457 1.14601019,15.1248893 L1.14601019,9.59787422 C1.14601019,9.26572188 0.891341256,9 0.573005093,9 C0.25466893,9 0,9.26572188 0,9.59787422 L0,15.1248893 C0,17.2639504 1.66808149,19 3.7139219,19 L16.2860781,19 C18.336163,19 20,17.2595217 20,15.1248893 L20,9.68644818 C20,9.35872453 19.7453311,9.08857396 19.4269949,9.08857396 Z" id="Shape" />
                                    <path d="M6.92571141,4.89805097 L9.45605641,2.07346327 L9.45605641,14.3928036 C9.45605641,14.7301349 9.69780912,15 10,15 C10.3021909,15 10.5439436,14.7301349 10.5439436,14.3928036 L10.5439436,2.07346327 L13.0742886,4.89805097 C13.1790481,5.0149925 13.3200705,5.07796102 13.4570637,5.07796102 C13.5980861,5.07796102 13.7350793,5.01949025 13.8398388,4.89805097 C14.0533871,4.65967016 14.0533871,4.27736132 13.8398388,4.03898051 L10.3827751,0.179910045 C10.2820448,0.0674662669 10.1410224,0 10,0 C9.85494838,0 9.71795518,0.0629685157 9.61722488,0.179910045 L6.16016117,4.03898051 C5.94661294,4.27736132 5.94661294,4.65967016 6.16016117,4.89805097 C6.36968018,5.13193403 6.71619239,5.13193403 6.92571141,4.89805097 Z" id="Shape" />
                                </g>
                            </g>
                        </svg>
                    )}
                    {(existingCoverImage || coverImage) ? (
                        <div className="upload-image-overlay">
                            <div className="flex-row pt-1">
                                <button type="button" className="btn btn--transparent flex-fill" onClick={() => this.fileInput.click()}>
                                    <img src="/images/icons/change.svg" alt="Change"/>
                                    <p className="text-smaller text-dark">Change</p>
                                </button>
                                <button type="button" className="btn btn--transparent flex-fill" onClick={this.remove}>
                                    <img src="/images/icons/remove--dark.svg" alt="Remove"/>
                                    <p className="text-smaller text-dark align-self-end mt-1">Remove</p>
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
                <p className="text-smaller muted mt-2">{helperText}</p>
            </div>
        );
    }
}
