/**
 * Created by andrewmoffatt on 04/03/2019.
 */

// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export default class ButtonGroup extends PureComponent {
    static displayName = 'ButtonGroup';

    static propTypes = {};

    render() {
        const { props: { className, dataValue, ...rest } } = this;
        return (
            <div className={cn('btn-group d-flex', className)} role="group" aria-label="Basic example">
                {this.props.children}
            </div>
        );
    }
}