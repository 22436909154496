import React from 'react';

import MemberInviteRow, { EmailInvitedRow } from './MemberInviteRow';
import { ExistingMemberInviteRow, GroupInviteRow, EmailMemberInviteRow } from './SearchMemberInviteRow';
import AutoComplete from './extras/virtualised/AutoComplete';
import BasicButton from './BasicButton';
import { SelectInput } from './FormInput';

export default hot(module)(class extends React.Component {
  static displayName = 'InviteUsers';

  static propTypes = {
      accountId: propTypes.number.isRequired,
      itemId: propTypes.number,
      isDocument: propTypes.bool,
      accountSelection: propTypes.bool,
      cancelButton: propTypes.bool,
  }

  state = {
      usersToInvite: [],
      emailsToInvite: [],
  }

  onAddUser(user) {
      const usersToInvite = this.state.usersToInvite || [];
      const userToInvite = { ...user };
      if (!this.props.accountSelection) {
          userToInvite.permission_type = '';
      } else {
          userToInvite.admin = false;
      }
      usersToInvite.push(userToInvite);
      this.autoComplete.clearSearch();
      this.setState({ usersToInvite });
  }

  onRemoveUser(user) {
      const usersToInvite = this.state.usersToInvite;
      _.remove(usersToInvite, userToInvite => userToInvite.id === user.id);
      this.setState({ usersToInvite });
  }

  onAddEmailInvite = () => {
      const emailsToInvite = this.state.emailsToInvite || [];
      const emailToInvite = {
          email: this.state.search,
      };
      if (!this.props.accountSelection) {
          emailToInvite.permission_type = '';
      } else {
          emailToInvite.admin = false;
      }
      emailsToInvite.push(emailToInvite);
      this.autoComplete.clearSearch();
      this.setState({ emailsToInvite });
  }

  onRemoveEmailInvite(email) {
      const emailsToInvite = this.state.emailsToInvite;
      _.remove(emailsToInvite, emailToInvite => emailToInvite.email === email);
      this.setState({ emailsToInvite });
  }

  filterUsers = (users, search) => {
      if (!search) return [];

      return _.filter(_.differenceBy(users, this.state.usersToInvite || [], 'id'), (user) => {
          const stringToSearch = user.first_name + user.last_name + user.email;
          return stringToSearch.toLowerCase().indexOf(search.replace(/\s+/g, '').toLowerCase()) !== -1;
      });
  }

  sendInvites = () => {
      const { usersToInvite, emailsToInvite, selectedAccountId } = this.state;
      const { accountId, itemId, isDocument } = this.props;
      if (usersToInvite.length) {
          if (!this.props.accountSelection) {
              _.each(usersToInvite, (userToInvite) => {
                  AppActions.updateItemPermissionsForUser(itemId, userToInvite.id, userToInvite.permission_type, isDocument);
              });
          } else {
              AppActions.inviteUsersToAccount(selectedAccountId || accountId, _.map(usersToInvite, ({ email, admin }) => ({ email, admin })));
          }
      }
      if (emailsToInvite.length) {
          if (itemId) {
              AppActions.inviteUsersToItem(itemId, emailsToInvite, isDocument);
          } else {
              AppActions.inviteUsersToAccount(selectedAccountId || accountId, emailsToInvite);
          }
      }
      closeModal();
  }

  isValid = () => {
      const { state: { usersToInvite, emailsToInvite }, props: { accountSelection } } = this;
      if (!usersToInvite.length && !emailsToInvite.length) return false;

      return !accountSelection ? _.every(usersToInvite, userToInvite => userToInvite.permission_type) && _.every(emailsToInvite, emailToInvite => emailToInvite.permission_type) : true;
  }

  onPermissionTypeChange = (id, e) => {
      const usersToInvite = _.cloneDeep(this.state.usersToInvite);
      _.find(usersToInvite, user => user.id === id).permission_type = Utils.safeParseEventValue(e);
      this.setState({ usersToInvite });
  }

  onEmailPermissionTypeChange = (email, e) => {
      const emailsToInvite = _.cloneDeep(this.state.emailsToInvite);
      _.find(emailsToInvite, { email }).permission_type = Utils.safeParseEventValue(e);
      this.setState({ emailsToInvite });
  }

  onAdminChange = (id, e) => {
      const usersToInvite = _.cloneDeep(this.state.usersToInvite);
      _.find(usersToInvite, user => user.id === id).admin = Utils.safeParseEventValue(e);
      this.setState({ usersToInvite });
  }

  onEmailAdminChange = (email, e) => {
      const emailsToInvite = _.cloneDeep(this.state.emailsToInvite);
      _.find(emailsToInvite, { email }).admin = Utils.safeParseEventValue(e);
      this.setState({ emailsToInvite });
  }

  renderSearch = (users) => {
      const { state: { search, usersToInvite, emailsToInvite } } = this;
      const filteredUsers = this.filterUsers(users, search);
      const showEmailInvite = Utils.isValidEmail(search) && !filteredUsers.length && !_.find(usersToInvite, user => user.email === search) && !_.find(emailsToInvite, { email: search });
      return (
          <React.Fragment>
              <AutoComplete
                data={filteredUsers}
                className="flex-1"
                renderRow={userToInvite => <ExistingMemberInviteRow key={userToInvite.id} user={userToInvite} onAdd={() => this.onAddUser(userToInvite)} />}
                onSearchChange={e => this.setState({ search: Utils.safeParseEventValue(e) })}
                renderOnSearch
                placeholder="Search via name or invite via email address..."
                containerHeight={filteredUsers.length > 4 ? 200 : 0}
                windowScrolling={false}
                disableRowClick
                listClassname="search-dropdown"
                rowHeight={50}
                onSelectChange={() => {}}
                renderSelectedItem={() => {}}
                ref={c => this.autoComplete = c}
              />
              {/* TODO <GroupInviteRow/> */}
              {showEmailInvite ? <EmailMemberInviteRow email={this.state.search} onAdd={this.onAddEmailInvite} /> : null}
          </React.Fragment>
      );
  }

  render() {
      const {
          state: {
              usersToInvite, emailsToInvite, isSending, selectedAccountId,
          },
          props: {
              accountId, accountSelection, cancelButton,
          },
      } = this;

      const totalInvites = usersToInvite.length + emailsToInvite.length;
      return (
          <form className="mt-4 mt-sm-0">
              <div className="col-md-6 mb-5 px-0">
                  <div className="mb-5">
                      {accountSelection ? (
                          <React.Fragment>
                              <label className="mt-4">Company</label>
                              <Row>
                                  <SelectInput
                                    className="mb-4" onChange={e => this.setState({ selectedAccountId: Utils.safeParseEventValue(e) })}
                                    value={selectedAccountId}
                                  >
                                      <option value="">Select a company..</option>
                                      {_.map(AccountStore.getUser().accounts, ({ account }) => (
                                          <option key={account.id} value={account.id}>{account.name}</option>
                                      ))}
                                  </SelectInput>
                              </Row>
                          </React.Fragment>
                      ) : null}
                      {!accountSelection ? (
                          <AccountUsersProvider accountId={accountId}>
                              {({ users }) => this.renderSearch(users)}
                          </AccountUsersProvider>
                      ) : selectedAccountId ? (
                          <UsersProvider>
                              {({ users }) => this.renderSearch(users)}
                          </UsersProvider>
                      ) : null}
                  </div>
                  {_.map(this.state.usersToInvite || [], (userToInvite) => {
                      const user = userToInvite;
                      return (
                          <MemberInviteRow
                            key={userToInvite.id} user={userToInvite} onRemove={() => this.onRemoveUser(userToInvite)}
                            permissionType={userToInvite.permission_type} accountSelection={accountSelection}
                            admin={userToInvite.admin} onAdminChange={e => this.onAdminChange(user.id, e)}
                            onPermissionTypeChange={e => this.onPermissionTypeChange(user.id, e)}
                          />
                      );
                  })}
                  {_.map(this.state.emailsToInvite || [], emailToInvite => (
                      <EmailInvitedRow
                        key={emailToInvite.email} invitee={emailToInvite} onRemove={() => this.onRemoveEmailInvite(emailToInvite.email)}
                        permissionType={emailToInvite.permission_type} accountSelection={accountSelection}
                        admin={emailToInvite.admin} onAdminChange={e => this.onEmailAdminChange(emailToInvite.email, e)}
                        onPermissionTypeChange={e => this.onEmailPermissionTypeChange(emailToInvite.email, e)}
                      />
                  ))}
                  <div className="form__footer mt-5">
                      <div className="row justify-content-center">
                          <BasicButton buttonText={cancelButton ? 'Cancel' : 'Skip'} className="btn--transparent order-1 order-sm-0" onClick={() => closeModal()} />
                          <Column>
                              <BasicButton buttonText={isSending ? 'Sending..' : 'Send Invites'} disabled={!this.isValid()} onClick={this.sendInvites}/>
                              {totalInvites > 0 ? (
                                  <p className="text-small text-center mt-2">
                                      {totalInvites}
                                      {' '}
invite
                                      {totalInvites > 1 ? 's' : ''}
                                      {' '}
will be sent
                                      <img src="/images/icons/paper-plane.svg" alt="Add" className="ml-1"/>
                                  </p>
                              ) : null}
                          </Column>
                      </div>
                  </div>
              </div>
          </form>

      );
  }
});
