const BaseStore = require('./base/_store');
const data = require('./base/_data');
const endpoints = require('./endpoints');

const store = Object.assign({}, BaseStore, {
    id: 'account-hierarchy',
    getHierarchy() {
        return store.model;
    },
});

const controller = {
    getHierarchy(id) {
        store.loading();
        endpoints.getAccountHierarchy(id)
            .then((res) => {
                store.accountId = id;
                store.model = res;
                store.loaded();
            })
            .catch(error => API.handleError(store, error));
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_ACCOUNT_HIERARCHY:
            controller.getHierarchy(action.id);
            break;
        case Actions.ON_LOGOUT:
            store.model = null;
            store.error = null;
            break;
        default:
    }
});

// AsyncStorage.getItem('projects', (err, res) => {
//     if (res) {
//         store.model = JSON.parse(res);
//     }
// });

controller.store = store;
module.exports = controller.store;
