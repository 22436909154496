import React from 'react';

export default hot(module)(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static displayName = 'DocumentInvitePage';

  state = {
  };

  componentDidMount() {
      API.trackPage(Constants.pages.DOCUMENT_INVITE_PAGE);

      const token = _.get(this.context.router, 'route.match.params.token');
      const documentId = _.get(this.context.router, 'route.match.params.documentId');
      if (!token || !documentId) {
          this.context.router.history.replace('/dashboard');
          return;
      }

      // Redirect to register
      this.context.router.history.replace(`/register?token=${token}&documentId=${documentId}`);
  }

  render = () => {
      return (
          <div className="app-container centered-container">
              <Loader />
          </div>
      );
  }
});
