import React from 'react';

import { CompanyTile, ProjectTile } from '../ItemTile';
import StatCard, { UsersStatCard, AllUsersStatCard } from '../StatCard';
import GridToggleButton from '../GridToggleButton';
import Table, { ProjectRow } from '../ItemTable';
import CompanyTable, { CompanyRow } from '../CompanyTable';
import { CompanySelect, ProjectSelect, CollectionSelect, DocumentSelect } from '../PanelSelect';

import CreateModal from '../modals/Create';
import CreateProjectModal from '../modals/CreateProject';
import CreateCompanyModal from '../modals/CreateCompany';
import DeleteItemModal from '../modals/DeleteItem';

export default hot(module)(ConfigProvider(class extends React.Component {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static propTypes = {
        accountId: propTypes.string,
    }

    static displayName = 'DashboardPage';

    state = {
        viewType: window.viewType,
    };

    componentDidMount() {
        API.trackPage(Constants.pages.DASHBOARD_PAGE);
    }


    create = (projects) => {
        openModal({ body: <CreateModal accountId={Utils.getAccountId(this.props)} projects={projects} />, className: 'modal--fullscreen', disableDismissOnBackdrop: true });
    }

    onViewTypeChanged = () => {
        window.viewType = window.viewType === 'table' ? 'tile' : 'table';
        AsyncStorage.setItem('viewType', window.viewType);
        this.setState({ viewType: window.viewType });
    }

    onAddProject = () => {
        openModal({ body: <CreateProjectModal accountId={Utils.getAccountId(this.props)} />, className: 'modal--fullscreen', disableDismissOnBackdrop: true });
    }

    onEditProject = (project) => {
        openModal({ body: <CreateProjectModal
          accountId={Utils.getAccountId(this.props)} edit project={project}
          onDelete={() => {
              closeModal();
              setTimeout(() => this.deleteProject(project), 501);
          }}
        />,
        className: 'modal--fullscreen',
        disableDismissOnBackdrop: true });
    }

    onEditCompany = (account) => {
        openModal({ body: <CreateCompanyModal edit account={account} />, className: 'modal--fullscreen', disableDismissOnBackdrop: true });
    }

    deleteProject = (project) => {
        openModal({
            body: <DeleteItemModal name={project.name} type="project" onConfirm={() => AppActions.deleteProject(project.id)} />,
            className: 'modal--default',
        });
    }

    onError = (error) => {
        toast(error);
    }

  render = () => {
      const isAdmin = AccountStore.isAdmin();
      const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
      const { accountId, presentationMode } = this.props;
      const { viewType } = this.state;
      return (
          <div className=" content-pane">
              <AccountProvider>
                  {({ isLoading, user }) => {
                      const accountName = Utils.getAccountName(this.props);
                      return (
                          <div>
                              {isLoading || !user ? <Loader /> : (
                                  <div>
                                      {!accountId && (hasMultipleAccounts || isAdmin) ? (
                                          <React.Fragment>
                                              {isAdmin ? (
                                                  !presentationMode ? (
                                                      <button type="button" className="btn--action round" onClick={this.create}>
                                                          <img src="/images/icons/create-white.svg" alt="Add" />
                                                      </button>
                                                  ) : null

                                              ) : null }

                                              <div className="col-md-12">
                                                  <div className="row justify-content-end pb-4">
                                                      <GridToggleButton
                                                        onClick={this.onViewTypeChanged}
                                                        enabled={window.viewType === 'table'}
                                                      />
                                                  </div>
                                              </div>
                                              <div className="row mb-5">
                                                  {viewType === 'table' ? (
                                                      <div className="col-md-12">
                                                          <CompanyTable>
                                                              {_.map(user.accounts, ({ account }) => (
                                                                  <CompanyRow key={account.id} account={account} onClick={() => this.context.router.history.push(`/account/${account.id}`)} />
                                                              ))}
                                                          </CompanyTable>
                                                      </div>
                                                  ) : _.map(user.accounts, ({ account }) => (
                                                      <div key={account.id} className="col-md-3 mb-3 mb-sm-5 col-lg-2">
                                                          <CompanyTile
                                                            account={account}
                                                            onClick={() => this.context.router.history.push(`/account/${account.id}`)}
                                                            onEdit={() => this.onEditCompany(account)}
                                                          />
                                                      </div>
                                                  ))}
                                              </div>
                                          </React.Fragment>
                                      ) : (
                                          <ProjectsProvider accountId={Utils.getAccountId(this.props)} onProjectsNotFound={this.onProjectsNotFound} onError={this.onError}>
                                              {({ isLoading: projectsLoading, projects, error, noProjectsFound, stats }, { refresh }) => {
                                                  if (error) return <p>Error retrieving projects</p>;
                                                  if (projectsLoading || (!projects && !noProjectsFound)) return <Loader />;
                                                  const isAccountAdmin = isAdmin || accountId ? _.find(user.accounts, ({ account }) => account.id === parseInt(accountId)).admin : user.accounts[0].account.admin;
                                                  return (
                                                      <React.Fragment>
                                                          {!presentationMode ? (
                                                              <button type="button" className="btn--action round" onClick={() => this.create(projects)}>
                                                                  <img src="/images/icons/create-white.svg" alt="Add" />
                                                              </button>
                                                          ) : null}

                                                          <div className="col-md-12">
                                                              <div className="row justify-content-end pb-4">
                                                                  <GridToggleButton
                                                                    onClick={this.onViewTypeChanged}
                                                                    enabled={window.viewType === 'table'}
                                                                  />
                                                              </div>
                                                          </div>
                                                          {!presentationMode ? (
                                                              <div className="row">
                                                                  {stats && stats.num_documents != null ? (
                                                                      <div className="col-6 col-md-3 mb-3 mb-lg-5">
                                                                          <StatCard
                                                                            title="Documents"
                                                                            content={stats.num_documents} // TODO document_count always zero on old API
                                                                            footer={`${stats.num_new_documents} New documents created this month`}
                                                                            onClick={() => this.context.router.history.push(`/account/${Utils.getAccountId(this.props)}/documents`)}
                                                                          />
                                                                      </div>
                                                                  ) : null}
                                                                  {stats && stats.num_collections != null ? (
                                                                      <div className="col-6 col-md-3 mb-3 mb-lg-5">
                                                                          <StatCard
                                                                            title="Collections"
                                                                            content={stats.num_collections}
                                                                            footer={`${stats.num_new_collections} New collections created this month`}
                                                                            onClick={() => this.context.router.history.push(`/account/${Utils.getAccountId(this.props)}/collections`)}
                                                                          />
                                                                      </div>
                                                                  ) : null}
                                                                  {stats && stats.members != null ? (
                                                                      <div className="col-6 col-md-3 mb-3 mb-lg-5">
                                                                          <UsersStatCard
                                                                            title="Members"
                                                                            users={stats.members}
                                                                            footer={`${stats.num_new_members || 0} new members joined ${accountName} this month`}
                                                                            onClick={isAccountAdmin ? () => this.context.router.history.push(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : ''}/members`) : null}
                                                                          />
                                                                      </div>
                                                                  ) : null}
                                                                  {stats && stats.size != null ? (
                                                                      <div className="col-6 col-md-3 mb-3 mb-lg-5">
                                                                          <StatCard
                                                                            title="Usage"
                                                                            content={filesize(stats.size)}
                                                                            footer={`${accountName} has 7.08GB storage space`}
                                                                          />
                                                                      </div>
                                                                  ) : null}
                                                              </div>
                                                          ) : null}

                                                          {projects && projects.length ? (
                                                              <div className="row mb-5">
                                                                  {viewType === 'table' ? (
                                                                      <div className="col-md-12">
                                                                          <Table>
                                                                              {_.map(projects, project => (
                                                                                  <ProjectRow
                                                                                    key={project.id} project={project} onClick={() => this.context.router.history.push(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : ''}/project/${project.id}`)}
                                                                                    onDelete={() => this.deleteProject(project)}
                                                                                  />
                                                                              ))}
                                                                          </Table>
                                                                      </div>
                                                                  ) : _.map(projects, project => (
                                                                      <div key={project.id} className="col-lg-4 col-md-4 mb-3 mb-sm-5 col-xl-3">
                                                                          <ProjectTile
                                                                            project={project}
                                                                            onClick={() => this.context.router.history.push(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : ''}/project/${project.id}`)}
                                                                            onEdit={() => this.onEditProject(project)}
                                                                          />
                                                                      </div>
                                                                  ))}
                                                              </div>
                                                          ) : (
                                                              <div className="col-lg-10 m-auto">
                                                                  <div className="text-center">
                                                                      <h2 className="">There are no projects in this company</h2>
                                                                      <div className="row mb-5 justify-content-center ">
                                                                          <div className="col-md-3">
                                                                              <ProjectSelect
                                                                                panelTitle="Add Project"
                                                                                panelText="Create a project to securely share documents within your project team"
                                                                                onClick={this.onAddProject}
                                                                              />
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </React.Fragment>
                                                  );
                                              }}
                                          </ProjectsProvider>
                                      )}
                                  </div>
                              )}
                          </div>
                      );
                  }}
              </AccountProvider>
          </div>
      );
  }
}));
