/**
 * Created by andrewmoffatt on 12/03/2019.
 */
import React from 'react';

import { TextInput, ColourInput, ColourSelectInput } from '../FormInput';
import BasicButton from '../BasicButton';
import UploadInput from '../UploadInput';
import PageController from '../PageController';
import InviteUsers from '../InviteUsers';
import ProjectStore from '../../../common/stores/project-store';
import TableActionButton from '../TableActionButton';

export default hot(module)(class extends React.Component {
    static displayName = 'CreateProjectModal';

    static propTypes = {
        accountId: propTypes.number.isRequired,
        edit: propTypes.bool,
        project: propTypes.object,
        onDelete: propTypes.func,
    }

    state={
        name: '',
        settings: {
            brand_colour: '#192025',
            theme: 'dark',
        },
    };

    componentWillMount() {
        if (this.props.edit) {
            const state = Object.assign({}, this.state, this.props.project);
            if (!state.settings) state.settings = this.state.settings;
            this.setState(state);
        }
    }

    create = () => {
        AppActions.createProject(this.state);
    }

    edit = () => {
        AppActions.editProject(this.props.project.id, this.state);
    }

    changed = () => {
        let changed = false;
        const { props: { project }, state: { name, coverImage, cover_image_url, settings: { brand_colour, theme } } } = this;
        if (name !== project.name || coverImage || project.cover_image_url !== cover_image_url
            || brand_colour !== _.get(project, 'settings.brand_colour') || theme !== _.get(project, 'settings.theme')) {
            changed = true;
        }

        return changed;
    }

    valid = () => {
        const { name, settings: { brand_colour } } = this.state;
        return name && Utils.isValidHexColour(brand_colour);
    }

    onSave = (projectId) => {
        if (this.props.edit) {
            if (ProjectStore.projectId === projectId) {
                AppActions.getProject(projectId, null, true);
            }
            closeModal();
            return;
        }
        if (!AccountStore.isAdmin()) {
            closeModal();
            return;
        }
        this.setState({ invite: !this.state.invite, projectId });
    }

    setSetting = (setting) => {
        this.setState({ settings: Object.assign({}, this.state.settings, setting) });
    }

    render = () => {
        const { accountId, edit, onDelete } = this.props;
        const { invite, settings: { brand_colour, theme }, projectId, name, cover_image_url } = this.state;
        return (
            <div className="fullscreen--modal content-pane">
                <ProjectsProvider accountId={accountId} onSave={this.onSave}>
                    {({ isSaving, error }) => (
                        <React.Fragment>
                            <Row className="justify-content-end">
                                <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                                    <img className="" src="/images/icons/remove.svg" alt="Remove" />
                                </button>
                            </Row>
                            <div className="col-lg-8 m-auto">
                                <div className="fullscreen-modal__content">
                                    <Row>
                                        <h2 className="flex-1 mb-1 mb-sm-3 mt-4 mt-sm-3">{`${edit ? 'Edit' : 'Create'} Project`}</h2>
                                        {!edit ? <PageController pageCount={2} activePageIndex={invite ? 1 : 0}/> : null}
                                    </Row>
                                    {!invite ? (
                                        <form className="form">
                                            <div className="form__content">
                                                <Column className="flex-1 mx-0 mb-4 mt-4">
                                                    <label>Project Details</label>
                                                    <TextInput
                                                      placeholder="Project Name..." onChange={e => this.setState({ name: Utils.safeParseEventValue(e) })}
                                                      value={name}
                                                    />
                                                </Column>
                                                <div className=" mb-4">
                                                    <UploadInput
                                                      inputTitle="Upload cover image" helperText="Recommended dimensions (pixels): Portrait (300:400), Landscape (400:300). Must not exceed 2MB." sizeLimit={2097152}
                                                      onChange={file => this.setState({ coverImage: file })} existingCoverImage={cover_image_url}
                                                      onRemoveExistingCoverImage={() => this.setState({ cover_image_url: null })}
                                                    />
                                                </div>
                                                <Row className="mb-4">
                                                    <Column>
                                                        <ColourInput
                                                          placeholder="Brand colour..." colour={brand_colour} onChange={colour => this.setSetting({ brand_colour: colour.hex })}
                                                          onChangeText={e => this.setSetting({ brand_colour: Utils.safeParseEventValue(e) })}
                                                          popoverClassName="popover-top"
                                                        />
                                                        <p className="text-smaller muted mt-2">Select a colour that identifies the brand or project.  This will be applied for the users consuming content on the tablet app.</p>
                                                    </Column>
                                                </Row>
                                                <Row className="mb-4">
                                                    <Column>
                                                        <ColourSelectInput
                                                          placeholder="Theme..." colour={theme === 'dark' ? '#192025' : '#fefefe'} onChange={e => this.setSetting({ theme: Utils.safeParseEventValue(e) })}
                                                          value={theme}
                                                        >
                                                            <option value="dark" className="option">Dark</option>
                                                            <option value="light" className="option">Light</option>
                                                        </ColourSelectInput>
                                                        <p className="text-smaller muted mt-2">Select a light or dark theme for this project.  This will be applied for the users of the tablet app.</p>
                                                    </Column>
                                                </Row>
                                            </div>

                                            {edit ? (
                                                <TableActionButton
                                                  labelText="Delete" labelSubtext="this project" src="/images/icons/trash--red.svg"
                                                  alt="Delete" onClick={onDelete} className="mt-5 mr-4 btn--negative"
                                                />
                                            ) : null}

                                            <div className="form__footer mt-5">
                                                {error ? (
                                                    <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                                                ) : null}
                                                <Row className="justify-content-center">
                                                    <BasicButton buttonText="Cancel" className="btn--transparent flex-grow-1 flex-sm-grow-0 order-1 order-sm-0" onClick={() => closeModal()} />
                                                    {edit ? (
                                                        <BasicButton
                                                          buttonText={isSaving ? 'Saving...' : 'Save Changes'} className=" flex-grow-1 flex-sm-grow-0" disabled={!this.valid() || !this.changed()}
                                                          onClick={() => this.edit()}
                                                        />
                                                    ) : (
                                                        <BasicButton
                                                          buttonText={isSaving ? 'Creating...' : 'Create'} className=" flex-grow-1 flex-sm-grow-0" disabled={!this.valid()}
                                                          onClick={() => this.create()}
                                                        />
                                                    )}
                                                </Row>
                                            </div>
                                        </form>
                                    ) : (
                                        <InviteUsers accountId={accountId} itemId={projectId} />
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </ProjectsProvider>
            </div>
        );
    }
});
