const AppActions = Object.assign({}, require('./base/_app-actions'), {
    getProjects(accountId, force) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_PROJECTS,
            accountId,
            force,
        });
    },
    getProject(id, folderId, force, isPublic) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_PROJECT,
            id,
            folderId,
            force,
            isPublic,
        });
    },
    onLogin() {
        Dispatcher.handleViewAction({
            actionType: Actions.ON_LOGIN,
        });
    },
    getConfig() {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_CONFIG,
        });
    },
    getAccounts() {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_ACCOUNTS,
        });
    },
    downloadDocument(id, document) {
        Dispatcher.handleViewAction({
            actionType: Actions.DOWNLOAD_DOCUMENT,
            id,
            document,
        });
    },
    updateDocumentDownload(id, document) {
        Dispatcher.handleViewAction({
            actionType: Actions.UPDATE_DOCUMENT_DOWNLOAD,
            id,
            document,
        });
    },
    cancelDocumentDownload(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.CANCEL_DOCUMENT_DOWNLOAD,
            id,
        });
    },
    deleteDocumentDownload(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.DELETE_DOCUMENT_DOWNLOAD,
            id,
        });
    },
    onLogout() {
        Dispatcher.handleViewAction({
            actionType: Actions.ON_LOGOUT,
        });
    },
    selectedProject(project) {
        Dispatcher.handleViewAction({
            actionType: Actions.SELECTED_PROJECT,
            project,
        });
    },
    getUsersForAccount(accountId, force) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_USERS_FOR_ACCOUNT,
            accountId,
            force,
        });
    },
    getUsersForItem(itemId, isDocument, force) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_USERS_FOR_ITEM,
            itemId,
            isDocument,
            force,
        });
    },
    getDocumentAssets(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_DOCUMENT_ASSETS,
            id,
        });
    },
    getUsers(force) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_USERS,
            force,
        });
    },
    getUser(userId, force) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_USER,
            userId,
            force,
        });
    },
    getUserDocuments(userId, force) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_USER_DOCUMENTS,
            userId,
            force,
        });
    },
    getUserInstances(userId, force) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_USER_INSTANCES,
            userId,
            force,
        });
    },
    createAccount(details) {
        Dispatcher.handleViewAction({
            actionType: Actions.CREATE_ACCOUNT,
            details,
        });
    },
    createProject(details) {
        Dispatcher.handleViewAction({
            actionType: Actions.CREATE_PROJECT,
            details,
        });
    },
    createCollection(accountId, collectionId, details) {
        Dispatcher.handleViewAction({
            actionType: Actions.CREATE_COLLECTION,
            accountId,
            collectionId,
            details,
        });
    },
    createDocument(instanceId, details) {
        Dispatcher.handleViewAction({
            actionType: Actions.CREATE_DOCUMENT,
            instanceId,
            details,
        });
    },
    updateDocumentAssets(id, assets) {
        Dispatcher.handleViewAction({
            actionType: Actions.UPDATE_DOCUMENT_ASSETS,
            id,
            assets,
        });
    },
    editCollection(id, details) {
        Dispatcher.handleViewAction({
            actionType: Actions.EDIT_COLLECTION,
            id,
            details,
        });
    },
    editProject(id, details) {
        Dispatcher.handleViewAction({
            actionType: Actions.EDIT_PROJECT,
            id,
            details,
        });
    },
    editAccount(id, details) {
        Dispatcher.handleViewAction({
            actionType: Actions.EDIT_ACCOUNT,
            id,
            details,
        });
    },
    editDocument(id, details) {
        Dispatcher.handleViewAction({
            actionType: Actions.EDIT_DOCUMENT,
            id,
            details,
        });
    },
    deleteDocument(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.DELETE_DOCUMENT,
            id,
        });
    },
    deleteDocumentAssets(assetIds) {
        Dispatcher.handleViewAction({
            actionType: Actions.DELETE_DOCUMENT_ASSETS,
            assetIds,
        });
    },
    deleteCollection(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.DELETE_COLLECTION,
            id,
        });
    },
    deleteProject(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.DELETE_PROJECT,
            id,
        });
    },
    publishDocument(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.PUBLISH_DOCUMENT,
            id,
        });
    },
    unpublishDocument(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.UNPUBLISH_DOCUMENT,
            id,
        });
    },
    getDocumentStats(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_DOCUMENT_STATS,
            id,
        });
    },
    getInstanceStats(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_INSTANCE_STATS,
            id,
        });
    },
    updateAccountPermissionsForUser(accountId, userId, permissionType) {
        Dispatcher.handleViewAction({
            actionType: Actions.UPDATE_ACCOUNT_PERMISSIONS_FOR_USER,
            accountId,
            userId,
            permissionType,
        });
    },
    updateItemPermissionsForUser(itemId, userId, permissionType, isDocument) {
        Dispatcher.handleViewAction({
            actionType: Actions.UPDATE_ITEM_PERMISSIONS_FOR_USER,
            itemId,
            userId,
            permissionType,
            isDocument,
        });
    },
    inviteUsersToAccount(id, users) {
        Dispatcher.handleViewAction({
            actionType: Actions.INVITE_USERS_TO_ACCOUNT,
            id,
            users,
        });
    },
    deactivateUsers(users) {
        Dispatcher.handleViewAction({
            actionType: Actions.DEACTIVATE_USERS,
            users,
        });
    },
    removeAccountPermissionsForUsers(accountId, users) {
        Dispatcher.handleViewAction({
            actionType: Actions.REMOVE_ACCOUNT_PERMISSIONS_FOR_USERS,
            accountId,
            users,
        });
    },
    removeAccountUsers(accountId, users) {
        Dispatcher.handleViewAction({
            actionType: Actions.REMOVE_ACCOUNT_USERS,
            accountId,
            users,
        });
    },
    removeItemPermissionsForUsers(itemId, users, isDocument) {
        Dispatcher.handleViewAction({
            actionType: Actions.REMOVE_ITEM_PERMISSIONS_FOR_USERS,
            itemId,
            users,
            isDocument,
        });
    },
    getDocument(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_DOCUMENT,
            id,
        });
    },
    activateUsers(users) {
        Dispatcher.handleViewAction({
            actionType: Actions.ACTIVATE_USERS,
            users,
        });
    },
    setUserRole(userId, roleName) {
        Dispatcher.handleViewAction({
            actionType: Actions.SET_USER_ROLE,
            userId,
            roleName,
        });
    },
    getAccountStats(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_ACCOUNT_STATS,
            id,
        });
    },
    toggleUserAdminStatus(accountId, userId) {
        Dispatcher.handleViewAction({
            actionType: Actions.TOGGLE_USER_ADMIN_STATUS,
            accountId,
            userId,
        });
    },
    inviteUsersToItem(id, users, isDocument) {
        Dispatcher.handleViewAction({
            actionType: Actions.INVITE_USERS_TO_ITEM,
            id,
            users,
            isDocument,
        });
    },
    joinAccount(token, documentId) {
        Dispatcher.handleViewAction({
            actionType: Actions.JOIN_ACCOUNT,
            token,
            documentId,
        });
    },
    editProfile(user) {
        Dispatcher.handleViewAction({
            actionType: Actions.EDIT_PROFILE,
            user,
        });
    },
    refreshUser() {
        Dispatcher.handleViewAction({
            actionType: Actions.REFRESH_USER,
        });
    },
    getAccountHierarchy(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_ACCOUNT_HIERARCHY,
            id,
        });
    },
    togglePresentationMode() {
        Dispatcher.handleViewAction({
            actionType: Actions.TOGGLE_PRESENTATION_MODE,
        });
    },
    getAllDocumentData(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.GET_ALL_DOCUMENT_DATA,
            id,
        });
    },
    makeDocumentPublic(id, password) {
        Dispatcher.handleViewAction({
            actionType: Actions.MAKE_DOCUMENT_PUBLIC,
            id,
            password,
        });
    },
    makeDocumentPrivate(id) {
        Dispatcher.handleViewAction({
            actionType: Actions.MAKE_DOCUMENT_PRIVATE,
            id,
        });
    },
});

global.AppActions = AppActions;
module.exports = AppActions;
