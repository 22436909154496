import React from 'react';

import BasicButton from '../BasicButton';
import { CompanySelect, ProjectSelect, CollectionSelect, DocumentSelect } from '../PanelSelect';
import CreateCompanyModal from './CreateCompany';
import CreateProjectModal from './CreateProject';
import CreateCollectionModal from './CreateCollection';
import CreateDocumentModal from './CreateDocument';

export default hot(module)(class extends React.Component {
    static displayName = 'CreateModal';

    static propTypes = {
        accountId: propTypes.number,
        projects: propTypes.array,
        projectData: propTypes.object,
    }

    state = {
        selectedType: '',
    }

    constructor(props) {
        super(props);
        const { accountId, projects, projectData } = props;
        if (projectData) {
            this.collectionOptions = _.map([_.last(projectData.breadcrumb)], currDir => ({ value: currDir.id, label: currDir.name })).concat(
                _.map(projectData.collections, collection => ({ value: collection.id, label: collection.name })),
            );
            this.defaultCollectionOption = { value: _.last(projectData.breadcrumb).id, label: _.last(projectData.breadcrumb).name };
            this.state.selectedCollection = this.defaultCollectionOption.value;
        } else if (accountId) {
            this.collectionOptions = _.map(projects, project => ({ value: project.id, label: project.name }));
            this.defaultCollectionOption = projects && projects.length ? ({ value: projects[0].id, label: projects[0].name }) : null;
            this.state.selectedCollection = this.defaultCollectionOption && this.defaultCollectionOption.value;
        } else {
            this.state.selectedType = 'company';
        }
    }

    onNext = () => {
        const { props: { accountId, projectData }, state: { selectedType, selectedCollection } } = this;
        const projectId = !projectData ? selectedCollection : projectData.breadcrumb[0].id;
        switch (selectedType) {
            case 'company':
                openModal({ body: <CreateCompanyModal />, className: 'modal--fullscreen', disableDismissOnBackdrop: true });
                break;
            case 'project':
                openModal({ body: <CreateProjectModal accountId={accountId} />, className: 'modal--fullscreen', disableDismissOnBackdrop: true });
                break;
            case 'collection':
                openModal({ body: <CreateCollectionModal accountId={accountId} projectId={projectId} collectionId={selectedCollection !== projectId ? selectedCollection : null} />, className: 'modal--fullscreen', disableDismissOnBackdrop: true });
                break;
            case 'document':
                openModal({ body: <CreateDocumentModal accountId={accountId} projectId={projectId} collectionId={selectedCollection !== projectId ? selectedCollection : null} />, className: 'modal--fullscreen', disableDismissOnBackdrop: true });
                break;
            default:
        }
    }

    render = () => {
        const { state: { selectedType }, props: { accountId, projectData } } = this;
        return (
            <div className="content-pane">
                <Row className="justify-content-end">
                    <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                        <img className="" src="/images/icons/remove.svg" alt="Remove" />
                    </button>
                </Row>
                <div className="col-lg-10 m-auto">
                    <div className="text-center">
                        <h2 className="mb-1 mt-4 mt-sm-0">Add to your Publicate</h2>
                        <p className="text-small mb-5">Create a new project or add to an existing project</p>
                        <form className="form">
                            <div className="form__content">
                                <div className="row mb-5 justify-content-center ">
                                    {!accountId ? (
                                        <div className="col-md-3">
                                            <CompanySelect
                                              panelTitle="Add Company"
                                              panelText="Add a new company to the Publicate platform"
                                              active={selectedType === 'company'}
                                              onClick={() => this.setState({ selectedType: 'company' })}
                                            />
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {!projectData ? (
                                                <div className="col-md-3">
                                                    <ProjectSelect
                                                      panelTitle="Add Project"
                                                      panelText="Create a project to securely share documents within your project team"
                                                      active={selectedType === 'project'}
                                                      onClick={() => this.setState({ selectedType: 'project' })}
                                                    />
                                                </div>
                                            ) : null}
                                            <React.Fragment>
                                                <div className="col-md-3">
                                                    <CollectionSelect
                                                      panelTitle="Add Collection"
                                                      panelText="Organise your project documents into specific collections"
                                                      active={selectedType === 'collection'}
                                                      onClick={() => this.setState({ selectedType: 'collection' })}
                                                      defaultValue={this.defaultCollectionOption}
                                                      onChange={collectionId => this.setState({ selectedCollection: collectionId })}
                                                    >
                                                        {this.collectionOptions}
                                                    </CollectionSelect>
                                                </div>
                                                <div className="col-md-3">
                                                    <DocumentSelect
                                                      panelTitle="Upload Document"
                                                      panelText="Upload a document into your company, project or collection"
                                                      active={selectedType === 'document'}
                                                      onClick={() => this.setState({ selectedType: 'document' })}
                                                      defaultValue={this.defaultCollectionOption}
                                                      onChange={collectionId => this.setState({ selectedCollection: collectionId })}
                                                    >
                                                        {this.collectionOptions}
                                                    </DocumentSelect>
                                                </div>
                                            </React.Fragment>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>

                            <div className="form__footer mt-5">
                                <Row className="justify-content-center">
                                    <BasicButton buttonText="Cancel" className="btn--transparent  flex-grow-1 flex-sm-grow-0 order-1 order-sm-0" onClick={() => closeModal()} />
                                    <BasicButton
                                      buttonText="Next" className=" flex-grow-1 flex-sm-grow-0" disabled={!selectedType}
                                      onClick={this.onNext}
                                    />
                                </Row>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
});
