import { Component } from 'react';
import UserStore from '../stores/user-store';

const TheComponent = class extends Component {
    static displayName = 'UserProvider';

    static propTypes = {
        userId: propTypes.number,
        onError: propTypes.func,
        onUserNotFound: propTypes.func,
    }

    constructor(props, context) {
        super(props, context);
        const idHasChanged = props.userId !== UserStore.userId;
        this.state = {
            isLoadingInitial: true,
            isLoading: !UserStore.model || idHasChanged,
            isSaving: UserStore.isSaving,
            error: UserStore.error,
            user: idHasChanged ? null : UserStore.getUser(),
            documents: idHasChanged ? null : UserStore.getDocuments(),
            instances: idHasChanged ? null : UserStore.getInstances(),
        };
    }

    componentDidMount() { // Get Projects on mount, listen to store changing
        ES6Component(this);
        AppActions.getUser(this.props.userId);
        this.listenTo(UserStore, 'change', () => {
            this.setState({
                isLoadingInitial: UserStore.isLoading,
                isLoading: UserStore.isLoading,
                isSaving: UserStore.isSaving,
                error: UserStore.error,
                isRefreshing: UserStore.isLoading && this.state.isRefreshing,
                user: UserStore.getUser(),
                documents: UserStore.getDocuments(),
                instances: UserStore.getInstances(),
            });
        });
        this.listenTo(UserStore, 'problem', () => {
            if (this.props.onError) {
                this.props.onError(UserStore.error);
            } else {
                this.setState({
                    error: UserStore.error,
                });
            }
        });
        this.listenTo(UserStore, 'loaded', () => {
            if (!UserStore.model && this.props.onUserNotFound) {
                this.props.onUserNotFound();
            }
        });
    }

    refresh = () => {
        this.setState({ isRefreshing: true }, () => {
            AppActions.getUser(this.props.userId, true);
        });
    };

    render() { // Render with project store state and sort function
        const { isLoading, isSaving, user, error, isRefreshing, documents, instances } = this.state;
        const { refresh } = this;
        return (
            this.props.children({
                isLoading,
                isSaving,
                error,
                isRefreshing,
                user,
                documents,
                instances,
            }, { refresh })
        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
