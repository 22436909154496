import React from 'react';
import AsyncStorage from 'polyfill-react-native/AsyncStorage';
import FileTable, { FileRow } from '../FileTable';
import StatCard, { UsersStatCard } from '../StatCard';
import UpdateDocument from '../modals/UpdateDocument';
import Switch from '../Switch';
import TableActionButton from '../TableActionButton';
import Confirmation from '../modals/Confirmation';
import DraftDocument from '../DraftDocument';
import UpdateAsset from '../modals/UpdateAsset';
import ShareDocument from '../modals/ShareDocument';
import data from '../../../common/stores/base/_data';

export default hot(module)(ConfigProvider(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static propTypes = {
      accountId: propTypes.string,
      projectId: propTypes.string,
      collectionId: propTypes.string,
      documentId: propTypes.string,
  }

  static displayName = 'DocumentPage';

  state = {
      viewType: window.viewType,
      assetsSelected: [],
      selectAll: false,
  };

  componentDidMount() {
      API.trackPage(Constants.pages.DOCUMENT_PAGE);
  }

  updateDocument = (assets, document_type) => {
      const { projectId, collectionId, documentId } = this.props;
      openModal({ body: (
          <UpdateDocument
            projectId={parseInt(projectId)} collectionId={parseInt(collectionId)} documentId={parseInt(documentId)}
            assets={assets} documentType={document_type}
          />
      ),
      className: 'modal--fullscreen',
      disableDismissOnBackdrop: true });
  }

  onError = (error) => {
      this.context.router.history.replace('/dashboard');
      toast(error);
  }

  onCollectionNotFound = () => {
      this.context.router.history.replace('/dashboard');
      toast(Constants.errors.NO_COLLECTION);
  }

  onUsersStatCardClicked = () => {
      const { pathname } = this.context.router.route.location;
      this.context.router.history.push(`${pathname}/members`);
  }

  onPublish = (e) => {
      const checked = Utils.safeParseEventValue(e);
      openConfirm({
          body: <Confirmation modalTitle={`${checked ? 'Publish' : 'Unpublish'} Document?`} modalText={`Are you sure you want to ${checked ? 'publish' : 'unpublish'} this document?`} />,
          className: 'modal--default',
          onYes: () => {
              if (checked) {
                  AppActions.publishDocument(this.props.documentId);
              } else {
                  AppActions.unpublishDocument(this.props.documentId);
              }
          },
      });
  }

  onSelectAll = (checked, assets) => {
      this.setState({ selectAll: checked, assetsSelected: checked ? _.map(assets, 'id') : [] });
  }

  onFileSelected = (id, checked) => {
      const { assetsSelected, selectAll } = this.state;
      if (checked) {
          if (assetsSelected.indexOf(id) === -1) {
              assetsSelected.push(id);
              this.setState({ assetsSelected });
          }
      } else {
          const index = assetsSelected.indexOf(id);
          if (index !== -1) {
              assetsSelected.splice(index, 1);
              this.setState({ assetsSelected, selectAll: !assetsSelected.length ? false : selectAll });
          }
      }
  }

  onDeleteSelected = () => {
      const { state: { assetsSelected } } = this;
      openConfirm({
          body: <Confirmation modalTitle="Delete forever?" modalText="Are you sure you want to delete this files? This cannot be undone." />,
          className: 'modal--default',
          yesText: `Delete (${assetsSelected.length} ${assetsSelected.length > 1 ? 'files' : 'file'})`,
          onYes: () => {
              AppActions.deleteDocumentAssets(assetsSelected);
          },
      });
  }

  onDownloadSelected = () => {
      // @TODO
  }

  onPreview = (url, type) => {
      if (!url) return;
      const { pathname } = this.context.router.route.location;
      this.context.router.history.push(`${pathname}/preview`, { documentType: type, url });
  }

  deleteFile = (asset) => {
      openConfirm({
          body: <Confirmation modalTitle="Delete forever?" modalText={`Are you sure you want to delete ${asset.file_location.substr(asset.file_location.lastIndexOf('/') + 1)}? This cannot be undone.`} />,
          className: 'modal--default',
          onYes: () => {
              AppActions.deleteDocumentAssets([asset.id]);
          },
      });
  }

  onSave = (assets) => {
      const assetsSelected = _.intersection(this.state.assetsSelected, assets ? _.map(assets, 'id') : []);
      this.setState({ assetsSelected });
  }

  updateFile = (asset, document_type) => {
      openModal({
          body: <UpdateAsset asset={asset} documentId={this.props.documentId} document_type={document_type} />,
          className: 'modal--default',
      });
  }

  downloadFile = (asset, documentType) => {
      fetch(`${Project.api}assets/${asset.id}/download`, {
          method: 'GET',
          headers: new Headers({
              'Authorization': `Bearer ${data.token}`,
          }),
      })
          .then(response => response.blob())
          .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = Utils.parseDocumentAssetFilename(asset.file_location, documentType);
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove(); // afterwards we remove the element again
          });
  }

  onPresentationMode = (on) => {
      if (on) {
          // Navigate to the collection / project this document is in
          const { accountId, collectionId, projectId } = this.props;
          this.context.router.history.replace(`${(AccountStore.hasMultipleAccounts() || AccountStore.isAdmin()) ? `/account/${accountId}` : ''}/project/${projectId}${collectionId ? `/${collectionId}` : ''}`);
      }
  }

  onShare = (links, passwordProtected) => {
      openModal({
          body: <ShareDocument links={links} documentId={this.props.documentId} passwordProtected={passwordProtected} />,
          className: 'modal--default',
      });
  }

  render = () => {
      const { props: { projectId, collectionId, documentId, presentationMode }, state: { assetsSelected, selectAll }, hasDraft = false } = this;
      return (
          <div className="content-pane">
              <AccountProvider>
                  {({ isLoading, user }) => (
                      <div>
                          {isLoading || !user || !projectId ? <Loader /> : (
                              <div>
                                  <ProjectProvider
                                    onCollectionNotFound={this.onCollectionNotFound}
                                    onError={this.onError}
                                    projectId={parseInt(projectId)}
                                    id={parseInt(collectionId) || parseInt(projectId)}
                                    documentId={parseInt(documentId)}
                                  >
                                      {({ isLoading: projectLoading, error, allDocuments }) => {
                                          if (error) return <p>Error retrieving project</p>;
                                          if (projectLoading || !allDocuments) return <Loader />;
                                          const document = _.find(allDocuments, { id: parseInt(documentId) });
                                          return (
                                              <DocumentPageProvider
                                                documentId={parseInt(documentId)}
                                                pageId={document && document.pages && document.pages.length && document.pages[0].id}
                                                onError={this.onError}
                                                onSave={this.onSave}
                                              >
                                                  {({ isLoading: documentAssetsLoading, error: documentAssetsError, assets, stats, document: details, links }) => {
                                                      if (documentAssetsError) return <p>Error retrieving document assets</p>;
                                                      if (documentAssetsLoading || !assets || !stats) return <Loader />;
                                                      const { published, published_at, file_size, document_type, permission_type } = document;
                                                      const { passwordProtected } = details;
                                                      const accountName = Utils.getAccountName(this.props);
                                                      return (
                                                          <React.Fragment>
                                                              {!presentationMode ? (
                                                                  <button type="button" className="btn--action round" onClick={() => this.updateDocument(assets, document_type)}>
                                                                      <img src="/images/icons/upload-white.svg" alt="Add" />
                                                                  </button>
                                                              ) : null}

                                                              {!presentationMode ? (
                                                                  <div className="row">
                                                                      {stats && stats.downloads != null ? (
                                                                          <div className="col-6 col-md-3 mb-3 mb-sm-5">
                                                                              <StatCard
                                                                                title="Downloaded"
                                                                                content={stats.downloads}
                                                                                footer="47% of invited members have downloaded this document"
                                                                              />
                                                                          </div>
                                                                      ) : null}
                                                                      {stats && stats.opens != null ? (
                                                                          <div className="col-6 col-md-3 mb-3 mb-sm-5">
                                                                              <StatCard
                                                                                title="Opened"
                                                                                content={stats.opens}
                                                                                footer="23% of invited members have opened this document"
                                                                              />
                                                                          </div>
                                                                      ) : null}
                                                                      {stats && stats.members != null ? (
                                                                          <div className="col-6 col-md-3 mb-3 mb-sm-5">
                                                                              <UsersStatCard
                                                                                title="Members"
                                                                                users={stats.members}
                                                                                footer={`${stats.num_new_members || 0} new members joined ${document.name} this month`}
                                                                                onClick={permission_type === 'OWNER' ? this.onUsersStatCardClicked : null}
                                                                              />
                                                                          </div>
                                                                      ) : null}
                                                                      <div className="col-6 col-md-3 mb-3 mb-sm-5">
                                                                          <StatCard
                                                                            title="File size"
                                                                            content={filesize(file_size)}
                                                                            footer={`${accountName} has 7.08GB storage space remaining`}
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              ) : null}

                                                              <Row className="justify-content-between">
                                                                  <h4 className="mt-3 mt-sm-0 mb-3 mb-sm-0">{`${Format.documentType(document_type)} Document`}</h4>
                                                                  <Row className="mb-3">
                                                                      {assetsSelected.length ? (
                                                                          <React.Fragment>
                                                                              <TableActionButton
                                                                                labelText="Download all" labelSubtext={`${assetsSelected.length} selected`} src="/images/icons/download.svg"
                                                                                alt="Download" onClick={this.onDownloadSelected} className="mr-4 inactive-feature"
                                                                              />
                                                                              <TableActionButton
                                                                                labelText="Delete all" labelSubtext={`${assetsSelected.length} selected`} src="/images/icons/delete.svg"
                                                                                alt="Delete" onClick={this.onDeleteSelected}
                                                                              />
                                                                          </React.Fragment>
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              {!!links && (
                                                                              <TableActionButton
                                                                                labelText={(
                                                                                    <span>
Share
                                                                                        <br />
Document
                                                                                    </span>
                                                                            )} src="/images/icons/shareable--circle.svg"
                                                                                alt="Share"
                                                                                onClick={() => this.onShare(links, passwordProtected)}
                                                                              />
                                                                              )}
                                                                              {(details && details.viewer_url) ? (
                                                                                  <TableActionButton
                                                                                    labelText={(
                                                                                        <span>
                                                                                Preview
                                                                                            <br />
                                                                                Document
                                                                                        </span>
                                                                            )} src="/images/icons/preview--circle.svg"
                                                                                    alt="Preview"
                                                                                    onClick={() => this.onPreview(details && details.viewer_url, document_type)}
                                                                                    disabled={!details || !details.viewer_url}
                                                                                  />
                                                                              ) : null}

                                                                          </React.Fragment>
                                                                      )}
                                                                      <Switch
                                                                        onChange={this.onPublish} checked={published} labelText={published ? 'Published' : 'Make Live'}
                                                                        labelSubtext={published ? Format.publishedDate(published_at) : 'Not currently live'}
                                                                      />
                                                                  </Row>
                                                              </Row>
                                                              <div className="row mb-2 mb-sm-5">
                                                                  <div className="col-md-12">
                                                                      <FileTable selectAll={selectAll} onSelectAll={checked => this.onSelectAll(checked, assets)}>
                                                                          {_.map(assets, asset => (
                                                                              <FileRow
                                                                                key={asset.id} file={asset} onFileSelected={this.onFileSelected}
                                                                                selected={assetsSelected.indexOf(asset.id) !== -1}
                                                                                onDelete={() => this.deleteFile(asset)}
                                                                                documentType={document_type} permission_type={permission_type}
                                                                                onUpdate={() => this.updateFile(asset, document_type)}
                                                                                onDownload={() => this.downloadFile(asset, document_type)}
                                                                              />
                                                                          ))}
                                                                      </FileTable>
                                                                  </div>
                                                              </div>
                                                          </React.Fragment>
                                                      );
                                                  }}
                                              </DocumentPageProvider>
                                          );
                                      }}
                                  </ProjectProvider>
                                  <div>
                                      {hasDraft ? (
                                          <div>
                                              <h4>Draft Document</h4>
                                              <DraftDocument/>
                                          </div>
                                      ) : null}
                                  </div>
                              </div>
                          )}
                      </div>
                  )}
              </AccountProvider>
          </div>
      );
  }
}));
