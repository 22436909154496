import React from 'react';

import { TextInput, EmailInput, PasswordInput, SelectInput, ColourInput, ColourSelectInput, TextArea } from '../FormInput';
import BasicButton from '../BasicButton';
import UploadInput from '../UploadInput';
import PageController from '../PageController';
import InviteUsers from '../InviteUsers';
import {Collapse} from "bootstrap/js/src";

export default hot(module)(class extends React.Component {
    static displayName = 'CreateCompanyModal';

    static propTypes = {
        edit: propTypes.bool,
        account: propTypes.object,
    }

    state = {
        name: '',
        settings: {
            brand_colour: '#192025',
            theme: 'dark',
        },
    };

    componentWillMount() {
        if (this.props.edit) {
            const state = Object.assign({}, this.state, this.props.account);
            if (!state.settings) state.settings = this.state.settings;
            this.setState(state);
        }
    }

    create = () => {
        AppActions.createAccount(this.state);
    }

    edit = () => {
        AppActions.editAccount(this.props.account.id, this.state);
    }

    changed = () => {
        let changed = false;
        const { props: { account }, state: { name, logoImage, logo_url, settings: { brand_colour, theme } } } = this;
        if (name !== account.name || logoImage || account.logo_url !== logo_url || brand_colour !== _.get(account, 'settings.brand_colour') || theme !== _.get(account, 'settings.theme')) {
            changed = true;
        }

        return changed;
    }

    valid = () => {
        const { name, settings: { brand_colour }, logoImage } = this.state;
        return name && Utils.isValidHexColour(brand_colour) && (!this.props.edit ? logoImage : true);
    }

    onSave = (accountId) => {
        if (this.props.edit) {
            closeModal();
            return;
        }
        this.setState({ invite: !this.state.invite, accountId });
    }

    setSetting = (setting) => {
        this.setState({ settings: Object.assign({}, this.state.settings, setting) });
    }

    render = () => {
        const { props: { edit }, state: { invite, settings: { brand_colour, theme }, accountId, name, logo_url } } = this;
        return (
            <div className="fullscreen--modal content-pane">
                <AccountProvider onSave={this.onSave}>
                    {({ isSaving, error }) => (
                        <React.Fragment>
                            <Row className="justify-content-end">
                                <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                                    <img className="" src="/images/icons/remove.svg" alt="Remove" />
                                </button>
                            </Row>
                            <div className="col-lg-8 m-auto">
                                <div className="fullscreen-modal__content">
                                    <Row>
                                        <h2 className="flex-1 mb-1 mb-sm-3 mt-4 mt-sm-3">{`${edit ? 'Edit' : 'Create'} Company`}</h2>
                                        {!edit ? <PageController pageCount={2} activePageIndex={invite ? 1 : 0}/> : null}
                                    </Row>
                                    {!invite ? (
                                        <form className="form">
                                            <div className="form__content">
                                                <Column className="flex-1 mx-0 mb-4">
                                                    <label>Company Details</label>
                                                    <TextInput placeholder="Company Name..." onChange={e => this.setState({ name: Utils.safeParseEventValue(e) })} value={name}/>
                                                </Column>
                                                <div className="mb-4">
                                                    <UploadInput
                                                      inputTitle="Upload cover image" helperText="Recommended dimensions (pixels): Portrait (300:400), Landscape (400:300). Must not exceed 2MB." sizeLimit={2097152}
                                                      onChange={file => this.setState({ logoImage: file })} existingCoverImage={logo_url}
                                                      onRemoveExistingCoverImage={() => this.setState({ logo_url: null })}
                                                    />
                                                </div>
                                                <Row className="mb-4">
                                                    <Column>
                                                        <ColourInput
                                                          placeholder="Brand colour..." colour={brand_colour} onChange={colour => this.setSetting({ brand_colour: colour.hex })}
                                                          onChangeText={e => this.setSetting({ brand_colour: Utils.safeParseEventValue(e) })}
                                                          popoverClassName="popover-top"
                                                        />
                                                        <p className="text-smaller muted mt-2">Select a colour that identifies the brand or company.  This will be applied for the users of the tablet app.</p>
                                                    </Column>
                                                </Row>
                                                <Row className="mb-4">
                                                    <Column>
                                                        <ColourSelectInput
                                                          placeholder="Theme..." colour={theme === 'dark' ? '#192025' : '#fefefe'} onChange={e => this.setSetting({ theme: Utils.safeParseEventValue(e) })}
                                                          value={theme}
                                                        >
                                                            <option value="dark" className="option">Dark</option>
                                                            <option value="light" className="option">Light</option>
                                                        </ColourSelectInput>
                                                        <p className="text-smaller muted mt-2">Select a colour that identifies the brand or document.  This will be applied for the users consuming content on the tablet app.</p>
                                                    </Column>
                                                </Row>
                                            </div>

                                            <div className="form__footer mt-5">
                                                {error ? (
                                                    <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                                                ) : null}
                                                <Row className="justify-content-center">
                                                    <BasicButton buttonText="Cancel" className="btn--transparent flex-grow-1 flex-sm-grow-0 order-1 order-sm-0" onClick={() => closeModal()} />
                                                    {edit ? (
                                                        <BasicButton
                                                          buttonText={isSaving ? 'Saving...' : 'Save Changes'} className=" flex-grow-1 flex-sm-grow-0" disabled={!this.valid() || !this.changed()}
                                                          onClick={() => this.edit()}
                                                        />
                                                    ) : (
                                                        <BasicButton
                                                          buttonText={isSaving ? 'Creating...' : 'Create'} className=" flex-grow-1 flex-sm-grow-0" disabled={!this.valid()}
                                                          onClick={() => this.create()}
                                                        />
                                                    )}
                                                </Row>
                                            </div>
                                        </form>
                                    ) : (
                                        <InviteUsers accountId={accountId} />
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </AccountProvider>
            </div>
        );
    }
});
