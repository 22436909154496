const BaseStore = require('./base/_store');
const data = require('./base/_data');
const endpoints = require('./endpoints');

const store = Object.assign({}, BaseStore, {
    id: 'account-users',
    getUsers() {
        return bulletTrain.hasFeature('no_users') ? [] : store.model;
    },
});

const controller = {
    getUsersForAccount(accountId) {
        store.loading();
        if (accountId !== store.accountId) {
            store.model = [];
        }
        store.accountId = accountId;
        endpoints
            .getUsersForAccount(accountId)
            .then(controller.loaded)
            .catch((error) => {
                API.handleError(store, error);
            });
    },
    loaded(res) {
        store.model = _.sortBy(res, 'join_date');
        store.loaded();
    },
    updatePermissionsForUser(accountId, userId, permissionType) {
        store.saving();
        endpoints.updateAccountPermissionsForUser(accountId, userId, permissionType)
            .then(() => controller.getUsersForAccount(accountId))
            .then(() => {
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    inviteUsers(id, users) {
        store.saving();
        endpoints.inviteUsersToAccount(id, {
            base_invite_url: `${window.location.origin}/register`,
            invites: users,
        })
            .then(() => {
                toast(`User${users.length > 1 ? 's' : ''} invited successfully`);
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    removePermissionsForUsers(accountId, users) {
        store.saving();
        endpoints.removeAccountPermissionsForUsers(accountId, users)
            .then(() => controller.getUsersForAccount(accountId))
            .then(() => {
                toast(`Removed user${users.length > 1 ? 's' : ''} successfully`);
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    toggleUserAdminStatus(accountId, userId) {
        store.saving();
        endpoints.toggleUserAdminStatus(accountId, userId)
            .then(() => controller.getUsersForAccount(accountId))
            .then(() => {
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    removeUsers(accountId, users) {
        store.saving();
        endpoints.removeAccountUsers(accountId, users)
            .then(() => controller.getUsersForAccount(accountId))
            .then(() => {
                toast(`Removed user${users.length > 1 ? 's' : ''} successfully`);
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_USERS_FOR_ACCOUNT:
            controller.getUsersForAccount(action.accountId, action.force);
            break;
        case Actions.UPDATE_ACCOUNT_PERMISSIONS_FOR_USER:
            controller.updatePermissionsForUser(action.accountId, action.userId, action.permissionType);
            break;
        case Actions.INVITE_USERS_TO_ACCOUNT:
            controller.inviteUsers(action.id, action.users);
            break;
        case Actions.REMOVE_ACCOUNT_PERMISSIONS_FOR_USERS:
            controller.removePermissionsForUsers(action.accountId, action.users);
            break;
        case Actions.TOGGLE_USER_ADMIN_STATUS:
            controller.toggleUserAdminStatus(action.accountId, action.userId);
            break;
        case Actions.REMOVE_ACCOUNT_USERS:
            controller.removeUsers(action.accountId, action.users);
            break;
        case Actions.ON_LOGOUT:
            store.model = null;
            store.error = null;
            break;
        default:
    }
});

// AsyncStorage.getItem('projects', (err, res) => {
//     if (res) {
//         store.model = JSON.parse(res);
//     }
// });

controller.store = store;
module.exports = controller.store;
