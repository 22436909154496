const Project = {
    debug: false,
    env: 'prod',
    api: 'https://api.publicatehouse.com/api/',
    bulletTrain: 'iNVphMCUAb3EYSNCk5hMDY',
    defaultAccountName: 'Publicate',
};

if (typeof DeviceInfo !== 'undefined') {
    switch (DeviceInfo.getBundleId()) {
        case 'com.publicatehouse.publicate':
            Project.isMaster = true;
            Project.defaultAccount = 6;
            Project.defaultProject = 1;
            break;
        case 'com.publicatehouse.watg':
            Project.defaultAccount = 12;
            Project.defaultProject = 52;
            break;
    }
}

global.Project = Project;
module.exports = Project;
