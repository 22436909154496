// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class UserNav extends PureComponent {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static displayName = 'UserNav';

    static propTypes = {
        user: propTypes.shape({
            image_path: propTypes.string,
            first_name: propTypes.string,
            last_name: propTypes.string,
            roles: propTypes.arrayOf(propTypes.shape({
                display_name: propTypes.string,
            })),
        }),
        logout: propTypes.func,
    };

    render() {
        const {
            props: {
                user: {
                    avatar_url,
                    first_name = '',
                    last_name = '',
                    roles = [{ display_name: 'User' }],
                },
                logout,
            } } = this;

        return (
            <div className="user-nav dropdown">
                <button
                  className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                >
                    <Row className="align-top">
                        {avatar_url ? (
                            <img
                              className="avatar round"
                              alt={`${first_name} ${last_name} Avatar`}
                              src={avatar_url}
                            />
                        ) : (
                            <div className="avatar__text avatar__text--medium round bold">
                                {first_name[0].toUpperCase()}
                            </div>
                        )}
                        <Row className="ml-2 text-left avatar__details">
                            <Column>
                                <p className="text-small">{`${first_name} ${last_name}`}</p>
                                <p className="user-nav__role text-smaller muted">{roles.length ? roles[0].display_name : 'User'}</p>
                            </Column>
                            <Column className="ml-3 muted">
                                <ion-icon className="user-nav__icon" name="ios-arrow-down" />
                            </Column>
                        </Row>
                    </Row>
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-wide" aria-labelledby="dropdownMenuButton">
                    <button type="button" className="dropdown-item text-small" onClick={() => this.context.router.history.push(`/profile/${AccountStore.getUser().id}`)}>Profile</button>
                    <button type="button" className="dropdown-item text-small" onClick={logout}>Logout</button>
                </div>
            </div>
        );
    }
}
