import React, { Component } from 'react';
import AccountStore from '../stores/account-store';

const AccountProvider = class extends Component {
	static displayName = 'AccountProvider';

	constructor(props, context) {
	    super(props, context);
	    this.state = {
	        isLoading: AccountStore.isLoading,
	        user: AccountStore.getUser(),
	    };
	}

	componentDidMount() {
	    ES6Component(this);
	    this.listenTo(AccountStore, 'change', () => {
	        this.setState({
	            isLoading: AccountStore.isLoading,
	            isSaving: AccountStore.isSaving,
	            user: AccountStore.getUser(),
	            error: AccountStore.error,
	        });
	    });

	    this.listenTo(AccountStore, 'loaded', (fromRegister) => {
	        this.props.onLogin && this.props.onLogin(fromRegister);
	    });

	    this.listenTo(AccountStore, 'saved', () => {
	        this.props.onSave && this.props.onSave(AccountStore.savedId);
	    });

	    this.listenTo(AccountStore, 'logout', () => {
	        this.setState({
	            isLoading: false,
	            isSaving: false,
	            user: AccountStore.getUser(),
	        });
	        this.props.onLogout && this.props.onLogout();
	    });
	    this.listenTo(AccountStore, 'problem', () => {
	        this.setState({
	            isLoading: AccountStore.isLoading,
	            isSaving: AccountStore.isSaving,
	            error: AccountStore.error,
	        });
	    });
	    this.listenTo(AccountStore, 'forbidden', () => {
	        this.props.onForbidden && this.props.onForbidden();
	    });
	    this.listenTo(AccountStore, 'documentRedirect', (documentId) => {
	        this.props.onDocumentRedirect && this.props.onDocumentRedirect(documentId);
	    });
	}


	login = (details) => {
	    AppActions.login(details);
	};

	register = (details) => {
	    AppActions.register(details);
	};

	clearError = () => {
	    AccountStore.error = null;
	    AccountStore.changed();
	}

	logout = () => {
	    AppActions.logout();
	}

	render() {
	    const { isLoading, isSaving, user, error } = this.state;
	    return (
	        this.props.children({
	            isLoading,
	            isSaving,
	            user,
	            error,
	        }, {
	            login: this.login,
	            register: this.register,
	            clearError: this.clearError,
	            logout: this.logout,
	        })
	    );
	}
};

AccountProvider.propTypes = {};

module.exports = AccountProvider;
