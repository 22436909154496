// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { CheckboxInput } from './FormInput';

export class FileRow extends PureComponent {
    static displayName = 'FileRow';

    static propTypes = {
        file: propTypes.shape({
            updated_at: propTypes.string,
            created_at: propTypes.string,
            file_location: propTypes.string,
        }),
        onFileSelected: propTypes.func,
        selected: propTypes.bool,
        onDelete: propTypes.func,
        documentType: propTypes.string,
        permission_type: propTypes.string,
        onUpdate: propTypes.func,
        onDownload: propTypes.func,
    }

    render() {
        const { file: { id, file_location, updated_at, created_at }, onFileSelected, selected, onDelete, documentType, permission_type, onUpdate, onDownload } = this.props;
        let fileName = Utils.parseDocumentAssetFilename(file_location, documentType);
        if (fileName[0] === '/') {
            fileName = fileName.substr(1);
            fileName = fileName.substr(fileName.indexOf('/') + 1);
        }
        const canEdit = permission_type === 'OWNER' || permission_type === 'EDIT';
        return (
            <tr className="table__row">
                {/* Name */}
                <td className="table__cell table__cell--input">
                    <CheckboxInput onChange={e => onFileSelected(id, Utils.safeParseEventValue(e))} checked={selected} />
                </td>
                <td className="table__cell">
                    <p>{fileName}</p>
                </td>
                {/* File type */}
                <td className="table__cell">
                    <p>{Format.fileType(file_location)}</p>
                </td>
                {/* Last updated */}
                <td className="table__cell">{Format.updatedDate(updated_at)}</td>
                {/* Created */}
                <td className="table__cell">{Format.createdDate(created_at)}</td>
                {/* Actions */}
                <td className="table__cell">
                    <Row>
                        {canEdit && (
                            <React.Fragment>
                                <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onDelete(); }}>
                                    <img
                                      src="/images/icons/delete.svg" alt="Delete" className="pr-4"
                                    />
                                </div>
                                <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onUpdate(); }}>
                                    <img src="/images/icons/upload-white.svg" alt="Update" className="icon-shrink pr-4" />
                                </div>
                            </React.Fragment>
                        )}
                        <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onDownload(); }}>
                            <img
                              src="/images/icons/download.svg" alt="Download" className="inactive-feature"
                            />
                        </div>
                    </Row>
                </td>
            </tr>
        );
    }
}

export default class extends PureComponent {
    static displayName = 'FileTable';

    static propTypes = {
        selectAll: propTypes.bool,
        onSelectAll: propTypes.func,
    };

    render() {
        const { props: { children, className, selectAll, onSelectAll, ...rest } } = this;
        return (
            <div className="table-container">
                <div className="table-responsive">
                    <table className={cn('table', className)} {...rest}>
                        <thead className="table__head">
                            <tr>
                                <th className="table__head__cell"><CheckboxInput checked={selectAll} onChange={e => onSelectAll(Utils.safeParseEventValue(e))} /></th>
                                <th className="table__head__cell">File name</th>
                                <th className="table__head__cell">File type</th>
                                <th className="table__head__cell">Last updated</th>
                                <th className="table__head__cell">Created</th>
                                <th className="table__head__cell">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {children}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
