// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import DisplayButton from './DisplayButton';

export default class extends PureComponent {
    static displayName = 'ProfileHero';

    static propTypes = {
        className: propTypes.string,
        user: propTypes.shape({
            id: propTypes.number,
            avatar_url: propTypes.string,
            first_name: propTypes.string,
            last_name: propTypes.string,
            join_date: propTypes.string,
            last_logged_in_at: propTypes.string,
        }),
        documentCount: propTypes.number,
        onEdit: propTypes.func,
    };

    render() {
        const { props: { className, user: { id, avatar_url, first_name, last_name, last_logged_in_at, email }, documentCount, onEdit } } = this;
        const currentUser = AccountStore.getUser().id === id;
        return (
            <div
              className={cn('hero full-width', className)}
            >
                <div className="hero__overlay" />
                <div className="hero__content">
                    <Column className="flex-1 justify-content-center align-items-center p-5">
                        <img alt={`${first_name} ${last_name}`} src={avatar_url || Constants.avatar} className="hero__avatar round mr-3"/>
                        <Row className="justify-content-center mt-3">
                            <h5 className="mb-0 mr-1">
                                {first_name}
                                {' '}
                                {last_name}
                            </h5>
                            {' '}
                            {currentUser ? (
                                <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onEdit(); }}>
                                    <img alt="Edit" src="../images/icons/pencil-white.svg" className="round mr-3"/>
                                </div>
                            ) : null}
                        </Row>
                        <p>{email}</p>
                        <p className="text-small">
                            <span className="muted">Last logged in:</span>
                            {' '}
                            {Format.lastLoggedInAtDate(last_logged_in_at)}
                        </p>
                    </Column>
                    <Row>
                        <Row className="flex-1">
                            <Column>
                                <button type="button" className="btn btn--tab btn--tab--active">Documents</button>
                            </Column>
                            <Column>
                                <button type="button" className="btn btn--tab btn--tab">Groups</button>
                            </Column>
                            <Column>
                                <button type="button" className="btn btn--tab btn--tab">Projects</button>
                            </Column>
                        </Row>
                        <Row className="flex-1 justify-content-end">
                            <Column>
                                <DisplayButton buttonText="Documents" dataValue={documentCount} />
                            </Column>
                            <Column>
                                <DisplayButton buttonText="Groups" dataValue="4" />
                            </Column>
                        </Row>

                    </Row>
                </div>
            </div>
        );
    }
}
