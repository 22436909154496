/**
 * Created by andrewmoffatt on 04/03/2019.
 */

import React, { PureComponent } from 'react';
import cn from 'classnames';

export default class PageController extends PureComponent {
    static displayName = 'PageController';

    static propTypes = {
        pageCount: propTypes.number,
        activePageIndex: propTypes.number,
    };

    render() {
        const { props: { className, pageCount, activePageIndex, ...rest } } = this;
        return (
            <ul className={cn('page-controller list-unstyled list-inline mb-0 mt-3 mt-sm-0', className)} {...rest}>
                {_.map(new Array(pageCount || 2), (undefined, index) => (
                    <li key={index} className={`page-controller__item list-inline-item ${activePageIndex === index ? 'page-controller__item--active' : ''}`} />
                ))}
            </ul>
        );
    }
}
