/**
 * Created by andrewmoffatt on 12/03/2019.
 */
import React from 'react';

import data from '../../../common/stores/base/_data';
import DocumentStore from '../../../common/stores/document-store';
import { TextInput, ColourInput, TextArea, VersionInput, SelectInput, CheckboxInput, SearchableSelectInput } from '../FormInput';
import SwitchRegular from '../Switch';

export default hot(module)(class extends React.Component {
    static displayName = 'ShareDocumentModal';

    static propTypes = {
        links: propTypes.object,
        documentId: propTypes.string,
        passwordProtected: propTypes.bool,
    }

    state = {
        isLoading: false,
        isPublic: this.props.links.public_share_token,
        links: this.props.links,
        password: this.props.passwordProtected ? '12345678' : '',
        passwordProtected: this.props.passwordProtected,
        passwordChanged: false,
    };

    constructor(props) {
        super(props);
        ES6Component(this);
        this.listenTo(DocumentStore, 'loaded', () => {
            const state = { isLoading: false };
            if (!this.state.links.public_share_token && DocumentStore.links.public_share_token) {
                state.isPublic = true;
                state.links = _.cloneDeep(DocumentStore.links);
            } else if (this.state.links.public_share_token && !DocumentStore.links.public_share_token) {
                state.isPublic = false;
                state.links = _.cloneDeep(DocumentStore.links);
            } else if (!this.state.passwordProtected && DocumentStore.model.passwordProtected) {
                state.passwordProtected = true;
                state.links = _.cloneDeep(DocumentStore.links);
            } else if (this.state.passwordProtected && !DocumentStore.model.passwordProtected) {
                state.passwordProtected = false;
                state.links = _.cloneDeep(DocumentStore.links);
            }
            this.setState(state);
        });
    }

    togglePublic = (e) => {
        this.setState({ isLoading: true });
        const isOn = Utils.safeParseEventValue(e);
        if (isOn) {
            // Making this document public
            AppActions.makeDocumentPublic(this.props.documentId);
        } else {
            // Making this document private
            AppActions.makeDocumentPrivate(this.props.documentId);
        }
    }

    copyToClipboard = (text) => {
        Clipboard.setString(text);
        toast('Copied to clipboard');
    }

    togglePasswordProtected = (e) => {
        const isOn = Utils.safeParseEventValue(e);
        const state = { passwordProtected: isOn };
        if (!isOn) {
            state.isLoading = true;
            state.password = '';
            // Making this document public without password
            AppActions.makeDocumentPublic(this.props.documentId);
        }
        this.setState(state);
    }

    savePassword = () => {
        const { password, passwordChanged } = this.state;
        if (!password || !passwordChanged) return;
        this.setState({ isLoading: true });
        AppActions.makeDocumentPublic(this.props.documentId, password);
    }

    onPasswordChange = e => this.setState({ password: Utils.safeParseEventValue(e), passwordChanged: true })

    render = () => {
        const { state: { isLoading, isPublic, links, error, password, passwordProtected, passwordChanged } } = this;
        const origin = Project.env === 'prod' ? 'https://viewer.publicatehouse.com' : document.location.origin;
        const privateLink = `${origin}/document/invite/${this.props.documentId}/${links.private_share_token}`;
        const publicLink = `${origin}/document/viewer/${this.props.documentId}/${links.public_share_token}`;
        return (
            <div className="confirm-content-pane">
                <div>Private Link</div>
                <Flex>
                    <Row>
                        <TextInput className="flex-1" value={privateLink} readOnly />
                        <div className="btn-icon pl-2" role="button" onClick={() => this.copyToClipboard(privateLink)}>
                            <ion-icon
                              style={{ width: 25, height: 25 }} name="md-copy"
                              alt="Copy"
                            />
                        </div>
                    </Row>
                </Flex>
                <Column className="mt-4">
                    {isLoading ? <Loader /> : (
                        <>
                            <SwitchRegular
                              containerName="mb-3 mb-sm-0" onChange={this.togglePublic}
                              checked={isPublic} labelText={isPublic ? 'Make Private' : 'Create Viewer Link'}
                            />
                            <p className="text-smaller muted mt-2">Make this document accessible with a Viewer Link.</p>
                        </>
                    )}
                </Column>
                {!isLoading && isPublic && (
                    <>
                        <div className="mt-4">Viewer Link</div>
                        <Flex>
                            <Row>
                                <TextInput className="flex-1" value={publicLink} readOnly />
                                <div className="btn-icon pl-2" role="button" onClick={() => this.copyToClipboard(publicLink)}>
                                    <ion-icon
                                      style={{ width: 25, height: 25 }} name="md-copy"
                                      alt="Copy"
                                    />
                                </div>
                            </Row>
                        </Flex>
                        <SwitchRegular
                          containerName="mb-3 mb-sm-0 mt-3" onChange={this.togglePasswordProtected}
                          checked={passwordProtected} labelText="Password Protect"
                        />
                        <p className="text-smaller muted mt-2">Protect this public viewer link with a password.</p>
                        {passwordProtected && (
                            <Row>
                                <TextInput
                                  className="flex-1 mt-3" value={password} type="password"
                                  placeholder="Password" onChange={this.onPasswordChange}
                                />
                                {passwordChanged && !!password && (
                                    <button role="button" onClick={this.savePassword} className="btn--primary btn--standard mt-3 ml-2">Save</button>
                                )}
                            </Row>
                        )}
                    </>
                )}
                {error ? (
                    <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                ) : null}
            </div>
        );
    }
});
