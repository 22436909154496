import React, { Component } from 'react';

import BasicButton from '../BasicButton';
import { TextInput } from '../FormInput';

export default hot(module)(class extends Component {
    static displayName = 'EnterPassword';

    static propTypes = {
        onOK: propTypes.func.isRequired,
    }

    state = { password: '' };

    ok = () => {
        this.setState({ error: null });
        this.props.onOK(this.state.password)
            .then(() => closeModal())
            .catch((e) => {
                if (typeof e === 'object' && e.json) {
                    e.json().then(({ error }) => {
                        this.setState({ password: '', error: error || 'Invalid password' });
                        this.passwordInput.focus();
                    }).catch(() => {
                        this.setState({ password: '', error: 'Invalid password' });
                        this.passwordInput.focus();
                    });
                    return;
                }

                this.setState({ password: '', error: 'Invalid password' });
                this.passwordInput.focus();
            });
    }

    cancel = () => {
        window.location.replace('/');
    }

    render = () => {
        const { state: { password, error } } = this;
        return (
            <div className="confirm-content-pane">
                <div className="col-lg-10 m-auto">
                    <div className="text-center">
                        <h2 className="mb-1">Enter Password</h2>
                        <form
                          className="form" onSubmit={(e) => {
                              e.preventDefault();
                              this.ok();
                          }}
                        >
                            <Column className="flex-1 mx-0 mb-4 mt-4">
                                <TextInput
                                  placeholder="Password" type="password" onChange={e => this.setState({ password: Utils.safeParseEventValue(e) })}
                                  className="full"
                                  value={password}
                                  ref={c => this.passwordInput = c}
                                />
                            </Column>
                            <div className="form__footer">
                                {error ? (
                                    <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                                ) : null}
                                <Row className="justify-content-center">
                                    <BasicButton buttonText="Cancel" className="btn--transparent flex-grow-1 flex-sm-grow-0 order-1 order-sm-0" onClick={this.cancel} />
                                    <BasicButton
                                      type="submit"
                                      buttonText="OK" className="flex-grow-1 flex-sm-grow-0" disabled={!password}
                                    />
                                </Row>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
});
