import React from 'react';
import AsyncStorage from 'polyfill-react-native/AsyncStorage';

import data from '../../../common/stores/base/_data';
import { CollectionTile, DocumentTile } from '../ItemTile';
import Table, { CollectionRow, DocumentRow } from '../ItemTable';
import StatCard, { UsersStatCard } from '../StatCard';
import GridToggleButton from '../GridToggleButton';

import CreateModal from '../modals/Create';
import CreateCollectionModal from '../modals/CreateCollection';
import CreateDocumentModal from '../modals/CreateDocument';
import DeleteItemModal from '../modals/DeleteItem';

export default hot(module)(ConfigProvider(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static propTypes = {
      accountId: propTypes.string,
      projectId: propTypes.string,
      collectionId: propTypes.string,
  }

  static displayName = 'ProjectPage';

  state = {
      viewType: window.viewType,
      documentDeleting: {},
  };

  componentWillMount() {
      const documentDeletingId = _.get(this.context.router, 'route.location.state.documentDeletingId');
      if (documentDeletingId) {
          this.state.documentDeleting = { [documentDeletingId]: true };
      }
  }

  componentDidMount() {
      API.trackPage(Constants.pages.PROJECT_PAGE);

      AppActions.getInstanceStats(parseInt(this.props.collectionId) || parseInt(this.props.projectId));
  }

  componentDidUpdate(prevProps) {
      if (prevProps.collectionId !== this.props.collectionId || prevProps.projectId !== this.props.projectId) {
          AppActions.getInstanceStats(parseInt(this.props.collectionId) || parseInt(this.props.projectId));
      }
  }

  onCollectionClicked(collectionId) {
      const { pathname } = this.context.router.route.location;
      if (this.props.collectionId) {
          this.context.router.history.push(`${pathname.substr(0, pathname.lastIndexOf('/'))}/${collectionId}`);
      } else {
          this.context.router.history.push(`${pathname}/${collectionId}`);
      }
  }

  onDocumentClicked(documentId) {
      const { pathname } = this.context.router.route.location;
      if (ConfigStore.presentationModeEnabled()) {
          data.get(`${Project.api}documents/${documentId}`)
              .then((res) => {
                  if (!res.viewer_url) {
                      toast('Sorry unable to preview this document');
                      return;
                  }
                  this.context.router.history.push(`${pathname}/document/${documentId}/preview`, { documentType: res.type, url: res.viewer_url });
              })
              .catch((e) => {
                  toast('Sorry something went wrong previewing the document');
              });
          return;
      }
      this.context.router.history.push(`${pathname}/document/${documentId}`);
  }

  onUsersStatCardClicked = () => {
      const { pathname } = this.context.router.route.location;
      this.context.router.history.push(`${pathname}/members`);
  }

  onViewTypeChanged = () => {
      window.viewType = window.viewType === 'table' ? 'tile' : 'table';
      AsyncStorage.setItem('viewType', window.viewType);
      this.setState({ viewType: window.viewType });
  }

  onError = (error) => {
      this.context.router.history.replace('/dashboard');
      toast(error);
  }

  onCollectionNotFound = () => {
      this.context.router.history.replace('/dashboard');
      toast(Constants.errors.NO_COLLECTION);
  }

  create = (data) => {
      openModal({
          body: <CreateModal accountId={Utils.getAccountId(this.props)} projectData={data} />,
          className: 'modal--fullscreen',
          disableDismissOnBackdrop: true,
      });
  }

  createCollection = () => {
      const { props: { projectId, collectionId } } = this;
      openModal({ body: <CreateCollectionModal accountId={Utils.getAccountId(this.props)} projectId={parseInt(projectId)} collectionId={collectionId ? parseInt(collectionId) : null} />, className: 'modal--fullscreen', disableDismissOnBackdrop: true });
  }

  createDocument = () => {
      const { props: { projectId, collectionId } } = this;
      openModal({ body: <CreateDocumentModal accountId={Utils.getAccountId(this.props)} projectId={parseInt(projectId)} collectionId={collectionId ? parseInt(collectionId) : null} />, className: 'modal--fullscreen', disableDismissOnBackdrop: true });
  }

  onEditCollection = (collection) => {
      const { props: { projectId, collectionId } } = this;
      openModal({ body: (
          <CreateCollectionModal
            edit accountId={Utils.getAccountId(this.props)} projectId={parseInt(projectId)}
            collectionId={collectionId} collection={collection}
            onDelete={() => {
                closeModal();
                setTimeout(() => this.deleteCollection(collection), 501);
            }}
          />
      ),
      className: 'modal--fullscreen',
      disableDismissOnBackdrop: true });
  }

  onEditDocument = (document) => {
      const { props: { projectId, collectionId } } = this;
      openModal({ body: (
          <CreateDocumentModal
            accountId={Utils.getAccountId(this.props)} projectId={parseInt(projectId)} collectionId={parseInt(collectionId)}
            edit document={document}
            onDelete={() => {
                closeModal();
                setTimeout(() => this.deleteDocument(document), 501);
            }}
          />
      ),
      className: 'modal--fullscreen',
      disableDismissOnBackdrop: true });
  }

  deleteDocument = (document) => {
      openModal({
          body: <DeleteItemModal name={document.name} type="document" onConfirm={() => AppActions.deleteDocument(document.id)} />,
          className: 'modal--default',
      });
  }

  deleteCollection = (collection) => {
      openModal({
          body: <DeleteItemModal name={collection.name} type="collection" onConfirm={() => AppActions.deleteCollection(collection.id)} />,
          className: 'modal--default',
      });
  }

  onDocumentDeleted(id) {
      if (this.state.documentDeleting[id]) {
          this.setState({ documentDeleting: { ...this.state.documentDeleting, [id]: false } });
      }
  }

  render = () => {
      const { viewType } = this.state;
      const { projectId, collectionId, presentationMode } = this.props;
      return (
          <div className="content-pane">
              <AccountProvider>
                  {({ isLoading, user }) => (
                      <div>
                          {isLoading || !user || !projectId ? <Loader /> : (
                              <div>
                                  <ProjectProvider
                                    onCollectionNotFound={this.onCollectionNotFound}
                                    onError={this.onError}
                                    projectId={parseInt(projectId)}
                                    id={parseInt(collectionId) || parseInt(projectId)}
                                    onDocumentDeleting={id => this.setState({ documentDeleting: { ...this.state.documentDeleting, [id]: true } })}
                                    onDocumentDeleted={id => this.onDocumentDeleted(id)}
                                  >
                                      {({ isLoading: projectLoading, error, data, stats }) => {
                                          if (error) return <p>Error retrieving project</p>;
                                          if ((projectLoading && !data) || !data) return <Loader />;
                                          const { collections, documents, breadcrumb } = data;
                                          return (
                                              <React.Fragment>
                                                  {!presentationMode ? (
                                                      <button type="button" className="btn--action round" onClick={() => this.create(data)}>
                                                          <img src="/images/icons/create-white.svg" alt="Add" />
                                                      </button>
                                                  ) : null}

                                                  <div className="col-md-12">
                                                      <div className="row justify-content-end pb-4">
                                                          <GridToggleButton
                                                            onClick={this.onViewTypeChanged}
                                                            enabled={window.viewType === 'table'}
                                                          />
                                                      </div>
                                                  </div>
                                                  {!presentationMode ? (
                                                      <div className="row">
                                                          {stats && stats.num_documents != null ? (
                                                              <div className="col-md-3 mb-3 mb-sm-5">
                                                                  <StatCard
                                                                    title="Documents"
                                                                    content={stats.num_documents}
                                                                    footer={stats.num_new_documents != null ? `${stats.num_new_documents} New documents created this month` : ''}
                                                                    onClick={() => this.context.router.history.push(`${this.context.router.route.location.pathname}/documents`)}
                                                                  />
                                                              </div>
                                                          ) : null}
                                                          {stats && stats.num_collections != null ? (
                                                              <div className="col-md-3 mb-3 mb-sm-5">
                                                                  <StatCard
                                                                    title="Collections"
                                                                    content={stats.num_collections}
                                                                    footer={stats.num_new_collections != null ? `${stats.num_new_collections} New collections created this month` : ''}
                                                                    onClick={() => this.context.router.history.push(`${this.context.router.route.location.pathname}/collections`)}
                                                                  />
                                                              </div>
                                                          ) : null}
                                                          {stats && stats.members != null ? (
                                                              <div className="col-md-3 mb-3 mb-sm-5">
                                                                  <UsersStatCard
                                                                    title="Members"
                                                                    users={stats.members}
                                                                    footer={`${stats.num_new_members || 0} new members joined ${breadcrumb && breadcrumb[0].name} this month`}
                                                                    onClick={_.last(breadcrumb).permission_type === 'OWNER' ? this.onUsersStatCardClicked : null}
                                                                  />
                                                              </div>
                                                          ) : null}
                                                          {stats && stats.size != null ? (() => {
                                                              const accountName = Utils.getAccountName(this.props);
                                                              return (
                                                                  <div className="col-md-3 mb-3 mb-sm-5">
                                                                      <StatCard
                                                                        title="Usage"
                                                                        content={filesize(stats.size)}
                                                                        footer={`${accountName} has 7.08GB storage space`}
                                                                      />
                                                                  </div>
                                                              );
                                                          })() : null}
                                                      </div>
                                                  ) : null}

                                                  <div className="row mb-5">
                                                      {viewType === 'table' ? (
                                                          <div className="col-md-12">
                                                              <Table>
                                                                  {_.map(collections, collection => (
                                                                      <CollectionRow
                                                                        key={collection.key} collection={collection} onClick={() => this.onCollectionClicked(collection.id)}
                                                                        onDelete={() => this.deleteCollection(collection)}
                                                                        permissionType={collection.permission_type}
                                                                      />
                                                                  ))}
                                                                  {_.map(documents, document => (
                                                                      <DocumentRow
                                                                        key={document.key} document={document} onClick={() => this.onDocumentClicked(document.id)}
                                                                        onDelete={() => this.deleteDocument(document)} isDeleting={this.state.documentDeleting[document.id]}
                                                                        permissionType={document.permission_type}
                                                                      />
                                                                  ))}
                                                              </Table>
                                                          </div>
                                                      ) : _.map(collections, collection => (
                                                          <div key={collection.key} className="col-md-3 mb-3 mb-sm-5">
                                                              <CollectionTile collection={collection} onClick={() => this.onCollectionClicked(collection.id)} onEdit={() => this.onEditCollection(collection)} />
                                                          </div>
                                                      )).concat(_.map(documents, document => (
                                                          <div key={document.key} className="col-md-3 mb-3 mb-sm-5">
                                                              <DocumentTile
                                                                document={document} onClick={() => this.onDocumentClicked(document.id)} onEdit={() => this.onEditDocument(document)}
                                                                isDeleting={this.state.documentDeleting[document.id]}
                                                              />
                                                          </div>
                                                      )))}
                                                  </div>
                                              </React.Fragment>
                                          );
                                      }}
                                  </ProjectProvider>
                              </div>
                          )}
                      </div>
                  )}
              </AccountProvider>
          </div>
      );
  }
}));
