import propTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class TheComponent extends PureComponent {
  static displayName = 'TheComponent';

  static propTypes = {
      enabled: propTypes.bool,
      onClick: propTypes.func,
  };

  render() {
      const { props: { enabled, onClick } } = this;
      return (
          <span onClick={onClick} className="grid-toggle-button" role="button">
              {enabled ? (
                  <img src="/images/icons/tile-view.svg" alt="Tile view" className="grid-toggle-button__image"/>
              ) : (
                  <img src="/images/icons/table-view.svg" alt="List view" />
              )}
          </span>
      );
  }
}
