import React, { Component, PropTypes } from 'react';
import ConfigStore from '../stores/config-store';

module.exports = (WrappedComponent) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isLoading: ConfigStore.isLoading,
                presentationMode: ConfigStore.presentationMode,
            };
            ES6Component(this);
        }

        componentDidMount() {
            this.listenTo(ConfigStore, 'change', () => {
                if (this.state.presentationMode !== ConfigStore.presentationMode && this.wrappedComponent && this.wrappedComponent.onPresentationMode) {
                    this.wrappedComponent.onPresentationMode(ConfigStore.presentationMode);
                }
                this.setState({
                    isLoading: ConfigStore.isLoading,
                    presentationMode: ConfigStore.presentationMode,
                });
            });
            this.listenTo(ConfigStore, 'loaded', () => {
                if (this.wrappedComponent.onConfigLoaded) this.wrappedComponent.onConfigLoaded();
            });
        }

        render() {
            const { isLoading, presentationMode } = this.state;
            const { getValue, hasFeature } = bulletTrain;

            return (
                <WrappedComponent
                  ref={c => this.wrappedComponent = c}
                  isLoading={isLoading}
                  getValue={getValue}
                  hasFeature={hasFeature}
                  presentationMode={presentationMode}
                  {...this.props}
                />
            );
        }
    }

    return HOC;
};
