import React, { PureComponent } from 'react';

import BasicButton from '../BasicButton';
import { TextInput } from '../FormInput';

export default hot(module)(class extends PureComponent {
    static displayName = 'DeleteItem';

    static propTypes = {
        name: propTypes.string.isRequired,
        type: propTypes.string.isRequired,
        onConfirm: propTypes.func.isRequired,
    }

    state = { confirmation: '' };

    onConfirm = () => {
        this.props.onConfirm();
        closeModal();
    }

    render = () => {
        const { props: { name, type } } = this;
        return (
            <div className="confirm-content-pane">
                <div className="col-lg-10 m-auto">
                    <div className="text-center">
                        <h2 className="mb-1">{`Confirm ${_.upperFirst(type)} Deletion`}</h2>
                        <p className="mb-2">{`Are you sure you want to delete ${type} ${name}?`}</p>
                        <form
                          className="form" onSubmit={(e) => {
                              e.preventDefault();
                              this.onConfirm();
                          }}
                        >
                            <Column className="flex-1 mx-0 mb-4">
                                <label>Please enter DELETE to proceed</label>
                                <TextInput
                                  placeholder="Confirm Deletion..." onChange={e => this.setState({ confirmation: Utils.safeParseEventValue(e) })} className="full"
                                  value={this.state.confirmation}
                                />
                            </Column>
                            <Row className="justify-content-center">
                                <BasicButton buttonText="Cancel" className="btn--transparent flex-grow-1 flex-sm-grow-0 order-1 order-sm-0" onClick={() => closeModal()} />
                                <BasicButton
                                  type="submit"
                                  buttonText="Confirm" className="flex-grow-1 flex-sm-grow-0" disabled={this.state.confirmation !== 'DELETE'}
                                />
                            </Row>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
});
