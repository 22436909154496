import React from 'react';

import BasicButton from '../BasicButton';

export default hot(module)(class extends React.Component {
    static displayName = 'ConfirmationModal';

    static propTypes = {
        modalTitle: propTypes.string,
        modalText: propTypes.string,
    }

    render = () => {
        const { props: { modalTitle, modalText, ...rest } } = this;
        return (
            <div className="confirm-content-pane">
                <div className="col-lg-10 m-auto">
                    <div className="text-center">
                        <h2 className="mb-1">{modalTitle}</h2>
                        <p className="text-small">{modalText}</p>
                    </div>
                </div>
            </div>
        );
    }
});
