// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';

export const DocumentTile = ConfigProvider(class extends PureComponent {
    static displayName = 'DocumentTile';

    static propTypes = {
        document: propTypes.shape({
            name: propTypes.string,
            cover_image_url: propTypes.string,
            document_type: propTypes.string,
        }),
        onClick: propTypes.func,
        onEdit: propTypes.func,
        presentationMode: propTypes.bool,
        isDeleting: propTypes.bool,
    };

    render() {
        const {
            props: {
                document: {
                    name = 'Document',
                    cover_image_url,
                    document_type,
                },
                onClick,
                onEdit,
                presentationMode,
                isDeleting,
            },
        } = this;

        return (
            <div
              className="tile tile--document"
              role="button"
              onClick={isDeleting ? null : onClick}
            >
                {cover_image_url ? (
                    <div
                      className="tile__background" style={{
                          backgroundImage: `url('${cover_image_url}')`,
                      }}
                    />
                ) : null}
                {!presentationMode ? (
                    <div className="btn-icon" role="button" onClick={isDeleting ? null : (e) => { e.stopPropagation(); onEdit(); }}>
                        <img className="tile__button" src="/images/icons/cog-dark.svg" alt="Edit" />
                    </div>) : null}
                {!cover_image_url ? (
                    <div style={{ flex: 1 }}>
                        <span className="tile__text-image unselectable">{name[0].toUpperCase()}</span>
                        <div className="text-center">
                            <p className="text-small text-dark">Upload cover image</p>
                            <img src="/images/icons/upload-half.svg" alt="Upload"/>
                        </div>
                    </div>
                ) : null}
                <div className="tile__footer">
                    <p className="tile__title truncate">{name}</p>
                    <p className="tile__author text-small">{`${Format.documentType(document_type)} Document`}</p>
                </div>
                {!!isDeleting && (
                    <div className="tile__loader">
                        <Loader />
                    </div>
                )}
            </div>
        );
    }
});

export const CollectionTile = ConfigProvider(class extends PureComponent {
    static displayName = 'CollectionTile';

    static propTypes = {
        collection: propTypes.shape({
            name: propTypes.string,
        }),
        onClick: propTypes.func,
        onEdit: propTypes.func,
    };

    render() {
        const {
            props: {
                collection: {
                    name = 'Company',
                },
                onClick,
                onEdit,
                presentationMode,
            },
        } = this;

        return (
            <div
              className="tile tile--collection"
              role="button"
              onClick={onClick}
            >
                {!presentationMode ? (
                    <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onEdit(); }}>
                        <img className="tile__button" src="/images/icons/cog-dark.svg" alt="Edit" />
                    </div>) : null}
                <div style={{ flex: 1 }}><img className="tile__icon" src="/images/icons/folder-white.svg" alt="Collection"/></div>

                <div className="tile__footer">
                    <p className="tile__title truncate">{name}</p>
                    <p className="tile__author text-small">Collection</p>
                </div>
            </div>
        );
    }
});


export const ProjectTile = ConfigProvider(class extends PureComponent {
    static displayName = 'ProjectTile';

    static propTypes = {
        project: propTypes.shape({
            cover_image_url: propTypes.string,
            name: propTypes.string,
            created_by: propTypes.shape({
                first_name: propTypes.string,
                last_name: propTypes.string,
            }),
        }),
        onClick: propTypes.func,
        onEdit: propTypes.func,
    };

    render() {
        const {
            props: {
                project: {
                    cover_image_url,
                    name = 'Company',
                    created_by,
                },
                onClick,
                onEdit,
                presentationMode,
            },
        } = this;

        const { first_name = '', last_name = '' } = created_by || {};

        return (
            <div
              className="tile tile--project"
              role="button"
              onClick={onClick}
            >
                {cover_image_url ? (
                    <div
                      className="tile__background" style={{
                          backgroundImage: `url('${cover_image_url}')`,
                      }}
                    />
                ) : null}
                {!presentationMode ? (
                    <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onEdit(); }}>
                        <img className="tile__button" src="/images/icons/cog-dark.svg" alt="Edit" />
                    </div>) : null}
                {!cover_image_url ? (
                    <div style={{ flex: 1 }}>
                        <span className="tile__text-image unselectable">{name[0].toUpperCase()}</span>
                        <div className="text-center">
                            <p className="text-small text-dark">Upload cover image</p>
                            <img src="/images/icons/upload-half.svg" alt="Upload"/>
                        </div>
                    </div>
                ) : null}

                <div className="tile__footer">
                    <p className="tile__title truncate">{name}</p>
                    <p className="tile__author text-small">{`${first_name} ${last_name}`}</p>
                </div>
            </div>
        );
    }
});

export const CompanyTile = ConfigProvider(class extends PureComponent {
    static displayName = 'CompanyTile';

    static propTypes = {
        account: propTypes.shape({
            logo_url: propTypes.string,
            name: propTypes.string,
        }),
        onClick: propTypes.func,
        onEdit: propTypes.func,
    };

    render() {
        const {
            props: {
                account: {
                    logo_url,
                    name = 'WATG',
                },
                onClick,
                onEdit,
                presentationMode,
            },
        } = this;

        return (
            <div className="tile tile--company" role="button" onClick={onClick}>
                {logo_url ? (
                    <div
                      className="tile__background" style={{
                          backgroundImage: `url('${logo_url}')`,
                      }}
                    />
                ) : null}
                {!presentationMode ? (
                    <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onEdit(); }}>
                        <img className="tile__button" src="/images/icons/cog-dark.svg" alt="Edit" />
                    </div>) : null}
                {!logo_url ? (
                    <div style={{ flex: 1 }}>
                        <span className="tile__text-image unselectable">{name[0].toUpperCase()}</span>
                        <div className="text-center">
                            <p className="text-small text-dark">Upload cover image</p>
                            <img src="/images/icons/upload-half.svg" alt="Upload"/>
                        </div>
                    </div>
                ) : null}
                <div className="tile__footer">
                    <p className="tile__title truncate">{name}</p>
                </div>
            </div>
        );
    }
});
