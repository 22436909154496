// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export class CreateButton extends PureComponent {
  static displayName = 'CreateButton';

  static propTypes = {};

  render() {
      const { props: { className, ...rest } } = this;
      return (
          <button
            type="button" className={cn('btn--action round', className)}
            {...rest}
          >
              <img src="/images/icons/create-white.svg" alt="Create"/>
          </button>
      );
  }
}

export class UploadButton extends PureComponent {
  static displayName = 'UploadButton';

  static propTypes = {};

  render() {
      const { props: { className, ...rest } } = this;
      return (
          <button
            type="button" className={cn('btn--action round', className)}
            {...rest}
          >
              <img src="/images/icons/upload-white.svg" alt="Upload"/>
          </button>
      );
  }
}
