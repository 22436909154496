// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import Select from 'react-select';

export class CompanySelect extends PureComponent {
    static displayName = 'CompanySelect';

    static propTypes = {
        active: propTypes.bool,
        onClick: propTypes.func,
        className: propTypes.string,
        panelText: propTypes.string,
        panelTitle: propTypes.string,
    };

    render() {
        const { props: { className, panelText, panelTitle, active, onClick, ...rest } } = this;
        return (
            <div role="button" onClick={onClick}>
                <Column
                  className={cn('panel-select rounded', className, { ' panel-select--active': active })}
                  {...rest}
                >
                    <img src="/images/icons/create-company.svg" alt="Company"/>
                    <h5 className="pt-4">{panelTitle}</h5>
                    <p className="text-small">{panelText}</p>
                </Column>
            </div>
        );
    }
}

export class ProjectSelect extends PureComponent {
    static displayName = 'ProjectSelect';

    static propTypes = {
        active: propTypes.bool,
        onClick: propTypes.func,
        className: propTypes.string,
        panelText: propTypes.string,
        panelTitle: propTypes.string,
    };

    render() {
        const { props: { className, panelText, panelTitle, active, onClick, ...rest } } = this;
        return (
            <div role="button" onClick={onClick}>
                <Column
                  className={cn('panel-select rounded', className, { 'mb-4 panel-select--active': active })}
                  {...rest}
                >
                    <img src="/images/icons/create-project.svg" alt="project"/>
                    <h5 className="pt-4">{panelTitle}</h5>
                    <p className="text-small">{panelText}</p>
                </Column>
            </div>
        );
    }
}

export class CollectionSelect extends PureComponent {
    static displayName = 'CollectionSelect';

    static propTypes = {
        active: propTypes.bool,
        onClick: propTypes.func,
        className: propTypes.string,
        panelText: propTypes.string,
        panelTitle: propTypes.string,
        defaultValue: propTypes.object,
        onChange: propTypes.func,
    };

    render() {
        const { props: { children, className, panelText, panelTitle, active, onClick, defaultValue, onChange, ...rest } } = this;
        return (
            <div className={cn('panel-container mb-sm-0', { 'my-4 mb-sm-0 panel-container--active': active })} role="button" onClick={onClick}>
                <Column
                  className={cn('panel-select rounded', className, { ' panel-select--active': active })}
                  {...rest}
                >
                    <img src="/images/icons/create-collection.svg" alt="collection"/>
                    <h5 className="pt-4">{panelTitle}</h5>
                    <p className="text-small">{panelText}</p>
                </Column>
                <label className="panel-container__label text-left text-small muted mt-2 mt-sm-5">Destination</label>
                <Select
                  className="panel-container__select input full"
                  styles={Constants.reactSelectStyles}
                  options={children}
                  defaultValue={defaultValue}
                  onChange={selectedOption => onChange(selectedOption.value)}
                />
            </div>
        );
    }
}

export class DocumentSelect extends PureComponent {
    static displayName = 'DocumentSelect';

    static propTypes = {
        active: propTypes.bool,
        onClick: propTypes.func,
        className: propTypes.string,
        panelText: propTypes.string,
        panelTitle: propTypes.string,
        defaultValue: propTypes.object,
    };

    render() {
        const { props: { children, className, panelText, panelTitle, active, onClick, defaultValue, onChange, ...rest } } = this;
        return (
            <div className={cn('panel-container mb-sm-0', { ' my-4 mb-sm-0 panel-container--active': active })} role="button" onClick={onClick}>
                <Column
                  className={cn('panel-select rounded', className, { ' panel-select--active': active })}
                  {...rest}
                >
                    <img src="/images/icons/create-document.svg" alt="document"/>
                    <h5 className="pt-4">{panelTitle}</h5>
                    <p className="text-small">{panelText}</p>
                </Column>
                <label className="panel-container__label text-left text-small muted mt-2 mt-sm-5">Destination</label>
                <Select
                  className="panel-container__select input full"
                  styles={Constants.reactSelectStyles}
                  options={children}
                  defaultValue={defaultValue}
                  onChange={selectedOption => onChange(selectedOption.value)}
                />
            </div>
        );
    }
}
