import { Component } from 'react';
import UsersStore from '../stores/users-store';

const TheComponent = class extends Component {
    static displayName = 'UsersProvider';

    static propTypes = {
        onError: propTypes.func,
        onUsersNotFound: propTypes.func,
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoadingInitial: true,
            isLoading: !UsersStore.model,
            isSaving: UsersStore.isSaving,
            error: UsersStore.error,
            users: UsersStore.getUsers(),
        };
    }

    componentDidMount() { // Get Projects on mount, listen to store changing
        ES6Component(this);
        if (!UsersStore.model) {
            AppActions.getUsers();
        }
        this.listenTo(UsersStore, 'change', () => {
            this.setState({
                isLoadingInitial: UsersStore.isLoading,
                isLoading: UsersStore.isLoading,
                isSaving: UsersStore.isSaving,
                error: UsersStore.error,
                isRefreshing: UsersStore.isLoading && this.state.isRefreshing,
                users: UsersStore.getUsers(),
            });
        });
        this.listenTo(UsersStore, 'problem', () => {
            if (this.props.onError) {
                this.props.onError(UsersStore.error);
            } else {
                this.setState({
                    error: UsersStore.error,
                });
            }
        });
        this.listenTo(UsersStore, 'loaded', () => {
            if (!UsersStore.model && this.props.onUsersNotFound) {
                this.props.onUsersNotFound();
            }
        });
    }

    sort = (type) => { // Sort users according to user sort constants
        switch (type) {
            case 'A-Z': {

            }
        }
        this.setState({
            users: users && _.sortBy(users, () => {

            }),
        });
    };

    refresh = () => {
        this.setState({ isRefreshing: true }, () => {
            AppActions.getUsers(true);
        });
    };

    render() { // Render with project store state and sort function
        const { isLoading, isSaving, users, error, isRefreshing } = this.state;
        const { sort, refresh } = this;
        return (
            this.props.children({
                isLoading,
                isSaving,
                error,
                isRefreshing,
                users,
            }, { sort, refresh })
        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
