import moment from 'moment';

const BaseStore = require('./base/_store');
const data = require('./base/_data');
const endpoints = require('./endpoints');


const store = Object.assign({}, BaseStore, {
    id: 'projects',
    getProjects() {
        return bulletTrain.hasFeature('no_projects') ? [] : store.model;
    },
    setUser(user) {
        controller.setUser(user);
    },
    getProject(id) {
        return _.find(store.model, { id });
    },
    getStats() {
        return store.stats;
    },
});

const controller = {

    getProjects(accountId) {
        store.loading();
        if (accountId !== store.accountId) {
            store.model = [];
        }
        store.accountId = accountId;
        endpoints
            .getProjects(accountId)
            .then(controller.loaded)
            .catch((error) => {
                API.handleError(store, error);
            });
    },
    loaded(res) {
        res = res && res.map((res) => {
            return Object.assign(res, { key: `${res.id}`, updated_at_formatted: Format.updatedDate(res.updated_at) });
        });

        res = res && _.sortBy(res, 'created_at');

        if (bulletTrain.getValue('data_multiplier')) {
            let newRes = res.concat([]);
            _.map(_.times(bulletTrain.getValue('data_multiplier') - 1), (i) => {
                newRes = newRes.concat(res.map((item) => {
                    return Object.assign({}, item, {
                        id: `${item.id}${i}`,
                        key: item.key + i,
                        name: `${item.name} Copy ${i}`,
                    });
                }));
            });
            res = newRes;
        }

        if (bulletTrain.hasFeature('one_project')) {
            res = [res[0]];
        }


        AsyncStorage.setItem('projects', JSON.stringify(res));
        store.model = res;
        store.loaded();
    },
    uploadCoverImage: (coverImage) => {
        if (!coverImage) {
            return Promise.resolve();
        }
        return endpoints.upload(coverImage, 'INSTANCE')
            .then(({ url }) => {
                return Promise.resolve(url);
            });
    },
    createProject(details) {
        store.saving();
        controller.uploadCoverImage(details.coverImage)
            .then(cover_image_url => endpoints.createInstance(store.accountId, { ...details, cover_image_url }))
            .then((res) => {
                store.model.push(res);
                store.savedId = res.id;
                AsyncStorage.setItem('projects', JSON.stringify(store.model));
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    editProject(id, details) {
        store.saving();
        controller.uploadCoverImage(details.coverImage)
            .then(cover_image_url => endpoints.editInstance(id, { ...details, cover_image_url: cover_image_url || details.cover_image_url }))
            .then((res) => {
                const index = _.findIndex(store.model, { id });
                if (index !== -1) {
                    store.model[index] = res;
                }
                store.savedId = res.id;
                AsyncStorage.setItem('projects', JSON.stringify(store.model));
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    deleteProject(id) {
        store.saving();
        endpoints.deleteInstance(id)
            .then((res) => {
                const index = _.findIndex(store.model, { id });
                if (index !== -1) {
                    store.model.splice(index, 1);
                }
                store.savedId = res.id;
                AsyncStorage.setItem('projects', JSON.stringify(store.model));
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    getStats(accountId) {
        store.loading();
        endpoints.getAccountStats(accountId)
            .then((res) => {
                store.stats = res;
                store.loaded();
            })
            .catch(error => API.handleError(store, error));
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_PROJECTS:
            controller.getProjects(action.accountId, action.force);
            break;
        case Actions.ON_LOGOUT:
            store.model = null;
            store.error = null;
            break;
        case Actions.CREATE_PROJECT:
            controller.createProject(action.details);
            break;
        case Actions.EDIT_PROJECT:
            controller.editProject(action.id, action.details);
            break;
        case Actions.DELETE_PROJECT:
            controller.deleteProject(action.id);
            break;
        case Actions.GET_ACCOUNT_STATS:
            controller.getStats(action.id);
            break;
        default:
    }
});

AsyncStorage.getItem('projects', (err, res) => {
    if (res) {
        store.model = JSON.parse(res);
    }
});


controller.store = store;
module.exports = controller.store;
