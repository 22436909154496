// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { SelectInput } from './FormInput';

export class DocumentRow extends PureComponent {
  static displayName = 'DocumentRow';

  static propTypes = {
      document: propTypes.shape({
          users: propTypes.array,
          name: propTypes.string,
          updated_at: propTypes.string,
          created_at: propTypes.string,
      }),
      permissionType: propTypes.string,
      created_by: propTypes.shape({
          first_name: propTypes.string,
          last_name: propTypes.string,
      }),
      onClick: propTypes.func,
      onDelete: propTypes.func,
      isDeleting: propTypes.bool,
  };

  render() {
      const { props: {
          document: {
              created_at,
              updated_at,
              name = 'Untitled',
              user_count,
          },
          permissionType,
          onClick,
          onDelete,
          isDeleting,
      } } = this;
      const canDelete = permissionType === 'OWNER';
      return (
          <tr onClick={isDeleting ? null : onClick} className={`table__row clickable ${isDeleting ? 'table__row--inactive' : ''}`}>
              {/* Name */}
              <td className="table__cell">{name}</td>
              {/* Members */}
              {/* <td className="table__cell">{user_count}</td> */}
              {/* Type */}
              <td className="table__cell">Document</td>
              {/* Permissions */}
              <td className="table__cell">{Format.permissionType(permissionType)}</td>
              {/* Last Updated */}
              <td className="table__cell">{Format.updatedDate(updated_at)}</td>
              {/* Created */}
              <td className="table__cell">{Format.createdDate(created_at)}</td>
              {/* Actions */}
              <td className="table__cell">
                  {!isDeleting && (
                  <Row>
                      {canDelete && (
                      <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onDelete(); }}>
                          <img
                            src="/images/icons/delete.svg" alt="Delete" className="pr-4"
                          />
                      </div>
                      )}
                      <img src="/images/icons/download.svg" alt="Download" className="" />
                  </Row>
                  )}
              </td>
          </tr>
      );
  }
}

export class CollectionRow extends PureComponent {
  static displayName = 'CollectionRow';

  static propTypes = {
      collection: propTypes.shape({
          users: propTypes.array,
          name: propTypes.string,
          updated_at: propTypes.string,
          created_at: propTypes.string,
      }),
      permissionType: propTypes.string,
      onClick: propTypes.func,
      onDelete: propTypes.func,
  };

  render() {
      const { props: {
          collection: {
              users = [],
              created_at,
              updated_at,
              name = 'Company',
          },
          permissionType,
          onClick,
          onDelete,
      } } = this;
      const canDelete = permissionType === 'OWNER';
      return (
          <tr onClick={onClick} className="table__row clickable">
              {/* Name */}
              <td className="table__cell">{name}</td>
              {/* Members */}
              {/* <td className="table__cell">{users.length}</td> */}
              {/* Type */}
              <td className="table__cell">Collection</td>
              {/* Permissions */}
              <td className="table__cell">{Format.permissionType(permissionType)}</td>
              {/* Last Updated */}
              <td className="table__cell">{Format.updatedDate(updated_at)}</td>
              {/* Created */}
              <td className="table__cell">{Format.createdDate(created_at)}</td>
              {/* Actions */}
              <td className="table__cell">
                  {canDelete ? (
                      <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onDelete(); }}>
                          <img src="/images/icons/delete.svg" alt="Delete" className=""/>
                      </div>
                  ) : null}
              </td>
          </tr>
      );
  }
}


export class ProjectRow extends PureComponent {
  static displayName = 'ProjectRow';

  static propTypes = {
      project: propTypes.shape({
          users: propTypes.array,
          name: propTypes.string,
          updated_at: propTypes.string,
          created_at: propTypes.string,
      }),
      permissionType: propTypes.string,
      onClick: propTypes.func,
      onDelete: propTypes.func,
  };

  render() {
      const { props: {
          project: {
              users = [],
              created_at,
              updated_at,
              name = 'Company',
          },
          permissionType,
          onClick,
          onDelete,
      } } = this;
      const canDelete = permissionType === 'OWNER';
      return (
          <tr onClick={onClick} className="table__row clickable">
              {/* Name */}
              <td className="table__cell">{name}</td>
              {/* Members */}
              {/* <td className="table__cell">{users.length}</td> */}
              {/* Type */}
              <td className="table__cell">Project</td>
              {/* Permissions */}
              <td className="table__cell">{Format.permissionType(permissionType)}</td>
              {/* Last Updated */}
              <td className="table__cell">{Format.updatedDate(updated_at)}</td>
              {/* Created */}
              <td className="table__cell">{Format.createdDate(created_at)}</td>
              {/* Actions */}
              <td className="table__cell">
                  <Row>
                      {canDelete && (
                          <div className="btn-icon" role="button" onClick={(e) => { e.stopPropagation(); onDelete(); }}>
                              <img
                                src="/images/icons/delete.svg" alt="Delete" className="pr-4"
                              />
                          </div>
                      )}
                  </Row>
              </td>
          </tr>
      );
  }
}

export default class extends PureComponent {
  static displayName = 'TableRow';

  static propTypes = {};

  render() {
      const { props: { children, className, ...rest } } = this;
      return (
          <div className="table-container">
              <div className="table-responsive">
                  <table
                    className={cn('table', className)} {...rest}
                  >
                      <thead className="table__head">
                          <tr>
                              <th className="table__head__cell">Name</th>
                              {/* <th className="table__head__cell">Members</th> */}
                              <th className="table__head__cell">Type</th>
                              <th className="table__head__cell">Permissions</th>
                              <th className="table__head__cell">Last Updated</th>
                              <th className="table__head__cell">Created</th>
                              <th className="table__head__cell">Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                          {children}
                      </tbody>
                  </table>
              </div>
          </div>
      );
  }
}
