/**
 * Created by kyle-ssg on 10/02/15.
 */
module.exports = {
    'ACTIVE': 'ACTIVE',
    'INACTIVE': 'INACTIVE',
    'CONNECTED': 'CONNECTED',
    'DISCONNECTED': 'DISCONNECTED',
    'GET_CONFIG': 'GET_CONFIG',
    'GET_ACCOUNTS': 'GET_ACCOUNTS',
    'DOWNLOAD_DOCUMENT': 'DOWNLOAD_DOCUMENT',
    'UPDATE_DOCUMENT_DOWNLOAD': 'UPDATE_DOCUMENT_DOWNLOAD',
    'CANCEL_DOCUMENT_DOWNLOAD': 'CANCEL_DOCUMENT_DOWNLOAD',
    'SELECTED_PROJECT': 'SELECTED_PROJECT',
    'SET_THEME': 'SET_THEME',
    'DELETE_DOCUMENT_DOWNLOAD': 'DELETE_DOCUMENT_DOWNLOAD',
    'REGISTER_NOTIFICATIONS': 'REGISTER_NOTIFICATIONS',
    'LOGIN': 'LOGIN',
    'LOGOUT': 'LOGOUT',
    'REGISTER': 'REGISTER',
    'SET_USER': 'SET_USER',
    'REFRESH': 'REFRESH',
    'FORBIDDEN': 'FORBIDDEN',
};
