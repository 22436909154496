// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Breadcrumb extends PureComponent {
  static displayName = 'Breadcrumb';

  static propTypes = {
      breadcrumb: propTypes.arrayOf(propTypes.shape({
          id: propTypes.number,
          name: propTypes.string,
      })),
      account: propTypes.shape({
          id: propTypes.number,
          account: propTypes.shape({
              name: propTypes.string,
          }),
      }),
      hasMultipleAccounts: propTypes.bool,
      isAdmin: propTypes.bool,
      onSettingsClicked: propTypes.func,
      documentId: propTypes.number,
  };

  render() {
      const { props: {
          account: {
              account: {
                  id,
                  name = 'Account',
              },
          },
          breadcrumb = [],
          hasMultipleAccounts,
          isAdmin,
          onSettingsClicked,
          documentId,
      } } = this;

      return (
          <Row>
              <NavLink to={(hasMultipleAccounts || isAdmin) ? `/account/${id}` : '/dashboard'}>
                  <p className="breadcrumb__link breadcrumb__link--first bold muted px-1">{name}</p>
              </NavLink>
              {_.map(breadcrumb, (link, index) => (
                  <Row key={index}>
                      <img src="/images/icons/Chevron/right.svg" alt=">" className="chevron px-1" />
                      <NavLink to={`${(hasMultipleAccounts || isAdmin) ? `/account/${id}` : ''}/project/${index > 0 ? documentId && index === breadcrumb.length - 1 ? breadcrumb.length > 2 ? `${breadcrumb[0].id}/${breadcrumb[breadcrumb.length - 2].id}/document/${documentId}` : `${breadcrumb[0].id}/document/${documentId}` : `${breadcrumb[0].id}/${link.id}` : link.id}`}>
                          <p className="breadcrumb__link muted px-1">{link.name}</p>
                      </NavLink>
                      {index === breadcrumb.length - 1 && (
                          <div className="btn-icon" role="button" onClick={onSettingsClicked}><img src="/images/icons/cog.svg" alt="Settings" /></div>
                      )}
                  </Row>
              ))}
          </Row>
      );
  }
}
