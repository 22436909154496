// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export class ActivityRow extends PureComponent {
    static displayName = 'ActivityRow';

    static propTypes = {
        document: propTypes.shape({
            users: propTypes.array,
            name: propTypes.string,
            permissions: propTypes.string,
            updated_at: propTypes.string,
            created_at: propTypes.string,
        }),
        created_by: propTypes.shape({
            first_name: propTypes.string,
            last_name: propTypes.string,
        }),
    };

    render() {
        return (
            <tr className="table__row table__row--activity">
                <td className="table__cell">
                    <span className="table-marker round" />
                    Vexels Presentation
                    <p className="text-small muted">Edited Document</p>
                </td>
                <td className="table__cell">Hype Document</td>
                <td className="table__cell">5h ago</td>
                <td className="table__cell">16/12/2019</td>
                <td className="table__cell">
                    <img src="/images/icons/view.svg" alt="View" className="pr-4" />
                </td>
            </tr>
        );
    }
}

export default class extends PureComponent {
    static displayName = 'TableRow';

    static propTypes = {};

    render() {
        const { props: { children, className, ...rest } } = this;
        return (
            <table
              className={cn('table', className)} {...rest}
            >
                <thead className="table__head">
                    <tr />
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        );
    }
}
