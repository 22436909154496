const minute = 1000 * 60;
const Constants = {
    events: {
        'LOGIN': { 'event': 'User login', 'category': 'User' },
        'REGISTER': { 'event': 'User register', 'category': 'User' },
    },
    pages: {
        HOME_PAGE: 'Home Page',
        LOGIN_PAGE: 'Login Page',
        DASHBOARD_PAGE: 'Admin Dashboard Page',
        REGISTRATION_PAGE: 'Registration Page',
        PROJECT_PAGE: 'Project Page',
        DOCUMENT_PAGE: 'Document Page',
        MANAGE_MEMBERS_PAGE: 'Manage Members Page',
        PROFILE_PAGE: 'Profile Page',
        DOCUMENT_VIEWER_PAGE: 'Document Viewer Page',
        RESET_PASSWORD_PAGE: 'Reset Password Page',
        PROJECT_HIERARCHY_PAGE: 'Project Hierarchy Page',
        COMPANY_HIERARCHY_PAGE: 'Company Hierarchy Page',
        DOCUMENT_INVITE_PAGE: 'Document Invite Page',
    },
    errors: {
        NO_ACCOUNT: 'We could not retrieve this account, please check you have permissions to view it.',
        NO_PROJECT: 'We could not retrieve this project, please check you have permissions to view it.',
        NO_COLLECTION: 'We could not retrieve this collection, please check you have permissions to view it.',
        NO_DOCUMENT: 'We could not retrieve this document, please check you have permissions to view it.',
        NO_USERS: 'We could not retrieve all users, please check you have permissions to view them.',
        NO_USERS_FOR_ACCOUNT: 'We could not retrieve users for this account, please check you have permissions to view them.',
        NO_USERS_FOR_PROJECT: 'We could not retrieve users for this project, please check you have permissions to view them.',
        NO_USERS_FOR_COLLECTION: 'We could not retrieve users for this collection, please check you have permissions to view them.',
        NO_USERS_FOR_DOCUMENT: 'We could not retrieve users for this document, please check you have permissions to view them.',
        NO_USER: 'We could not retrieve this user, please check you have permissions to view them.',
        'Bad credentials!': 'Your email address or password is incorrect. Please check your details and try again.',
    },
    sessionTimeout: minute,
    strings: {
        NO_PAGES: 'There are no pages in this document',
        ACCOUNTS_ERROR: 'There was an error retrieving your accounts',
        PROJECTS_ERROR: 'There was an error retrieving your projects',
        NO_ACCOUNTS: "You don't have access to any accounts",
        NO_PROJECTS: "You don't have access to any projects",
        NO_PROJECT_DATA: 'This project is empty',
        NO_COLLECTION_DATA: 'This collection is empty',
        NO_DOWNLOADS: "You don't have any downloads",
    },
    avatar: '/images/headshot.jpg',
    dateFormat: 'Do MMM YYYY',
    showAPICalls: true,
    showActions: true,
    refreshAfter: minute * 10, // refresh token
    projectSort: {
        'A-Z': 'A-Z',
        'Z-A': 'Z-A',
    },
    loginRedirect: '/dashboard',
    logoutRedirect: '/',
    fileTypes: {
        'html': 'HTML Document',
        'js': 'Javascript',
        'css': 'Cascading Stylesheet',
        'jpeg': 'Image',
        'png': 'Image',
        'jpg': 'Image',
        'xml': 'XML Document',
        'ttf': 'Font',
        'woff': 'Font',
        'json': 'JSON File',
        'swf': 'Adobe Flash Animation',
        'svg': 'Scalable Vector Graphics',
        'mp4': 'MP4 Video',
        'gif': 'Image',
        'otf': 'Font',
        'pdf': 'PDF Document',
    },
    reactSelectStyles: {
        control: (base, state) => ({
            ...base,
            backgroundColor: 'transparent',
            borderWidth: 0,
            boxShadow: 'none',
            '&:hover': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
            },
        }),
        option: base => ({
            ...base,
            backgroundColor: '#192025',
            color: '#fff',
        }),
        singleValue: base => ({
            ...base,
            color: '#fff',
        }),
        placeholder: base => ({
            ...base,
            color: 'rgb(255, 255, 255, 0.5)',
        }),
        input: base => ({
            ...base,
            color: '#fff',
        }),
    },
    simulate: {
        AUTOFILL_LOGIN: false,
    },
};

global.Constants = Constants;
module.exports = Constants;
