import moment from 'moment';

const Format = Object.assign({}, require('./base/_format'), {
    updatedDate(date) {
        return date ? moment(date).fromNow() : 'Never';
    },
    permissionType(permissionType) {
        return permissionType ? _.upperFirst(permissionType.toLowerCase()) : '';
    },
    createdDate(date) {
        return moment(date).format('DD/MM/YYYY');
    },
    fileType(filepath) {
        if (!filepath) return '';
        const filename = filepath.split('\\').pop().split('/').pop();
        const ext = filename.substr(filename.lastIndexOf('.') + 1);
        return Constants.fileTypes[ext] || '';
    },
    lastLoggedInAtDate(date) {
        return date ? moment(date).format('DD/MM/YYYY') : 'Never logged in';
    },
    publishedDate(date) {
        return moment(date).format('DD/MM/YY HH:mm');
    },
    documentType(type) {
        switch (type) {
            case 'HYPE':
                return 'Hype';
            case 'PDF':
                return 'PDF';
            default:
                return '';
        }
    },
});

global.Format = Format;
module.exports = Format;
