import React from 'react';

import Table, { CollectionRow, DocumentRow } from '../ItemTable';
import StatCard, { UsersStatCard } from '../StatCard';
import DeleteItemModal from '../modals/DeleteItem';

export default hot(module)(class extends React.Component {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static propTypes = {
        projectId: propTypes.string,
        collectionId: propTypes.string,
    }

    static displayName = 'ProjectHierarchyPage';

    state = {};

    componentDidMount() {
        API.trackPage(Constants.pages.PROJECT_HIERARCHY_PAGE);
        ES6Component(this);

        AppActions.getInstanceStats(parseInt(this.props.collectionId) || parseInt(this.props.projectId));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.collectionId !== this.props.collectionId || prevProps.projectId !== this.props.projectId) {
            AppActions.getInstanceStats(parseInt(this.props.collectionId) || parseInt(this.props.projectId));
        }
    }

    onError = (error) => {
        this.context.router.history.replace('/dashboard');
        toast(error);
    }

    onCollectionNotFound = () => {
        this.context.router.history.replace('/dashboard');
        toast(Constants.errors.NO_COLLECTION);
    }

    deleteCollection = (collection) => {
        openModal({
            body: <DeleteItemModal name={collection.name} type="collection" onConfirm={() => AppActions.deleteCollection(collection.id)} />,
            className: 'modal--default',
        });
    }

    deleteDocument = (document) => {
        openModal({
            body: <DeleteItemModal name={document.name} type="document" onConfirm={() => AppActions.deleteDocument(document.id)} />,
            className: 'modal--default',
        });
    }

    renderRows = (data) => {
        // Get all collections or documents from an array
        const documentsOnly = document.location.pathname.indexOf('documents') !== -1;
        const items = Utils.getItemsFromHierarchy(data, documentsOnly);
        const accountId = Utils.getAccountId(this.props);
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const isAdmin = AccountStore.isAdmin();
        return _.map(items, (item) => {
            if (item.type === 'FOLDER') {
                return (
                    <CollectionRow
                      key={item.id} collection={item} permissionType={item.permission_type}
                      onDelete={() => this.deleteCollection(item)}
                      onClick={() => this.context.router.history.push(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : '/dashboard'}/project/${this.props.projectId}/${item.id}`)}
                    />
                );
            }
            return (
                <DocumentRow
                  key={`doc-${item.id}`} document={item} permissionType={item.permission_type}
                  onDelete={() => this.deleteDocument(item)}
                  onClick={() => this.context.router.history.push(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : '/dashboard'}/project/${this.props.projectId}${this.props.collectionId ? `/${this.props.collectionId}` : ''}/document/${item.id}`)}
                />
            );
        });
    }

    render = () => {
        const { projectId, collectionId } = this.props;
        return (
            <div className="content-pane">
                <ProjectProvider
                  onCollectionNotFound={this.onCollectionNotFound}
                  onError={this.onError}
                  projectId={parseInt(projectId)}
                  id={parseInt(collectionId) || parseInt(projectId)}
                >
                    {({ isLoading, error, data, stats }) => {
                        if (error) return <p>{`Error retriving project ${document.location.pathname.indexOf('documents') !== -1 ? 'documents' : 'collections'}`}</p>;
                        if (isLoading || !data) return <Loader />;
                        const { breadcrumb } = data;
                        const pathname = this.context.router.route.location.pathname;
                        return (
                            <div>
                                <h4>Hierarchy</h4>
                                <div className="row">
                                    {stats && stats.num_documents != null ? (
                                        <div className="col-md-3 mb-3 mb-sm-5">
                                            <StatCard
                                              title="Documents"
                                              content={stats.num_documents}
                                              footer={stats.num_new_documents != null ? `${stats.num_new_documents} New documents created this month` : ''}
                                              onClick={() => this.context.router.history.push(`${pathname.substr(0, pathname.lastIndexOf('/'))}/documents`)}
                                            />
                                        </div>
                                    ) : null}
                                    {stats && stats.num_collections != null ? (
                                        <div className="col-md-3 mb-3 mb-sm-5">
                                            <StatCard
                                              title="Collections"
                                              content={stats.num_collections}
                                              footer={stats.num_new_collections != null ? `${stats.num_new_collections} New collections created this month` : ''}
                                              onClick={() => this.context.router.history.push(`${pathname.substr(0, pathname.lastIndexOf('/'))}/collections`)}
                                            />
                                        </div>
                                    ) : null}
                                    {stats && stats.members != null ? (
                                        <div className="col-md-3 mb-3 mb-sm-5">
                                            <UsersStatCard
                                              title="Members"
                                              users={stats.members}
                                              footer={`${stats.num_new_members || 0} new members joined ${breadcrumb && breadcrumb[0].name} this month`}
                                              onClick={_.last(breadcrumb).permission_type === 'OWNER' ? this.onUsersStatCardClicked : null}
                                            />
                                        </div>
                                    ) : null}
                                    {stats && stats.size != null ? (() => {
                                        const accountName = Utils.getAccountName(this.props);
                                        return (
                                            <div className="col-md-3 mb-3 mb-sm-5">
                                                <StatCard
                                                  title="Usage"
                                                  content={filesize(stats.size)}
                                                  footer={`${accountName} has 7.08GB storage space`}
                                                />
                                            </div>
                                        );
                                    })() : null}
                                </div>
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <Table>
                                            {this.renderRows(data)}
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </ProjectProvider>
            </div>
        );
    }
});
