import moment from 'moment';

const BaseStore = require('./base/_store');
const data = require('./base/_data');
const endpoints = require('./endpoints');

const controller = {

    getProject: (id, folderId, force) => {
        if (store.isLoading) return;

        if ((id == store.projectId || !id) && !force) {
            return; // todo: handle refreshing single collection
        } if (!force) {
            store.model = { allDocuments: [] };
        }
        store.loading();
        store.projectId = id;

        AsyncStorage.getItem(`project${store.projectId}`, (err, res) => {
            store.newModel = {
                allDocuments: [],
            };

            if (res) {
                store.model = JSON.parse(res);
                store.changed();
            }
            return controller.getCollection(id)
                .then(controller.loaded)
                .catch((error) => {
                    API.handleError(store, error);
                });
        });
    },
    loaded: () => {
        store.model = store.newModel;
        store.newModel = { allDocuments: [] };
        AsyncStorage.setItem(`project${store.projectId}`, JSON.stringify(store.model));

        store.loaded();
    },
    getCollection(instanceId, collectionId, data, key = []) {
        // Get all documents and sub collections
        const promises = store.isPublic ? [endpoints.getPublicDocuments(instanceId).then((documents) => {
            return {
                documents,
            };
        })] : [data ? Promise.resolve(data) : endpoints.getSubCollections(instanceId, collectionId)];

        if (!collectionId && !store.isPublic) { // if this is a project then also get the project metadata
            promises.push(endpoints.getProject(instanceId, collectionId));
        }

        return Promise.all(promises).then(([hierarchy, data]) => {
            let documents = hierarchy.documents || [];
            let collections = hierarchy.folders || [];
            data = data || hierarchy;
            if (!collectionId) {
                data.permission_type = hierarchy.permission_type;
            }

            if (bulletTrain.hasFeature('empty_project')) {
                store.newModel[collectionId || instanceId] = {
                    data,
                    collections: [],
                    documents: [],
                };
                return;
            }

            documents = documents && _.sortBy(documents.map(({ document: res, permission_type }) => {
                return Object.assign(res, {
                    type: 'DOCUMENT',
                    document_type: res.type,
                    key: `doc-${res.id}`,
                    downloadId: `${res.id}`,
                    updated_at_formatted: Format.updatedDate(res.published_at),
                    search: res.name && res.name.toLowerCase(),
                    updated_at_value: moment(res.published_at).valueOf(),
                    permission_type,
                });
            }), 'created_at');

            if (bulletTrain.getValue('data_multiplier') && documents) {
                let newRes = [].concat(documents);
                _.map(_.times(bulletTrain.getValue('data_multiplier') - 1), (i) => {
                    newRes = newRes.concat(documents.map((item) => {
                        return Object.assign({}, item, {
                            id: `${item.id}${i}`,
                            key: item.key + i,
                            name: `${item.name} Copy ${i}`,
                        });
                    }));
                });
                documents = newRes;
            }


            collections = collections && _.orderBy(collections.map((res) => {
                return Object.assign({}, res, {
                    type: 'FOLDER',
                    key: `folder-${res.id}`,
                    updated_at_formatted: Format.updatedDate(res.updated_at),
                    updated_at_value: moment(res.updated_at).valueOf(),
                    search: res.name && res.name.toLowerCase(),
                    folders: null,
                    documents: null,
                    permission_type: res.permission_type,
                });
            }), ['name'], ['desc']);

            store.newModel.allDocuments = store.newModel.allDocuments.concat(documents);

            // breadcrumb
            const newKey = store.isPublic
                ? [{ id: instanceId, name: 'Public documents' }]
                : key.concat([{
                    settings: data && data.settings && Object.assign({}, data.settings, { id: data.id }),
                    id: data.id,
                    name: data.name,
                    permission_type: data.permission_type,
                }]);

            store.newModel[collectionId || instanceId] = { // store the data keyed by instance id
                data,
                key: newKey,
                documents,
                collections,
            };

            if (collections && collections.length) { // if there are sub collections, fetch those recursively
                return Promise.all(hierarchy.folders.map((collection) => {
                    return controller.getCollection(collection.instance_id, collection.id, collection, newKey);
                }));
            }
        });
    },
    createCollection(accountId, collectionId, details) {
        store.saving();
        endpoints.createInstance(accountId, Object.assign({}, details, { parent_instance_id: collectionId || store.projectId }))
            .then((res) => {
                store.savedId = res.id;
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    uploadCoverImage: (coverImage) => {
        if (!coverImage) {
            return Promise.resolve();
        }
        return endpoints.upload(coverImage, 'DOCUMENT')
            .then(({ url }) => {
                return Promise.resolve(url);
            });
    },
    createDocument(instanceId, details) {
        store.saving();
        controller.uploadCoverImage(details.coverImage)
            .then(cover_image_url => endpoints.createDocument(instanceId, { ...details, cover_image_url }))
            .then((res) => {
                store.savedId = res.id;
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    editCollection(id, details) {
        store.saving();
        endpoints.editInstance(id, details)
            .then((res) => {
                store.savedId = res.id;
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    editDocument(id, details) {
        store.saving();
        controller.uploadCoverImage(details.coverImage)
            .then(cover_image_url => endpoints.editDocument(id, { ...details, cover_image_url: cover_image_url || details.cover_image_url }))
            .then((res) => {
                store.savedId = res.id;
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    deleteDocument(id) {
        store.trigger('document-deleting', id);
        endpoints.deleteDocument(id)
            .then(() => {
                store.trigger('document-deleted', id);
            })
            .catch(error => API.handleError(store, error));
    },
    deleteCollection(id) {
        store.saving();
        endpoints.deleteInstance(id)
            .then(() => {
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    publishDocument(id) {
        store.saving();
        endpoints.publishDocument(id)
            .then(() => {
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    unpublishDocument(id) {
        store.saving();
        endpoints.unpublishDocument(id)
            .then(() => {
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    getStats(id) {
        endpoints.getInstanceStats(id)
            .then((res) => {
                store.stats = res;
                store.loaded();
            })
            .catch(error => API.handleError(store, error));
    },
};


const store = Object.assign({}, BaseStore, {
    id: 'project',
    model: { allDocuments: [] },
    newModel: { allDocuments: [] },
    getAllDocuments() {
        return store.model && store.model.allDocuments;
    },
    getData(id) {
        if (!store.model || !store.model[id]) return null;

        const collections = [];
        _.each(store.model[id].collections, (collection) => {
            const collectionData = store.model[collection.id];

            if (collectionData) {
                const data = { ...collection };
                const documents = collectionData.documents || [];
                // const nestedCollections = _.filter(collectionData.collections, ({id}) => { //filter out empty collections
                //     return store.model[id] &&
                //         (
                //             (store.model[id].collections && store.model[id].collections.length) ||
                //             (store.model[id].documents && store.model[id].documents.length)
                //         )
                // }) || [];

                const nestedCollections = collectionData.collections || [];

                // if (documents.length || nestedCollections.length) {
                data.collections = nestedCollections;
                data.documents = documents;
                collections.push(data);
                // }
            }
        });
        return {
            data: (store.model[id].collections || []).concat(store.model[id].documents || []),
            breadcrumb: store.model[id].key, // breadcrumb
            documents: store.model[id].documents, // top level documents
            collections: bulletTrain.hasFeature('ignore_collections') ? [] : collections, // sub collections with files directly inside
        };
    },
    getCollection(id) {
        return store.model[id] && store.model[id].data;
    },
    getDocument(id) {
        return store.model && store.model.allDocuments && _.find(store.model.allDocuments, { id });
    },
});

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_PROJECT:
            store.isPublic = action.isPublic;
            controller.getProject(action.id, action.folderId, action.force);
            break;
        case Actions.ON_LOGIN:
        case Actions.ON_LOGOUT:
            store.projectId = null;
            store.model = null;
            store.error = null;
            break;
        case Actions.CREATE_COLLECTION:
            controller.createCollection(action.accountId, action.collectionId, action.details);
            break;
        case Actions.CREATE_DOCUMENT:
            controller.createDocument(action.instanceId, action.details);
            break;
        case Actions.EDIT_COLLECTION:
            controller.editCollection(action.id, action.details);
            break;
        case Actions.EDIT_DOCUMENT:
            controller.editDocument(action.id, action.details);
            break;
        case Actions.DELETE_DOCUMENT:
            controller.deleteDocument(action.id);
            break;
        case Actions.DELETE_COLLECTION:
            controller.deleteCollection(action.id);
            break;
        case Actions.PUBLISH_DOCUMENT:
            controller.publishDocument(action.id);
            break;
        case Actions.UNPUBLISH_DOCUMENT:
            controller.unpublishDocument(action.id);
            break;
        case Actions.GET_INSTANCE_STATS:
            controller.getStats(action.id);
            break;
        default:
    }
});

controller.store = store;
module.exports = controller.store;
