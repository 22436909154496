// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export class NavigationItem extends PureComponent {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static displayName = 'NavigationItem';

    static propTypes = {
        onClick: propTypes.func,
        to: propTypes.string,
        active: propTypes.bool,
        navText: propTypes.string,
        className: propTypes.string,
        onClickChevron: propTypes.func,
    };

    render() {
        const { props: { className, navText, active, onClick, to, onClickChevron } } = this;
        return (
            <div role="link" className="aside__link clickable" onClick={onClick || (() => this.context.router.history.push(to))}>
                <Row
                  className={cn('nav-item', className, { 'nav-item--active': active })} space
                >
                    {navText}
                    <div
                      role="button" className="clickable" onClick={onClickChevron ? ((e) => { e.stopPropagation(); onClickChevron(); }) : undefined}
                      style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                        <img src={active ? '/images/icons/Chevron/down.svg' : '/images/icons/Chevron/right.svg'} alt=">"/>
                    </div>
                </Row>
            </div>
        );
    }
}

export class NavigationItemCompany extends PureComponent {
    static displayName = 'NavigationItemCompany';

    static propTypes = {};

    render() {
        const { props: { className, navText, navImage, active, ...rest } } = this;
        return (
            <NavLink className="aside__link" {...rest}>
                <Row
                  className={cn('nav-item', className, { 'nav-item--active': active })} space
                >
                    <div className="nav-item__avatar round mr-2" style={{ backgroundImage: `url('${navImage}')`, backgroundSize: 'cover' }} />
                    <div className="flex-1 overflow-hidden">
                        <p className="truncate">{navText}</p>
                    </div>
                    <img src={active ? '/images/icons/Chevron/down.svg' : '/images/icons/Chevron/right.svg'} alt=">"/>
                </Row>
            </NavLink>
        );
    }
}


export class NavigationItemHeader extends PureComponent {
    static displayName = 'NavigationItemHeader';

    static propTypes = {
        hideChevron: propTypes.bool,
    };

    render() {
        const { props: { className, navText, to, active, hideChevron, ...rest } } = this;
        const component = (
            <Row
              className={cn('nav-item', className)}
              {...rest} space
            >
                <p className="bold">{navText}</p>
                {to && !hideChevron ? <img src={active ? '/images/icons/Chevron/down.svg' : '/images/icons/Chevron/right.svg'} alt=">"/> : null}
            </Row>
        );
        if (to) {
            return (
                <NavLink className="aside__link" to={to} {...rest}>
                    {component}
                </NavLink>
            );
        }
        return component;
    }
}

export class NavigationItemCollection extends PureComponent {
    static displayName = 'NavigationItemCollection';

    static propTypes = {};

    render() {
        const { props: { className, navText, active, ...rest } } = this;
        return (
            <NavLink className="aside__link" {...rest}>
                <Row
                  className={cn('nav-item nav-item__collection pl-4', className, { 'nav-item__collection--active': active })}
                  space
                >
                    <img src={active ? '/images/icons/collection--orange.svg' : '/images/icons/collection--white.svg'} alt="Document"/>
                    <p className="flex-1 pl-2 small ">{navText}</p>
                </Row>
            </NavLink>
        );
    }
}

export class NavigationItemDocument extends PureComponent {
    static displayName = 'NavigationItemDocument';

    static propTypes = {};

    render() {
        const { props: { className, navText, active, ...rest } } = this;
        return (
            <NavLink className="aside__link" {...rest}>
                <Row
                  className={cn('nav-item nav-item__document pl-4', className, { 'nav-item__document--active': active })}
                  {...rest} space
                >
                    <img src={active ? '/images/icons/document--orange.svg' : '/images/icons/document--white.svg'} alt="Document"/>
                    <p className="flex-1 pl-2 small ">{navText}</p>
                </Row>
            </NavLink>
        );
    }
}

export class NavigationItemProject extends PureComponent {
    static displayName = 'NavigationItemProject';

    static propTypes = {};

    render() {
        const { props: { className, navText, active, ...rest } } = this;
        return (
            <NavLink className="aside__link" {...rest}>
                <Row
                  className={cn('nav-item nav-item__collection pl-4', className, { 'nav-item__collection--active': active })}
                  space
                >
                    <img src={active ? '/images/icons/nav-plus-circle--orange.svg' : '/images/icons/nav-plus-circle.svg'} alt="Project"/>
                    <p className="flex-1 pl-2 small ">{navText}</p>
                </Row>
            </NavLink>
        );
    }
}
