/**
 * Created by andrewmoffatt on 12/03/2019.
 */
import React from 'react';

import data from '../../../common/stores/base/_data';
import PageController from '../PageController';
import UpdateDocumentUpload from './UpdateDocumentUpload';
import UpdateDocumentUploaded from './UpdateDocumentUploaded';

const STEP_COUNT = 2;

export default hot(module)(class extends React.Component {
    static displayName = 'UpdateDocumentModal';

    static propTypes = {
        projectId: propTypes.number.isRequired,
        collectionId: propTypes.number,
        documentId: propTypes.number.isRequired,
        assets: propTypes.array,
        documentType: propTypes.string,
    }

    state = {
        name: '',
        step: 1,
        version: '',
    };

    valid = () => {
        const { name, version } = this.state;
        // @TODO validate version number
        return name.length;
    }

    upload = () => {
        const filesToUpload = _.filter(this.state.files, file => !file.ignore);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', `${Project.api}documents/${this.props.documentId}/assets`, true);
        xhr.setRequestHeader('Authorization', `Bearer ${data.token}`);

        xhr.upload.onprogress = ({ loaded, total }) => {
            this.setState({ uploadProgress: loaded / total * 100 });
        };
        xhr.upload.onloadstart = () => {
            this.setState({ isUploading: true });
            this.forceUpdate();
        };
        xhr.upload.onerror = (uploadError) => {
            this.setState({ uploadError, isUploading: false });
        };
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200 || xhr.status === 201) {
                    const doc = JSON.parse(xhr.responseText);
                    AppActions.updateDocumentAssets(this.props.documentId, doc.assets);
                    this.setState({ uploadProgress: 100, uploadStatus: 'success' });
                    setTimeout(() => {
                        this.setState({ step: this.state.step + 1 });
                    }, 500);
                } else {
                    let uploadError;
                    if (xhr.status === 413) { // entity request too large
                        uploadError = 'One or more files were too large to upload. Contact a system administrator.';
                    } else {
                        try {
                            uploadError = JSON.parse(xhr.responseText);
                            uploadError = (uploadError && uploadError.error) || xhr.responseText || 'We could not complete your request';
                        } catch (e) {
                            uploadError = xhr.responseText || 'We could not complete your request';
                        }
                    }
                    this.setState({ uploadError, isUploading: false });
                }
            }
        };

        const formData = new FormData();
        const metadata = [];
        const isFolderDrop = Utils.isFolderDrop(this.state.files);
        _.each(filesToUpload, (file) => {
            formData.append('files', file);
            metadata.push({
                last_modified: moment(file.lastModified).utc().toISOString(),
                path: Utils.parseDocumentFilePath(file.path, isFolderDrop),
            });
        });
        formData.append('metadata', JSON.stringify(metadata));
        formData.append('version', this.state.version);

        xhr.send(formData);
    }

    publish = () => {
        AppActions.publishDocument(this.props.documentId);
    }

    onDrop = (files) => {
        // Remove existing files that have not been modified
        const isFolderDrop = Utils.isFolderDrop(files);
        const newOrModifiedFiles = _.filter(files, ({ path, lastModified }) => {
            const existingAsset = _.find(this.props.assets, asset => (Utils.parseDocumentAssetFilename(asset.file_location, this.props.documentType) === Utils.parseDocumentFilePath(path, isFolderDrop)));
            if (!existingAsset) {
                // New file
                return true;
            }

            return !moment.utc(existingAsset.file_last_modified).isSame(moment(lastModified));
        });
        this.setState({ files: newOrModifiedFiles, isFolderDrop });
    }

    onIgnore = (file, ignore) => {
        const files = _.cloneDeep(this.state.files);
        _.find(files, { path: file.path }).ignore = ignore;
        this.setState({ files });
    }

    onIgnoreAll = (ignore) => {
        const files = _.cloneDeep(this.state.files);
        _.each(files, (file) => {
            file.ignore = ignore;
        });
        this.setState({ files });
    }

    renderContent = (isSaving, error) => {
        const { state: { step, files, isUploading, uploadProgress, uploadStatus, version, uploadError, isFolderDrop }, props: { documentType } } = this;
        switch (step) {
            case 1:
                return (
                    <UpdateDocumentUpload
                      className="form__content" next={this.upload} onDrop={this.onDrop}
                      onIgnore={this.onIgnore} onIgnoreAll={this.onIgnoreAll} files={files}
                      isUploading={isUploading} uploadProgress={uploadProgress} uploadStatus={uploadStatus}
                      error={error} version={version} onVersionChange={e => this.setState({ version: Utils.safeParseEventValue(e) })}
                      uploadError={uploadError} type={documentType} isFolderDrop={isFolderDrop}
                    />
                );
            case 2:
                return (
                    <UpdateDocumentUploaded
                      className="form__content" next={this.publish} files={files}
                      isPublishing={isSaving} error={error}
                    />
                );
            default:
                return null;
        }
    }

    render = () => {
        const { props: { projectId, collectionId }, state: { step } } = this;
        return (
            <div className="fullscreen--modal content-pane">
                <ProjectProvider projectId={projectId} id={collectionId || projectId} onSave={() => closeModal()}>
                    {({ isSaving, error }) => (
                        <React.Fragment>
                            <Row className="justify-content-end">
                                <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                                    <img className="" src="/images/icons/remove.svg" alt="Remove" />
                                </button>
                            </Row>
                            <div className="col-lg-10 m-auto">
                                <div className="fullscreen-modal__content">
                                    <Row>
                                        <h2 className="flex-1 mb-5">Update Document</h2>
                                        <PageController pageCount={STEP_COUNT} activePageIndex={step - 1}/>
                                    </Row>

                                    <form className="form">
                                        {this.renderContent(isSaving, error)}
                                    </form>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </ProjectProvider>
            </div>
        );
    }
});
