import React, { PureComponent } from 'react';

import { NavigationItem, NavigationItemHeader, NavigationItemCompany, NavigationItemProject, NavigationItemCollection, NavigationItemDocument } from './NavigationItem';
import ProjectStore from '../../common/stores/project-store';


export class Sidebar extends PureComponent {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static displayName = 'Sidebar';

    static propTypes = {
        accounts: propTypes.array,
        hasMultipleAccounts: propTypes.bool,
        isAdmin: propTypes.bool,
        projectId: propTypes.number,
        accountId: propTypes.number,
        collectionId: propTypes.number,
        documentId: propTypes.number,
    };

    state = {}

    toggleProject = (id, multipleAccountsOrAdmin, chevronClicked) => {
        if (this.props.projectId === id) {
            if (!chevronClicked && (this.props.collectionId || this.props.documentId)) {
                this.context.router.history.push(`${multipleAccountsOrAdmin ? `/account/${this.props.accountId}` : ''}/project/${id}`);
                return;
            }
            this.setState({ hideProjects: !this.state.hideProjects });
            return;
        }

        this.setState({ hideProjects: false });
        this.context.router.history.push(`${multipleAccountsOrAdmin ? `/account/${this.props.accountId}` : ''}/project/${id}`);
    }

    renderProjects = ({ hasMultipleAccounts, isAdmin, accountId, projectId, collectionId, documentId }) => (
        <React.Fragment>
            <NavigationItemHeader navText="Projects" to={(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : '/dashboard'} hideChevron />
            {!accountId ? <Loader /> : (
                <ProjectsProvider accountId={accountId}>
                    {({ isLoading: projectsLoading, projects }) => (
                        <ul className="nav sidebar__account flex-column ml-0 mr-0">
                            {projects && projects.length ? _.map(projects, project => (
                                <React.Fragment key={project.id}>
                                    <NavigationItem
                                      navText={project.name} onClick={() => this.toggleProject(project.id, hasMultipleAccounts || isAdmin)}
                                      to={`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : ''}/project/${project.id}`}
                                      active={project.id === projectId && !this.state.hideProjects}
                                      onClickChevron={() => this.toggleProject(project.id, hasMultipleAccounts || isAdmin, true)}
                                    />
                                    {project.id === projectId ? (
                                        <ProjectProvider projectId={projectId} id={projectId}>
                                            {({ isLoading: projectLoading, error, data }) => {
                                                if (projectLoading || error || !data) return <Loader />;
                                                const { collections, documents } = data;
                                                if (this.state.hideProjects) return null;
                                                return !collections.length && !documents.length ? <Row className="nav-item pl-4" space>No items found</Row>
                                                    : _.map(collections, ({ key, id, name }) => (
                                                        <NavigationItemCollection
                                                          key={key} navText={name} to={`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : ''}/project/${project.id}/${id}`}
                                                          active={collectionId ? (collectionId === id || (ProjectStore.model[collectionId].key.length > 2 && ProjectStore.model[collectionId].key[1].id === id)) : false}
                                                        />
                                                    )).concat(_.map(documents, ({ key, id, name }) => (
                                                        <NavigationItemDocument
                                                          key={key} navText={name} to={`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : ''}/project/${project.id}/document/${id}`}
                                                          active={id === documentId}
                                                        />
                                                    )));
                                            }}
                                        </ProjectProvider>
                                    ) : null}
                                </React.Fragment>
                            )) : projectsLoading ? <Loader /> : <Row className="nav-item" space>No projects found</Row>}
                        </ul>
                    )}
                </ProjectsProvider>
            )}
        </React.Fragment>
    );

    renderCompanies = ({ accounts, accountId }) => (
        <ul className="nav sidebar__account flex-column ml-0 mr-0">
            {_.map(accounts, ({ account }) => (
                <React.Fragment key={account.id}>
                    <NavigationItemCompany
                      key={account.id} navText={account.name} navImage={account.logo_url}
                      to={account.id === accountId ? '/dashboard' : `/account/${account.id}`}
                      active={account.id === accountId}
                    />
                    {account.id === accountId ? (
                        <ProjectsProvider accountId={account.id}>
                            {({ isLoading: projectsLoading, projects }) => (
                                <React.Fragment>
                                    {projects && projects.length ? _.map(projects, project => (
                                        <NavigationItemProject key={project.id} navText={project.name} to={`/account/${account.id}/project/${project.id}`} />
                                    )) : projectsLoading ? <Loader /> : <Row className="nav-item" space>No projects found</Row>}
                                </React.Fragment>
                            )}
                        </ProjectsProvider>
                    ) : null}
                </React.Fragment>
            ))}
        </ul>
    );

    render() {
        const { props: { accounts, hasMultipleAccounts, isAdmin, projectId, accountId, collectionId, documentId } } = this;
        return (
            <React.Fragment>
                <Flex>
                    {!accounts ? <Loader /> : (hasMultipleAccounts || isAdmin) ? (
                        <React.Fragment>
                            <div className="aside__brand mb-3">
                                <Link to="/dashboard" className="d-flex justify-content-center">
                                    <img
                                      src={accountId ? _.find(accounts, ({ account }) => account.id === parseInt(accountId)).logo_url || '/images/icons/publicate_logo.png' : '/images/icons/publicate_logo.png'} alt="logo"
                                      className="aside__brand-image img-fluid mt-3"
                                    />
                                </Link>
                            </div>
                            <NavigationItemHeader navText="Companies" to="/dashboard" hideChevron />
                            {
                                projectId ? this.renderProjects({ hasMultipleAccounts, isAdmin, accountId, projectId, collectionId, documentId })
                                    : this.renderCompanies({ accounts, accountId })
                            }
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="aside__brand mb-3">
                                <Link to="/dashboard" className="d-flex justify-content-center">
                                    <img
                                      src={(accounts.length && accounts[0].account.logo_url) || '/images/icons/publicate_logo.png'} alt="logo"
                                      className="aside__brand-image img-fluid mt-3"
                                    />
                                </Link>
                            </div>
                            {this.renderProjects({ accountId, projectId, collectionId, documentId })}
                        </React.Fragment>
                    )}
                </Flex>

                {isAdmin ? <NavigationItemHeader navText="Manage Members" to="/members" hideChevron /> : null}
            </React.Fragment>
        );
    }
}

const SidebarContainer = class extends PureComponent {
    static displayName = 'SidebarContainer';

    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static propTypes = {
        accountId: propTypes.string,
        projectId: propTypes.string,
        collectionId: propTypes.string,
        documentId: propTypes.string,
        presentationMode: propTypes.bool,
    };

    render() {
        const { props: { accountId, projectId, collectionId, documentId, presentationMode } } = this;
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const isAdmin = AccountStore.isAdmin();
        if (presentationMode) return null;
        return (
            <nav className="pl-0 pr-0 aside d-flex flex-column mx-0">
                <AccountProvider>
                    {({ user }) => {
                        return !user ? <Loader />
                            : (
                                <Sidebar
                                  hasMultipleAccounts={hasMultipleAccounts}
                                  isAdmin={isAdmin}
                                  accounts={user.accounts}
                                  accountId={accountId ? parseInt(accountId) : (hasMultipleAccounts || isAdmin) ? null : user.accounts.length ? user.accounts[0].account.id : null}
                                  projectId={projectId ? parseInt(projectId) : null}
                                  collectionId={collectionId ? parseInt(collectionId) : null}
                                  documentId={documentId ? parseInt(documentId) : null}
                                />
                            );
                    }}
                </AccountProvider>
            </nav>
        );
    }
};

export default ConfigProvider(SidebarContainer);
