const BaseStore = require('./base/_store');

const store = Object.assign({}, BaseStore, {
    id: 'theme',
    model: navigator.product === 'ReactNative' ? styleVariables.theme : null, // todo: move styleVariables.theme to API.getTheme
});

store.setTheme = (theme) => {
    if (navigator.product !== 'ReactNative') return;

    store.model = Object.assign({}, theme && theme.theme === 'light' ? styleVariables.themeLight : styleVariables.theme, theme ? Utils.convertToTheme(theme) : {});
    store.changed();
};
store.model = navigator.product === 'ReactNative' ? Object.assign({}, styleVariables.theme) : null;

module.exports = store;
