module.exports = {

    connected(lastSession) { // when the device comes online
        Dispatcher.handleViewAction({
            actionType: Actions.CONNECTED,
            lastSession,
        });
    },
    disconnected() { // when the device goes offline
        Dispatcher.handleViewAction({
            actionType: Actions.DISCONNECTED,
            // todo: maybe store last online
        });
    },
    active() { // when the device comes online
        Dispatcher.handleViewAction({
            actionType: Actions.ACTIVE,
        });
    },
    inactive() { // when the device goes offline
        Dispatcher.handleViewAction({
            actionType: Actions.INACTIVE,
            // todo: maybe store last online
        });
    },
    active(lastSession) { // When the device goes active
        Dispatcher.handleViewAction({
            actionType: Actions.ACTIVE,
            lastSession,
        });
    },
    inactive() { // When the device goes inactive
        Dispatcher.handleViewAction({
            actionType: Actions.INACTIVE,
        });
    },
    refresh() { // refresh the entire app
        Dispatcher.handleViewAction({
            actionType: Actions.REFRESH,
        });
    },
    registerNotifications(data) {
        Dispatcher.handleViewAction({
            actionType: Actions.REGISTER_NOTIFICATIONS,
            data,
        });
    },
    setUser(user) {
        Dispatcher.handleViewAction({
            actionType: Actions.SET_USER,
            user,
        });
    },
    register(details) { // refresh the entire app
        Dispatcher.handleViewAction({
            actionType: Actions.REGISTER,
            details,
        });
    },
    login(details) { // refresh the entire app
        Dispatcher.handleViewAction({
            actionType: Actions.LOGIN,
            details,
        });
    },
    logout() { // refresh the entire app
        Dispatcher.handleViewAction({
            actionType: Actions.LOGOUT,
        });
    },
    forbidden() { // refresh the entire app
        Dispatcher.handleViewAction({
            actionType: Actions.FORBIDDEN,
        });
    },
};
