// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export default class BasicButton extends PureComponent {
  static displayName = 'BasicButton';

  static propTypes = {};

  render() {
      const { props: { className, buttonText, ...rest } } = this;
      return (
          <button
            type="button" className={cn('btn--primary', className)}
            {...rest}
          >
              {buttonText}
          </button>
      );
  }
}

export class TabButton extends PureComponent {
    static displayName = 'TabButton';

    static propTypes = {};

    render() {
        const { props: { className, buttonText, ...rest } } = this;
        return (
            <button
                type="button" className={cn('btn btn--short', className)}
                {...rest}
            >
                {buttonText}
            </button>
        );
    }
}

