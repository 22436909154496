import { PureComponent } from 'react';

export default class TableActionButton extends PureComponent {
  static displayName = 'TableActionButton';

  static propTypes = {
      labelText: propTypes.node,
      labelSubtext: propTypes.string,
      src: propTypes.string,
      alt: propTypes.string,
      onClick: propTypes.func,
  }

  render() {
      const { labelText, labelSubtext, src, alt, onClick, className } = this.props;
      return (
          <div role="button" className={'btn-icon mr-4 mb-1 ' + className} onClick={onClick}>
              <img src={src} alt={alt} />
              {labelSubtext ? (
                  <div className="table-action__text-container ml-2">
                      <p className="text-small">{labelText}</p>
                      <span className="text-smaller muted">
                          {labelSubtext}
                      </span>
                  </div>
              ) : (
                  <p className="text-small ml-2 table-action__text-container">{labelText}</p>
              )}
          </div>
      );
  }
}
