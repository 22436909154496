import { Component } from 'react';
import ProjectListStore from '../stores/project-list-store';
import ThemeStore from '../stores/theme-store';

const TheComponent = class extends Component {
    static displayName = 'TheComponent';

    static propTypes = {
        onOneProject: propTypes.func,
        accountId: propTypes.number,
        onThemeChange: propTypes.func,
    }

    constructor(props, context) {
        super(props, context);
        const idHasChanged = (props.accountId || Project.defaultAccount) !== ProjectListStore.accountId;
        this.state = { // Project List Store State
            isLoadingInitial: true,
            isLoading: !ProjectListStore.model || (navigator.product === 'ReactNative' ? ProjectListStore.getProjects().length === 1 : false) || idHasChanged,
            isSaving: ProjectListStore.isSaving,
            error: ProjectListStore.error,
            projects: idHasChanged ? null : ProjectListStore.getProjects(),
            stats: idHasChanged ? null : ProjectListStore.getStats(),
        };
    }

    componentWillMount() {
        if (this.state.projects && this.state.projects.length == 1 && navigator.product === 'ReactNative') {
            this.state.isLoading = true;
            return this.props.onOneProject && this.props.onOneProject(this.state.projects);
        }
    }

    componentDidMount() { // Get Projects on mount, listen to store changing
        ES6Component(this);
        if (!ProjectListStore.model || ProjectListStore.accountId !== (this.props.accountId || Project.defaultAccount)) {
            AppActions.getProjects(this.props.accountId || Project.defaultAccount);
            AppActions.getAccountStats(this.props.accountId || Project.defaultAccount);
        }
        if (this.state.projects) {
            const theme = AccountStore.getSettings(this.props.accountId || Project.defaultAccount);
            ThemeStore.setTheme(theme);
            if (this.props.onThemeChange) this.props.onThemeChange();
        }
        this.listenTo(ProjectListStore, 'change', () => {
            if (!ProjectListStore.isLoading && this.state.isLoadingInitial && navigator.product === 'ReactNative') {
                if (ProjectListStore.getProjects() && ProjectListStore.getProjects().length === 1 && navigator.product === 'ReactNative') {
                    return this.props.onOneProject && this.props.onOneProject(ProjectListStore.getProjects());
                }
            }

            this.setState({
                isLoadingInitial: ProjectListStore.isLoading,
                isLoading: ProjectListStore.isLoading,
                isSaving: ProjectListStore.isSaving,
                error: ProjectListStore.error,
                isRefreshing: ProjectListStore.isLoading && this.state.isRefreshing,
                projects: ProjectListStore.getProjects(),
                stats: ProjectListStore.getStats(),
            });

            if (ProjectListStore.accountId == this.props.accountId) {
                const theme = AccountStore.getSettings(this.props.accountId || Project.defaultAccount);
                ThemeStore.setTheme(theme);
                if (this.props.onThemeChange) this.props.onThemeChange();
            }
        });
        this.listenTo(ProjectListStore, 'loaded', () => {
            const projects = ProjectListStore.getProjects();
            this.setState({ noProjectsFound: !projects || !projects.length });
        });
        this.listenTo(ProjectListStore, 'saved', () => {
	        this.props.onSave && this.props.onSave(ProjectListStore.savedId);
        });
        this.listenTo(ProjectListStore, 'problem', () => {
            if (this.props.onError) {
                this.props.onError(ProjectListStore.error);
            }
        });
        if (global.DeviceStateStore) {
            this.listenTo(global.DeviceStateStore, 'change', () => {
                if (global.DeviceStateStore.getLastSession() > Constants.sessionTimeout) {
                    this.refresh();
                }
            });
            this.listenTo(global.NetworkStore, 'change', () => {
                if (global.NetworkStore.getLastSession() > Constants.sessionTimeout) {
                    this.refresh();
                }
            });
        }
    }

    sort = (type) => { // Sort projects according to project sort constants
        switch (type) {
            case 'A-Z': {

            }
        }
        this.setState({
            projects: projects && _.sortBy(projects, () => {

            }),
        });
    };

    refresh = () => {
        this.setState({ isRefreshing: true }, () => {
            AppActions.getProjects(this.props.accountId || Project.defaultAccount, true);
            AppActions.getAccountStats(this.props.accountId || Project.defaultAccount);
        });
    };

    render() { // Render with project store state and sort function
        const { isLoading, isSaving, projects, error, isRefreshing, noProjectsFound, stats } = this.state;
        const { sort, refresh } = this;
        return (
            this.props.children({
                isLoading,
                isSaving,
                error,
                isRefreshing,
                projects,
                noProjectsFound,
                stats,
            }, { sort, refresh })
        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
