import React, { Component } from 'react';
import DocumentStore from '../stores/document-store';

const TheComponent = class extends Component {
    static displayName = 'DocumentPageProvider';

    static propTypes = {
        documentId: propTypes.number,
        children: propTypes.node,
        onError: propTypes.func,
        onSave: propTypes.func,
    };

    state = {
        isLoading: DocumentStore.isLoading,
        assets: DocumentStore.assets,
        stats: DocumentStore.stats,
        document: DocumentStore.model,
        links: DocumentStore.links,
    };

    componentDidMount() { // Get document assets on mount, listen to store changing
        ES6Component(this);
        AppActions.getAllDocumentData(this.props.documentId); // Get the doc, its assets and stats.
        this.listenTo(DocumentStore, 'change', () => {
            this.setState({
                assets: DocumentStore.assets,
                stats: DocumentStore.stats,
                document: DocumentStore.model,
                links: DocumentStore.links,
                isLoading: DocumentStore.isLoading,
            });
        });
        this.listenTo(DocumentStore, 'problem', () => {
            if (this.props.onError) {
                this.props.onError(DocumentStore.error);
            } else {
                this.setState({
                    error: DocumentStore.error,
                });
            }
        });
        this.listenTo(DocumentStore, 'saved', () => {
            if (this.props.onSave) this.props.onSave(DocumentStore.assets);
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.documentId !== this.props.documentId) {
            AppActions.getAllDocumentData(this.props.documentId);
        }
    }

    render() { // Render with project store state and sort function
        const { isLoading, assets, stats, document, links, error } = this.state;
        // const { download, cancel, updateDocument } = this;
        return (
            this.props.children({
                isLoading,
                error,
                assets,
                stats,
                document,
                links,
            })
        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
