// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export class ExistingMemberInviteRow extends PureComponent {
    static displayName = 'ExistingMemberInviteRow';

    static propTypes = {
        user: propTypes.object,
        onAdd: propTypes.func,
    };

    render() {
        const { props: { className, user, onAdd, ...rest } } = this;
        return (
            <Row
              className={cn('search-dropdown__item', className)}
              {...rest}
            >
                <Row className="flex-1">
                    <img alt="Some-guy Smith" src={Constants.avatar || user.image_path} className="invite-row__avatar round mr-3"/>
                    <div className="mr-3">
                        <p>{`${user.first_name} ${user.last_name}`}</p>
                        <p className="text-small muted">{user.email}</p>
                    </div>
                </Row>
                <img
                  src="/images/icons/add.svg" alt="Add" className="clickable"
                  onClick={onAdd}
                />
            </Row>
        );
    }
}

export class GroupInviteRow extends PureComponent {
    static displayName = 'GroupInviteRow';

    static propTypes = {};

    render() {
        const { props: { className, ...rest } } = this;
        return (
            <Row
              className={cn('search-dropdown__item', className)}
              {...rest}
            >
                <Row className="flex-1">
                    <img alt="Marketing" src="../images/icons/add-group.svg" className="invite-row__avatar round mr-3"/>
                    <div className="mr-3">
                        <p>Marketing</p>
                    </div>
                </Row>
                <img src="/images/icons/add.svg" alt="Add"/>
            </Row>
        );
    }
}

export class EmailMemberInviteRow extends PureComponent {
    static displayName = 'EmailMemberInviteRow';

    static propTypes = {
        email: propTypes.string.isRequired,
        onAdd: propTypes.func,
    };

    render() {
        const { props: { className, email, onAdd, ...rest } } = this;
        return (
            <Row
              className={cn('search-dropdown__item', className)}
              {...rest}
            >
                <p className="flex-1">{email}</p>
                <Row onClick={onAdd} className="clickable">
                    <p className="text-small muted mr-1">Add to invites</p>
                    <img src="/images/icons/paper-plane.svg" alt="Add" />
                </Row>
            </Row>
        );
    }
}
