// import propTypes from 'prop-types';
import React, { PureComponent, Component } from 'react';
import cn from 'classnames';

import DraftTable, { DraftUpdateRow } from './DraftTable';

const exampleUser = {
    'id': 14,
    'first_name': 'Marvin',
    'last_name': 'Nooitgedacht',
    'email': 'marvin@pixelflakes.com',
    'image_path': 'https://via.placeholder.com/512',
    'last_logged_in_at': null,
    'status_id': 0,
    'status_changed_at': '2017-02-10 16:18:35',
    'created_at': '2017-02-10 16:18:35',
    'updated_at': '2017-02-10 16:18:36',
    'created_by': null,
    'updated_by': null,
    'deleted_at': null,
    'image': null,
    'roles': [{
        'id': 1,
        'name': 'admin',
        'display_name': 'Admin',
        'description': 'The Super Admin role that can control all accounts, instances, documents and users',
        'created_at': '2016-12-01 16:24:03',
        'updated_at': '2016-12-01 16:24:03',
        'pivot': { 'user_id': 14, 'role_id': 1 },
    }],
    'accounts': [{
        'id': 4,
        'name': 'Publicate',
        'image_path': 'accounts/4/Publicate_backendlogo.jpg',
        'agreement_id': 2,
        'status_id': null,
        'status_changed_at': null,
        'user_count': 0,
        'instance_count': 0,
        'industry_id': 2,
        'main_contact_name': 'MULL',
        'main_contact_email': null,
        'main_contact_number': null,
        'settings': { 'example': true },
        'last_logged_in_at': '2019-02-19 09:58:46',
        'created_at': '2017-02-10 16:18:19',
        'updated_at': '2019-01-25 14:06:54',
        'created_by': null,
        'updated_by': 14,
        'deleted_at': null,
        'image_thumbnail': 'https://publicatev2.s3.eu-west-1.amazonaws.com/accounts/4/Publicate_backendlogo.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJHD37NAFP5NHW2JA%2F20190219%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20190219T113847Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Signature=6fe72b295c1431efd47e15c6d387173efa107ec1bcdd933fbcefa95ddac2854b',
        'pivot': { 'user_id': 14, 'account_id': 4, 'type_id': 2 },
        'agreement': {
            'id': 2,
            'name': 'Ab Recusandae',
            'max_instances': 5,
            'created_at': '2016-12-01 16:24:03',
            'updated_at': '2016-12-01 16:24:03',
            'created_by': null,
            'updated_by': null,
        },
        'industry': {
            'id': 2,
            'name': 'Ipsum',
            'created_at': '2016-12-01 16:24:03',
            'updated_at': '2016-12-01 16:24:03',
            'created_by': null,
            'updated_by': null,
        },
        'addresses': [],
    }],
};

export default class DraftDocument extends Component {
    static displayName = 'DraftDocument';

    state={};

    toggleExpand = (e) => {
        this.setState({ active : !this.state.active})
    }

    render() {
        const { props: { className, ...rest }, state:{active} } = this;
        return (
            <div className={cn( active ? 'draft-document draft-document--active' : 'draft-document',className)} {...rest}>
                <div className="document-row flex-row flex-1">
                    <div className="document-row__inner text-small flex-row flex-1 collapsed" onClick={this.toggleExpand}>
                        <div className="flex-1 flex-row justify-content-between">
                            <div className="flex-row">
                                <img src="/images/headshot.jpg" alt="Andrew Moffatt" className="document-row__avatar round mr-2"/>
                                <p>Andrew Moffatt</p>
                            </div>
                            <div className="flex-row">
                                <p className="mr-2">4 File changes</p>
                                {active ?
                                    <img src="/images/icons/Chevron/up.svg" alt="Chevron Down" className=""/> :
                                    <img src="/images/icons/Chevron/down.svg" alt="Chevron Up" className=""/>
                                }
                            </div>
                            <div className="flex-row mobile-fill justify-content-center justify-content-sm-left">
                                <p className="mr-2">Created 12/09/2019</p>
                            </div>
                        </div>
                        <div className="flex-row flex-1 justify-content-end ml-sm-auto mobile-fill justify-content-center justify-content-sm-end">
                            <div className="flex-row justify-content-end pr-0 pr-sm-5 mt-2 mt-sm-0">
                                <div className="btn-icon">
                                    <img src="/images/icons/preview--circle.svg" alt="delete" />
                                    <p className="btn-icon__text ml-2">Preview <br/>
                                        Document</p>
                                </div>
                            </div>
                            <button className="btn btn--primary btn--short mt-3 mt-sm-0">Update</button>
                        </div>
                    </div>
                    <div className="pl-4">
                        <img src="/images/icons/trash.svg" alt="delete" />
                    </div>
                </div>
                <div className="draft-document__body mt-3 mt-sm-5">
                    <div className="col-md-12 px-0">
                        <DraftTable>
                            <DraftUpdateRow user={exampleUser}/>
                            <DraftUpdateRow user={exampleUser}/>
                            <DraftUpdateRow user={exampleUser}/>
                            <DraftUpdateRow user={exampleUser}/>
                        </DraftTable>
                    </div>
                </div>
            </div>
        );
    }
}