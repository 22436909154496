import './project/polyfil';
import './project/libs';
import './project/api';
import './project/project-components';
import './styles/styles.scss';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ToastMessages from './project/toast';

import routes from './routes';

import { openModal, openConfirm } from './project/modals';

window.openModal = openModal;
window.openConfirm = openConfirm;

const bulletTrain = require('bullet-train-client');
// Add this line if you're using bulletTrain via npm
global.bulletTrain = bulletTrain;


const rootElement = document.getElementById('app');

const getUser = new Promise(((resolve) => {
    if (bulletTrain.hasFeature('new_user')) {
        resolve(null);
    } else {
        AsyncStorage.getItem('user', (err, res) => {
            if (res) {
                AccountStore.setUser(JSON.parse(res));
            } else {
                AppActions.setUser(null);
            }
            resolve(res);
        });
    }
}));

const getViewType = new Promise((resolve) => {
    AsyncStorage.getItem('viewType', (err, res) => {
        if (res) {
            window.viewType = res;
        }
        resolve(res);
    });
});

const getPresentationMode = new Promise((resolve) => {
    if (forcePresentationMode) {
        ConfigStore.presentationMode = true;
        resolve();
        return;
    }
    AsyncStorage.getItem('presentationMode', (err, res) => {
        if (res) {
            ConfigStore.presentationMode = !!res;
        }
        resolve(res);
    });
});

Promise.all([getUser, getViewType, getPresentationMode])
    .then(([res]) => {
        setTimeout(() => {
            const browserHistory = createBrowserHistory();

            // todo: move to util to decide if url is public
            const { pathname } = document.location;
            if (pathname !== '/' && pathname !== '/register' && pathname !== '/markup' && pathname !== '/reset-password'
                && pathname.indexOf('/document/invite/') === -1 && pathname.indexOf('/document/viewer/') === -1 && !res) {
                const { redirect } = Utils.fromParam();
                browserHistory.replace(`/?redirect=${redirect || encodeURIComponent(pathname)}`);
            }

            // TODO redirect logged in users who are not admins away from members pages etc.. otherwise they get 403 and logged out

            if (res) {
                AppActions.refreshUser();
            }

            ReactDOM.render(
                <Router history={browserHistory}>{routes}</Router>,
                rootElement,
            );
        }, 1);
    });

// Setup for toast messages
ReactDOM.render(<ToastMessages />, document.getElementById('toast'));

AppActions.getConfig();
