// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { SelectInput, CheckboxInput } from './FormInput';

export class AccountMemberRow extends PureComponent {
    static displayName = 'AccountMemberRow';

    static propTypes = {
        isDocument: propTypes.bool,
        user: propTypes.shape({
            image_path: propTypes.string,
            first_name: propTypes.string,
            last_name: propTypes.string,
            roles: propTypes.arrayOf(propTypes.shape({
                display_name: propTypes.string,
            })),
            last_logged_in_at: propTypes.string,
            accounts: propTypes.arrayOf(propTypes.shape({
                account: propTypes.object,
                admin: propTypes.bool,
            })),
        }),
        onClick: propTypes.func,
        onChangeCompanyRole: propTypes.func,
        onRemoveUser: propTypes.func,
        selected: propTypes.bool,
        onUserSelected: propTypes.func,
        accountId: propTypes.number,
    };

    render() {
        const {
            props: {
                isDocument,
                user: {
                    id,
                    image_path,
                    first_name = '',
                    last_name = '',
                    last_logged_in_at,
                    accounts,
                    email,
                },
                onClick,
                onChangeCompanyRole,
                onRemoveUser,
                onUserSelected,
                selected,
                accountId,
            } } = this;
        const account = _.find(accounts, acc => acc.account.id === accountId);
        const isAdmin = account && account.admin;
        return (
            <tr className="table__row" onClick={onClick}>
                {/* Name */}
                <td className="table__cell table__cell--check" onClick={e => e.stopPropagation()}>
                    <CheckboxInput checked={selected} onChange={(e) => { e.stopPropagation(); onUserSelected(id, Utils.safeParseEventValue(e)); }} />
                </td>
                <td className="table__cell">
                    <Row>
                        {image_path ? (
                            <img
                              className="avatar avatar--small round mr-2"
                              alt={`${first_name} ${last_name} Avatar`}
                              src={image_path}
                            />
                        ) : (
                            <div className="avatar__text avatar__text--small round bold mr-1">
                                {first_name[0].toUpperCase()}
                            </div>
                        )}
                        <Column>
                            <p className="mb-0 link-style">{`${first_name} ${last_name}`}</p>
                            <p>{email}</p>
                        </Column>
                    </Row>
                </td>
                {/* Role */}
                <td className="table__cell">
                    {AccountStore.getUser().id !== id ? (
                        <SelectInput
                          className="select--table" onClick={e => e.stopPropagation()}
                          onChange={onChangeCompanyRole}
                          value={isAdmin ? 'admin' : 'user'}
                        >
                            <option value="admin" className="option">Admin</option>
                            <option value="user" className="option">User</option>
                        </SelectInput>
                    ) : <span>Admin</span>}
                </td>
                {/* Status */}
                {isDocument ? <td className="table__cell">Downloaded</td> : null}
                {/* Last Seen */}
                <td className="table__cell">{Format.lastLoggedInAtDate(last_logged_in_at)}</td>
                {/* Actions */}
                <td className="table__cell">
                    {AccountStore.getUser().id !== id ? (
                        <div role="button" onClick={(e) => { e.stopPropagation(); onRemoveUser(); }} className="btn-icon">
                            <img src="/images/icons/remove.svg" alt="Remove" className=""/>
                        </div>
                    ) : null}
                </td>
            </tr>
        );
    }
}

export default class extends PureComponent {
    static displayName = 'AccountMemberTable';

    static propTypes = {
        selectAll: propTypes.bool,
        onSelectAll: propTypes.func,
    };

    render() {
        const { props: { children, className, isDocument, selectAll, onSelectAll, ...rest } } = this;
        return (
            <table
              className={cn('table', className)} {...rest}
            >
                <thead className="table__head">
                    <tr>
                        <th className="table__head__cell"><CheckboxInput checked={selectAll} onChange={e => onSelectAll(Utils.safeParseEventValue(e))} /></th>
                        <th className="table__head__cell">Name</th>
                        <th className="table__head__cell">Company Role</th>
                        {isDocument ? <th className="table__head__cell">Status</th> : null}
                        <th className="table__head__cell">Last seen</th>
                        <th className="table__head__cell">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        );
    }
}
