const BaseStore = require('./base/_store');
const data = require('./base/_data');
const endpoints = require('./endpoints');

const store = Object.assign({}, BaseStore, {
    id: 'user',
    getUser() {
        return bulletTrain.hasFeature('no_users') ? [] : store.model;
    },
    getDocuments() {
        return store.documents;
    },
    getInstances() {
        return store.instances;
    },
});

const controller = {
    getUser(userId) {
        store.loading();
        if (userId !== store.userId) {
            store.model = null;
        }
        store.userId = userId;
        Promise.all([
            endpoints.getUser(userId),
            endpoints.getUserDocuments(userId),
            endpoints.getUserInstances(userId),
        ])
            .then(controller.loaded)
            .catch((error) => {
                API.handleError(store, error);
            });
    },
    getUserDocuments(userId) {
        store.loading();
        if (userId !== store.userId) {
            store.model = null;
        }
        store.userId = userId;
        endpoints
            .getUserDocuments(userId)
            .then((res) => {
                store.documents = res;
                store.loaded();
            })
            .catch((error) => {
                API.handleError(store, error);
            });
    },
    getUserInstances(userId) {
        store.loading();
        if (userId !== store.userId) {
            store.model = null;
        }
        store.userId = userId;
        endpoints
            .getUserInstances(userId)
            .then((res) => {
                store.instances = res;
                store.loaded();
            })
            .catch((error) => {
                API.handleError(store, error);
            });
    },
    loaded([user, documents, instances]) {
        store.model = user;
        store.documents = _.orderBy(documents, ({ document }) => document.created_at, ['desc']);
        store.instances = _.orderBy(instances, ({ instance }) => instance.created_at, ['desc']);
        store.loaded();
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_USER:
            controller.getUser(action.userId, action.force);
            break;
        case Actions.GET_USER_DOCUMENTS:
            controller.getUserDocuments(action.userId, action.force);
            break;
        case Actions.GET_USER_INSTANCES:
            controller.getUserInstances(action.userId, action.force);
            break;
        case Actions.ON_LOGOUT:
            store.model = null;
            store.error = null;
            break;
        default:
    }
});

// AsyncStorage.getItem('projects', (err, res) => {
//     if (res) {
//         store.model = JSON.parse(res);
//     }
// });

controller.store = store;
module.exports = controller.store;
