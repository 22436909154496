const ReactDispatcher = require('flux-react-dispatcher');

const Dispatcher = new ReactDispatcher();

const dispatcher = Object.assign(Dispatcher, {
    handleViewAction(action) {
        const that = this;

        const payload = {
            source: 'VIEW_ACTION',
            action,
        };

        if (Constants.showActions) {
            API.log(payload.action.actionType, payload.action);
        }

        that.dispatch(payload);
    },

});
global.Dispatcher = dispatcher;
module.exports = dispatcher;
