import React, { Component } from 'react';
import { matchPath } from 'react-router';
import { withRouter } from 'react-router-dom';
import UserNav from './components/UserNav';
import Breadcrumb from './components/Breadcrumb';
import ProjectStore from '../common/stores/project-store';
import ProjectListStore from '../common/stores/project-list-store';
import CreateProjectModal from './components/modals/CreateProject';
import CreateCollectionModal from './components/modals/CreateCollection';
import CreateDocumentModal from './components/modals/CreateDocument';
import DeleteItemModal from './components/modals/DeleteItem';
import Switch from './components/Switch';

const disableNavbarOnRoute = (pathname) => {
    if (pathname.indexOf('/preview') !== -1 || pathname.indexOf('/register') !== -1 || pathname.indexOf('/login') !== -1 || pathname.indexOf('/document/viewer/') !== -1) {
        return true;
    }

    return false;
};

class App extends Component {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static propTypes = {
        children: propTypes.node,
        location: propTypes.object,
        presentationMode: propTypes.bool,
    };

    static displayName = 'HomePage';

    state = {};

    componentDidMount() {
        ES6Component(this);
        this.onRouteChanged(this.props.location.pathname);
        this.listenTo(ProjectStore, 'change', () => {
            this.onRouteChanged(this.props.location.pathname);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.onRouteChanged(this.props.location.pathname);
        }
    }

    onRouteChanged(pathname) {
        if (pathname === '/dashboard') {
            this.setState({ breadcrumb: null, accountId: null });
            return;
        }
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const isAdmin = AccountStore.isAdmin();
        let accountId;
        if ((hasMultipleAccounts || isAdmin) && pathname.indexOf('/account') !== -1) {
            const match = matchPath(pathname, {
                path: '/account/:accountId',
                exact: false,
                strict: false,
            });
            accountId = parseInt(_.get(match, 'params.accountId'));
            if (!accountId || !_.find(AccountStore.getUser().accounts, ({ account }) => account.id === accountId)) {
                this.context.router.history.replace('/dashboard'); // Account not found
                toast(Constants.errors.NO_ACCOUNT);
                return;
            }
            if (pathname.indexOf('/project') === -1) {
                this.setState({ breadcrumb: null, accountId });
                return;
            }
        }

        if (pathname.indexOf('/project') !== -1) {
            let match = matchPath(pathname, {
                path: `${(hasMultipleAccounts || isAdmin) ? '/account/:accountId' : ''}/project/:projectId`,
                exact: false,
                strict: false,
            });
            const projectId = parseInt(_.get(match, 'params.projectId'));
            match = matchPath(pathname, {
                path: `${(hasMultipleAccounts || isAdmin) ? '/account/:accountId' : ''}/project/:projectId/:collectionId`,
                exact: false,
                strict: false,
            });
            const collectionId = parseInt(_.get(match, 'params.collectionId'));
            if (collectionId) {
                match = matchPath(pathname, {
                    path: `${(hasMultipleAccounts || isAdmin) ? '/account/:accountId' : ''}/project/:projectId/:collectionId/document/:documentId`,
                    exact: false,
                    strict: false,
                });
            } else {
                match = matchPath(pathname, {
                    path: `${(hasMultipleAccounts || isAdmin) ? '/account/:accountId' : ''}/project/:projectId/document/:documentId`,
                    exact: false,
                    strict: false,
                });
            }
            const documentId = parseInt(_.get(match, 'params.documentId'));
            const data = ProjectStore.getData(collectionId || projectId);
            const documentName = (documentId && data && data.documents) ? _.find(data.documents, { id: documentId }).name : '';
            this.setState({ breadcrumb: data && data.breadcrumb, accountId, collectionId, documentId, documentName });
        }
    }

    onLogin = (fromRegister) => {
        const { context: { router: { history, route: { match: { params: { redirect } } } } } } = this;
        const token = Utils.fromParam().token;
        const documentId = Utils.fromParam().documentId;
        if (token) {
            // Invite token found on URL
            if (!fromRegister) {
                // User just logged in from the login page or was already logged in, attempt to join user to account
                AppActions.joinAccount(token, documentId);
            } else if (documentId) {
                // User just registered and needs to be redirected to document viewer page
                history.replace(`/document/viewer/${documentId}`);
                return;
            }
        }
        if (this.props.location.pathname === '/' || this.props.location.pathname === '/register') {
            history.replace(redirect || Constants.loginRedirect);
        }
    }

    onLogout = () => {
        const { context: { router: { history } } } = this;
        history.replace(`${Constants.logoutRedirect}${document.location.search}`);
    };

    onForbidden = () => {
        const { context: { router: { history } } } = this;
        history.replace('/dashboard');
    }

    onDocumentRedirect = (documentId) => {
        // User joined account and needs to be redirected to document viewer page
        const { context: { router: { history } } } = this;
        history.replace(`/document/viewer/${documentId}`);
    }

    toggleMobileNav= () => {
        this.setState({ navOpen: !this.state.navOpen });
    }

    onSettings = () => {
        const pathname = this.props.location.pathname;
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const isAdmin = AccountStore.isAdmin();
        const user = AccountStore.getUser();
        let accountId;
        if ((hasMultipleAccounts || isAdmin) && pathname.indexOf('/account') !== -1) {
            const match = matchPath(pathname, {
                path: '/account/:accountId',
                exact: false,
                strict: false,
            });
            accountId = parseInt(_.get(match, 'params.accountId'));
        } else {
            accountId = user.accounts[0].account.id;
        }
        const { breadcrumb } = this.state;
        if (this.state.documentId) {
            const document = ProjectStore.getDocument(this.state.documentId);
            openModal({ body: (
                <CreateDocumentModal
                  edit accountId={accountId} projectId={breadcrumb[0].id}
                  collectionId={this.state.collectionId} document={document}
                  onDelete={() => {
                      closeModal();
                      setTimeout(() => this.deleteDocument(document, accountId), 501);
                  }}
                />
            ),
            className: 'modal--fullscreen',
            disableDismissOnBackdrop: true });
        } else if (breadcrumb.length === 1) {
            const project = ProjectListStore.getProject(breadcrumb[0].id);
            openModal({ body: (
                <CreateProjectModal
                  accountId={accountId} edit
                  project={project}
                  onDelete={() => {
                      closeModal();
                      setTimeout(() => this.deleteProject(project, accountId), 501);
                  }}
                />
            ),
            className: 'modal--fullscreen',
            disableDismissOnBackdrop: true });
        } else if (breadcrumb.length > 1) {
            const collectionId = _.last(breadcrumb).id;
            const collection = ProjectStore.getCollection(collectionId);
            openModal({ body: (
                <CreateCollectionModal
                  edit accountId={accountId} projectId={breadcrumb[0].id}
                  collectionId={collectionId} collection={collection}
                  onDelete={() => {
                      closeModal();
                      setTimeout(() => this.deleteCollection(collection, accountId), 501);
                  }}
                />
            ),
            className: 'modal--fullscreen',
            disableDismissOnBackdrop: true });
        }
    }

    deleteDocument = (document, accountId) => {
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const isAdmin = AccountStore.isAdmin();
        openModal({
            body: (
                <DeleteItemModal
                  name={document.name}
                  type="document"
                  onConfirm={() => {
                      // Traverse up one level
                      const { breadcrumb } = this.state;
                      const previousLink = breadcrumb.length > 1 && breadcrumb[breadcrumb.length - 2];
                      this.context.router.history.replace(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : ''}/project/${previousLink ? `${breadcrumb[0].id}/${previousLink.id}` : breadcrumb[0].id}`, { documentDeletingId: document.id });
                      AppActions.deleteDocument(document.id);
                  }}
                />
            ),
            className: 'modal--default',
        });
    }

    deleteCollection = (collection, accountId) => {
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const isAdmin = AccountStore.isAdmin();
        openModal({
            body: (
                <DeleteItemModal
                  name={collection.name}
                  type="collection"
                  onConfirm={() => {
                      // Traverse up one level
                      const { breadcrumb } = this.state;
                      const previousLink = breadcrumb.length > 1 && breadcrumb[breadcrumb.length - 2];
                      this.context.router.history.replace(`${(hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : ''}/project/${breadcrumb.length > 2 ? `${breadcrumb[0].id}/${previousLink.id}` : previousLink.id}`);
                      AppActions.deleteCollection(collection.id);
                  }}
                />
            ),
            className: 'modal--default',
        });
    }

    deleteProject = (project, accountId) => {
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const isAdmin = AccountStore.isAdmin();
        openModal({
            body: (
                <DeleteItemModal
                  name={project.name}
                  type="project"
                  onConfirm={() => {
                      // Traverse up one level
                      this.context.router.history.replace((hasMultipleAccounts || isAdmin) ? `/account/${accountId}` : '/');
                      AppActions.deleteProject(project.id);
                  }}
                />
            ),
            className: 'modal--default',
        });
    }

    render() {
        const {
            props: { children, location: { pathname }, presentationMode },
            state: { breadcrumb, accountId, navOpen, documentId, documentName },
        } = this;
        const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
        const isAdmin = AccountStore.isAdmin();
        return (
            <AccountProvider
              onLogout={this.onLogout} onLogin={this.onLogin} onForbidden={this.onForbidden}
              onDocumentRedirect={this.onDocumentRedirect}
            >
                {({ user }, { logout }) => (
                    <div className={`${navOpen ? 'navbar--open' : ''}`}>
                        {user && !disableNavbarOnRoute(pathname) ? (
                            <nav className={presentationMode ? 'navbar navbar-fixed-top navbar-light navbar--presenting' : 'navbar navbar-fixed-top navbar-light'}>
                                <button type="button" className="navbar__button btn btn--transparent" onClick={this.toggleMobileNav}>
                                    <img src="/images/icons/menu.svg" alt="List view" />
                                </button>
                                <ul className="nav justify-content-start flex-1">
                                    {user && ((hasMultipleAccounts || isAdmin) ? accountId : user.accounts.length) ? (
                                        <Breadcrumb
                                          hasMultipleAccounts={hasMultipleAccounts}
                                          account={!hasMultipleAccounts ? user.accounts[0] : _.find(user.accounts, ({ account }) => account.id === accountId)}
                                          breadcrumb={breadcrumb ? documentId ? breadcrumb.concat([{ id: documentId, name: documentName }]) : breadcrumb : []}
                                          isAdmin={isAdmin}
                                          onSettingsClicked={this.onSettings}
                                          documentId={documentId}
                                        />
                                    ) : null}
                                </ul>
                                {!forcePresentationMode && (
                                    <Switch
                                      onChange={AppActions.togglePresentationMode} checked={presentationMode} labelText="Presentation Mode"
                                      labelSubtext={presentationMode ? 'Presenting' : 'Not Presenting'}
                                    />
                                )}
                                {user ? (
                                    <ul className="nav nav--user justify-content-end align-items-center ">
                                        <li className="nav-item dropdown">
                                            <UserNav
                                              user={user}
                                              logout={logout}
                                            />
                                        </li>
                                    </ul>
                                ) : null}
                            </nav>
                        ) : null}
                        <div>
                            {children}
                        </div>
                    </div>
                )}
            </AccountProvider>
        );
    }
}

App.propTypes = {
};

export default hot(module)(withRouter(ConfigProvider(App)));
