// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

import UploadArea from '../UploadArea';
import BasicButton from '../BasicButton';
import { VersionInput } from '../FormInput';
import DocumentTable, { FileUpdateRow } from '../DocumentTable';

export default class UpdateDocumentUpload extends PureComponent {
    static displayName = 'UpdateDocumentUpload';

    static propTypes = {
        onDrop: propTypes.func,
        onIgnore: propTypes.func,
        onIgnoreAll: propTypes.func,
        files: propTypes.array,
        isUploading: propTypes.bool,
        className: propTypes.string,
        uploadProgress: propTypes.number,
        uploadStatus: propTypes.string,
        error: propTypes.string,
        version: propTypes.string,
        onVersionChange: propTypes.func,
        uploadError: propTypes.string,
        type: propTypes.string,
        isFolderDrop: propTypes.bool,
    };

    render() {
        const { props: {
            className, onDrop, onIgnore, onIgnoreAll, next,
            files, isUploading, uploadProgress, uploadStatus, error,
            version, onVersionChange, uploadError, type, isFolderDrop, ...rest
        } } = this;
        return (
            <div className={cn('content', className)} {...rest}>
                {!files || !files.length
                    ? (
                        <div>
                            <UploadArea onDrop={onDrop} type={type} />
                            {files && !files.length ? (
                                <p className="text-center mt-5">No files have been modified.</p>
                            ) : null}
                        </div>
                    ) : (
                        <div>
                            <div className="col-md-12 ">
                                <DocumentTable
                                  onIgnore={() => onIgnoreAll(true)}
                                  onUpdate={() => onIgnoreAll(false)}
                                  isUploading={isUploading}
                                  uploadProgress={uploadProgress}
                                  uploadStatus={uploadStatus}
                                >
                                    {_.map(files, file => (
                                        <FileUpdateRow
                                          key={file.path} file={file}
                                          onIgnore={ignore => onIgnore(file, ignore)}
                                          isFolderDrop={isFolderDrop}
                                        />
                                    ))}
                                </DocumentTable>
                            </div>
                            <div className="form__footer mt-5">
                                {error ? (
                                    <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                                ) : null}
                                {uploadError ? (
                                    <Column className="text-center"><p className="text-center mb-3">{uploadError}</p></Column>
                                ) : null}
                                <Row className="justify-content-center">
                                    <BasicButton
                                      buttonText="Cancel" disabled={isUploading} className="btn--transparent"
                                      onClick={() => closeModal()}
                                    />
                                    <Row>
                                        <BasicButton buttonText="Confirm & Update" onClick={next} disabled={isUploading || !files || !files.length || !_.filter(files, file => !file.ignore).length}/>
                                        <VersionInput containerClassName="ml-4" value={version} onChange={onVersionChange} />
                                    </Row>
                                </Row>
                            </div>
                        </div>
                    ) }
            </div>
        );
    }
}
