// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { SelectInput, CheckboxInput } from './FormInput';

export class EmailInvitedRow extends PureComponent {
    static displayName = 'EmailInvitedRow';

    static propTypes = {
        invitee: propTypes.object.isRequired,
        onRemove: propTypes.func,
        onPermissionTypeChange: propTypes.func,
        permissionType: propTypes.string,
        accountSelection: propTypes.bool,
        admin: propTypes.bool,
        onAdminChange: propTypes.func,
    };

    render() {
        const { props: { className, invitee, onRemove, permissionType, onPermissionTypeChange, accountSelection, admin, onAdminChange, ...rest } } = this;
        return (
            <Row
              className={cn('invite-row', className)}
              {...rest}
            >
                <Row className="flex-1">
                    <div className="mr-3 flex-1 flex-column">
                        <p className="bold">{`${invitee.email.substr(0, invitee.email.indexOf('@'))}`}</p>
                        <p className="text-small muted">{invitee.email}</p>
                    </div>
                    {!accountSelection ? (
                        <SelectInput className="select--table mr-3 text-small" value={permissionType} onChange={onPermissionTypeChange}>
                            <option value="" className="option">Select permissions..</option>
                            <option value="OWNER" className="option">Owner</option>
                            <option value="READ" className="option">Read</option>
                            <option value="EDIT" className="option">Read / Write</option>
                        </SelectInput>
                    ) : (
                        <div className="mr-3">
                            <CheckboxInput name="Admin" checked={admin} onChange={onAdminChange} />
                        </div>
                    )}
                </Row>
                <img
                  src="/images/icons/remove-minus.svg" alt="remove" className="clickable"
                  onClick={onRemove}
                />
            </Row>
        );
    }
}

export default class MemberInviteRow extends PureComponent {
    static displayName = 'MemberInviteRow';

    static propTypes = {
        user: propTypes.object.isRequired,
        onRemove: propTypes.func,
        onPermissionTypeChange: propTypes.func,
        permissionType: propTypes.string,
        accountSelection: propTypes.bool,
        admin: propTypes.bool,
        onAdminChange: propTypes.func,
    };

    render() {
        const { props: { className, user, onRemove, onPermissionTypeChange, permissionType, accountSelection, admin, onAdminChange, ...rest } } = this;
        return (
            <Row
              className={cn('invite-row', className)}
              {...rest}
            >
                <Row className="flex-1">
                    <img alt={`${user.first_name} ${user.last_name}`} src={user.image_path || Constants.avatar} className="invite-row__avatar round mr-3"/>
                    <div className="mr-3 flex-column flex-1">
                        <p className="bold">{`${user.first_name} ${user.last_name}`}</p>
                        <p className="text-small muted">{user.email}</p>
                    </div>
                    {!accountSelection ? (
                        <SelectInput className="select--table mr-3 text-small" value={permissionType} onChange={onPermissionTypeChange}>
                            <option value="">Select permissions..</option>
                            <option value="OWNER" className="option">Owner</option>
                            <option value="READ" className="option">Read</option>
                            <option value="EDIT" className="option">Read / Write</option>
                        </SelectInput>
                    ) : (
                        <div className="mr-3">
                            <CheckboxInput name="Admin" checked={admin} onChange={onAdminChange} />
                        </div>
                    )}
                </Row>
                <img
                  src="/images/icons/remove-minus.svg" alt="remove" className="clickable"
                  onClick={onRemove}
                />
            </Row>
        );
    }
}
