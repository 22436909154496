// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Progress } from 'react-sweet-progress';
import { RadioInput } from './FormInput';
import 'react-sweet-progress/lib/style.css';

export class FileCreateRow extends PureComponent {
    static displayName = 'FileCreateRow';

    static propTypes = {
        isFolderDrop: propTypes.bool,
        file: propTypes.shape({
            path: propTypes.string,
        }),
    }

    render() {
        const { props: {
            isFolderDrop,
            file: { path },
        } } = this;
        return (
            <tr className="table__row">
                <td className="table__cell"><p>{Utils.parseDocumentFilePath(path, isFolderDrop)}</p></td>
                <td className="table__cell"><p>{Format.fileType(path)}</p></td>
            </tr>
        );
    }
}

export class FileUpdateRow extends PureComponent {
    static displayName = 'FileUpdateRow';

    static propTypes = {
        file: propTypes.shape({
            path: propTypes.string,
            ignore: propTypes.bool,
        }),
        onIgnore: propTypes.func,
        isFolderDrop: propTypes.bool,
    }

    render() {
        const { props: {
            file: { path, ignore },
            onIgnore,
            isFolderDrop,
        } } = this;
        return (
            <tr className="table__row">
                <td className="table__cell"><p>{Utils.parseDocumentFilePath(path, isFolderDrop)}</p></td>
                <td className="table__cell"><p>{Format.fileType(path)}</p></td>
                <td className="table__cell text-center"><RadioInput checked={ignore} onChange={() => onIgnore(true)} /></td>
                <td className="table__cell text-center"><RadioInput checked={!ignore} onChange={() => onIgnore(false)} /></td>

            </tr>
        );
    }
}

const successSymbol = (
    <svg version="1.1" viewBox="0 0 512 512" style={{ width: '20px', height: '20px' }}>
        <path
          fill="#FC7000"
          d="M489,255.9c0-0.2,0-0.5,0-0.7c0-1.6,0-3.2-0.1-4.7c0-0.9-0.1-1.8-0.1-2.8c0-0.9-0.1-1.8-0.1-2.7  c-0.1-1.1-0.1-2.2-0.2-3.3c0-0.7-0.1-1.4-0.1-2.1c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5-0.1-1.1-0.1-1.6c-0.1-1.3-0.3-2.6-0.4-4  c0-0.3-0.1-0.7-0.1-1C474.3,113.2,375.7,22.9,256,22.9S37.7,113.2,24.5,229.5c0,0.3-0.1,0.7-0.1,1c-0.1,1.3-0.3,2.6-0.4,4  c-0.1,0.5-0.1,1.1-0.1,1.6c-0.1,1.2-0.2,2.4-0.3,3.6c0,0.7-0.1,1.4-0.1,2.1c-0.1,1.1-0.1,2.2-0.2,3.3c0,0.9-0.1,1.8-0.1,2.7  c0,0.9-0.1,1.8-0.1,2.8c0,1.6-0.1,3.2-0.1,4.7c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0.1s0,0,0,0.1c0,0.2,0,0.5,0,0.7c0,1.6,0,3.2,0.1,4.7  c0,0.9,0.1,1.8,0.1,2.8c0,0.9,0.1,1.8,0.1,2.7c0.1,1.1,0.1,2.2,0.2,3.3c0,0.7,0.1,1.4,0.1,2.1c0.1,1.2,0.2,2.4,0.3,3.6  c0,0.5,0.1,1.1,0.1,1.6c0.1,1.3,0.3,2.6,0.4,4c0,0.3,0.1,0.7,0.1,1C37.7,398.8,136.3,489.1,256,489.1s218.3-90.3,231.5-206.5  c0-0.3,0.1-0.7,0.1-1c0.1-1.3,0.3-2.6,0.4-4c0.1-0.5,0.1-1.1,0.1-1.6c0.1-1.2,0.2-2.4,0.3-3.6c0-0.7,0.1-1.4,0.1-2.1  c0.1-1.1,0.1-2.2,0.2-3.3c0-0.9,0.1-1.8,0.1-2.7c0-0.9,0.1-1.8,0.1-2.8c0-1.6,0.1-3.2,0.1-4.7c0-0.2,0-0.5,0-0.7  C489,256,489,256,489,255.9C489,256,489,256,489,255.9z" id="XMLID_3_"
        />
        <g
          fill="none" stroke="#FFFFFF" strokeWidth={30}
          strokeMiterlimit={10}
        >
            <line
              x1="213.6" x2="369.7" y1="344.2"
              y2="188.2"
            />
            <line
              x1="233.8" x2="154.7" y1="345.2"
              y2="266.1"
            />
        </g>
    </svg>
);

export default class extends PureComponent {
    static displayName = 'TableRow';

    static propTypes = {
        onIgnore: propTypes.func,
        onUpdate: propTypes.func,
        isUploading: propTypes.bool,
        uploaded: propTypes.bool,
        className: propTypes.string,
        uploadProgress: propTypes.number,
        uploadStatus: propTypes.string,
        create: propTypes.bool,
    };

    render() {
        const { props: {
            isUploading, uploaded, children, className, onIgnore, onUpdate, uploadProgress, uploadStatus, create, ...rest
        } } = this;
        return (
            <div>
                <div className="table-container">
                    <div className="table-responsive">
                        <table
                          className={cn('table table--update', className)} {...rest}
                        >
                            <thead className="table__head">
                                <tr>
                                    <th className="table__head__cell">File name</th>
                                    <th className="table__head__cell">File Type</th>
                                    {!create ? (
                                        <React.Fragment>
                                            <th className="table__head__cell text-center"><span role="button" className="link-style" onClick={onIgnore}>Ignore</span></th>
                                            <th className="table__head__cell text-center"><span role="button" className="link-style" onClick={onUpdate}>Update</span></th>
                                        </React.Fragment>
                                    ) : null}
                                </tr>
                            </thead>
                            <tbody>
                                {children}
                                {(isUploading || uploaded) && (
                                    <tr>
                                        <td className="d-flex align-items-end justify-content-center uploaded-overlay" />
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Row className="justify-content-center">
                    {uploaded ? (
                        <img alt="Upload done" src="/images/upload-done.svg" className="uploaded-overlay__icon"/>
                    ) : isUploading ? uploadProgress !== 100 ? (
                        <Progress
                          percent={Math.round(uploadProgress)} status={uploadStatus} theme={{
                              default: {
                                  trailColor: '#ffd6b5',
                              },
                              active: {
                                  color: '#FC7000',
                                  trailColor: '#ffd6b5',
                              },
                              success: {
                                  color: '#FC7000',
                                  symbol: successSymbol,
                              },
                          }}
                        />
                    ) : (
                        <div>
                            <Loader />
                            <div>Processing please wait..</div>
                        </div>
                    ) : null}
                </Row>
            </div>
        );
    }
}
