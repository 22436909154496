// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import cn from 'classnames';

export default class UploadArea extends PureComponent {
    static displayName = 'UploadArea';

    static propTypes = {
        onDrop: propTypes.func,
        type: propTypes.string,
    };

    render() {
        const { props: { className, onDrop, type } } = this;
        return (
            <Dropzone onDrop={onDrop} accept={type === 'PDF' ? 'application/pdf' : undefined}>
                {({ getRootProps, getInputProps }) => (
                    <div className={cn('droparea', className)} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <img alt="Upload" className="droparea__icon" src="/images/icons/upload.svg"/>
                        <p className="droparea__text">
                    Drop to upload or
                            {' '}
                            <span className="link-style clickable">Browse</span>
                        </p>
                    </div>
                )}
            </Dropzone>
        );
    }
}
