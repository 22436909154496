import { Component } from 'react';
import AccountUsersStore from '../stores/account-users-store';

const TheComponent = class extends Component {
    static displayName = 'AccountUsersProvider';

    static propTypes = {
        accountId: propTypes.number,
        onError: propTypes.func,
        onAccountNotFound: propTypes.func,
        onSave: propTypes.func,
    }

    constructor(props, context) {
        super(props, context);
        const idHasChanged = (props.accountId || Project.defaultAccount) !== AccountUsersStore.accountId;
        this.state = {
            isLoadingInitial: true,
            isLoading: !AccountUsersStore.model || idHasChanged,
            isSaving: AccountUsersStore.isSaving,
            error: AccountUsersStore.error,
            users: idHasChanged ? null : AccountUsersStore.getUsers(),
        };
    }

    componentDidMount() { // Get Projects on mount, listen to store changing
        ES6Component(this);
        if (!AccountUsersStore.model || AccountUsersStore.accountId !== (this.props.accountId || Project.defaultAccount)) {
            AppActions.getUsersForAccount(this.props.accountId || Project.defaultAccount);
        }
        this.listenTo(AccountUsersStore, 'change', () => {
            this.setState({
                isLoadingInitial: AccountUsersStore.isLoading,
                isLoading: AccountUsersStore.isLoading,
                isSaving: AccountUsersStore.isSaving,
                error: AccountUsersStore.error,
                isRefreshing: AccountUsersStore.isLoading && this.state.isRefreshing,
                users: AccountUsersStore.getUsers(),
            });
        });
        this.listenTo(AccountUsersStore, 'problem', () => {
            if (this.props.onError) {
                this.props.onError(AccountUsersStore.error);
            } else {
                this.setState({
                    error: AccountUsersStore.error,
                });
            }
        });
        this.listenTo(AccountUsersStore, 'loaded', () => {
            if (!AccountUsersStore.model && this.props.onAccountNotFound) {
                this.props.onAccountNotFound();
            }
        });
        this.listenTo(AccountUsersStore, 'saved', () => {
            if (this.props.onSave) this.props.onSave();
        });
    }

    sort = (type) => { // Sort users according to user sort constants
        switch (type) {
            case 'A-Z': {

            }
        }
        this.setState({
            users: users && _.sortBy(users, () => {

            }),
        });
    };

    refresh = () => {
        this.setState({ isRefreshing: true }, () => {
            AppActions.getUsersForAccount(this.props.accountId || Project.defaultAccount, true);
        });
    };

    render() { // Render with project store state and sort function
        const { isLoading, isSaving, users, error, isRefreshing } = this.state;
        const { sort, refresh } = this;
        return (
            this.props.children({
                isLoading,
                isSaving,
                error,
                isRefreshing,
                users,
            }, { sort, refresh })
        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
