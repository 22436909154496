import React from 'react';

import data from '../../../common/stores/base/_data';
import EnterPassword from '../modals/EnterPassword';

export default hot(module)(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static displayName = 'DocumentPublicViewerPage';

  state = {
  };

  componentDidMount() {
      API.trackPage(Constants.pages.DOCUMENT_INVITE_PAGE);

      const token = _.get(this.context.router, 'route.match.params.token');
      const documentId = _.get(this.context.router, 'route.match.params.documentId');
      if (!token || !documentId) {
          document.location.href = '/';
          return;
      }

      // Get the public viewer url
      return data.post(`${Project.api}documents/${documentId}/public/${token}`)
          .then(({ viewer_url }) => {
              this.setState({ viewer_url });
          })
          .catch((e) => {
              if (e && e.status && e.status === 401) {
                  this.enterPassword();
              } else {
                  this.setState({ error: 'Sorry something went wrong.' });
              }
          });
  }

  enterPassword = () => {
      const token = _.get(this.context.router, 'route.match.params.token');
      const documentId = _.get(this.context.router, 'route.match.params.documentId');
      openModal({
          body: (
              <EnterPassword
                onOK={(password) => {
                    return data.post(`${Project.api}documents/${documentId}/public/${token}`, { password })
                        .then(({ viewer_url }) => {
                            this.setState({ viewer_url });
                        });
                }}
              />
          ),
          className: 'modal--default',
      });
  }

  render = () => {
      const { viewer_url, error } = this.state;
      return (
          <Flex>
              {error ? (
                  <Column className="text-center"><p className="text-center mt-3">{error}</p></Column>
              ) : !viewer_url ? <Loader /> : (
                  <iframe
                    src={viewer_url} title="Viewing Document"
                    height="100%" style={{ width: '100%' }}
                  />
              )}
          </Flex>
      );
  }
});
