import React from 'react';

import BasicButton from '../BasicButton';
import { TextInput, SelectInput, CheckboxInput } from '../FormInput';

export default hot(module)(class extends React.Component {
    static displayName = 'InviteUserModal';

    static propTypes = {
        inviteUser: propTypes.func,
        accountSelection: propTypes.bool,
    }

    state = {
        email: '',
        admin: false,
    }

    valid = () => {
        const { state: { email, selectedAccountId } } = this;
        return Utils.isValidEmail(email) && (this.props.accountSelection ? selectedAccountId : true);
    }

    invite = () => {
        const { state: { email, admin, selectedAccountId } } = this;
        this.props.inviteUser({ email, admin }, selectedAccountId);
        closeModal();
    }

    render = () => {
        const { state: { email, admin, isSaving, selectedAccountId }, props: { accountSelection } } = this;
        return (
            <div className="content-pane">
                <Row className="justify-content-end">
                    <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                        <img className="" src="/images/icons/remove.svg" alt="Remove" />
                    </button>
                </Row>
                <div className="col-lg-8 m-auto">
                    <div className="fullscreen-modal__content">
                        <h2 className="mb-1">Invite new member</h2>
                        <form className="form">
                            <div className="form__content">
                                <Column className="mx-0 flex-1 mb-4 mt-4 mt-sm-0">
                                    {accountSelection ? (
                                        <React.Fragment>
                                            <label className="mt-4">Company</label>
                                            <SelectInput
                                              className="full" onChange={e => this.setState({ selectedAccountId: Utils.safeParseEventValue(e) })}
                                              value={selectedAccountId}
                                            >
                                                <option value="">Select a company..</option>
                                                {_.map(AccountStore.getUser().accounts, ({ account }) => (
                                                    <option key={account.id} value={account.id}>{account.name}</option>
                                                ))}
                                            </SelectInput>
                                        </React.Fragment>
                                    ) : null}
                                    <label className="mt-4">Email</label>
                                    <TextInput
                                      placeholder="Email address" value={email}
                                      onChange={e => this.setState({ email: Utils.safeParseEventValue(e) })}
                                    />
                                    <div className="mt-4">
                                        <CheckboxInput name="Admin" checked={admin} onChange={e => this.setState({ admin: Utils.safeParseEventValue(e) })} />
                                    </div>
                                </Column>
                            </div>
                            <div className="form__footer mt-5">
                                <Row className="justify-content-center">
                                    <BasicButton
                                      buttonText="Cancel" className="btn--transparent order-1 order-sm-0" onClick={() => closeModal()}
                                      disabled={isSaving}
                                    />
                                    <BasicButton buttonText={isSaving ? 'Sending..' : 'Send'} disabled={!this.valid()} onClick={this.invite}/>
                                </Row>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
});
