/**
 * Created by andrewmoffatt on 04/03/2019.
 */

import React, { PureComponent } from 'react';
import cn from 'classnames';

export default class SwitchRegular extends PureComponent {
    static displayName = 'SwitchRegular';

    static propTypes = {
        labelText: propTypes.string,
        labelSubtext: propTypes.string,
    };

    render() {
        const { props: { containerName, className, labelText, labelSubtext, ...rest } } = this;
        return (
            <div className={containerName}>
                <input type="checkbox" className={cn('ios8-switch switch', className)} ref={c => this.checkbox = c} {...rest} />
                {labelSubtext ? (
                    <label className="mb-0" onClick={() => this.checkbox.click()}>
                        <p className="text-small">{labelText}</p>
                        <span className="text-smaller muted">{labelSubtext}</span>
                    </label>
                ) : <label className="mb-0" onClick={() => this.checkbox.click()}><p className="text-small" style={{ lineHeight: 1.7 }}>{labelText}</p></label>}
            </div>
        );
    }
}
