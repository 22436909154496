/* eslint-disable no-alert */
import React from 'react';

import Breadcrumb from '../Breadcrumb';
import FileTable, { FileRow } from '../FileTable';
import DocumentTable, { FileUpdateRow } from '../DocumentTable';
import DraftTable, { DraftUpdateRow } from '../DraftTable';
import GridToggleButton from '../GridToggleButton';
import MemberTable, { MemberRow } from '../MemberTable';
import CompanyTable, { CompanyRow } from '../CompanyTable';
import ItemMemberTable, { ItemMemberRow } from '../ItemMemberTable';
import RecentActivityTable, { ActivityRow } from '../RecentActivityTable';
import StatCard, { UsersStatCard } from '../StatCard';
import Table, { CollectionRow, DocumentRow, ProjectRow } from '../ItemTable';
import UploadArea from '../UploadArea';
import UserNav from '../UserNav';
import ProfileHero from '../ProfileHero';
import { CollectionTile, DocumentTile, ProjectTile, CompanyTile } from '../ItemTile';
import { CompanySelect, ProjectSelect, CollectionSelect, DocumentSelect } from '../PanelSelect';
import { CreateButton, UploadButton } from '../ActionButton';
import DisplayButton from '../DisplayButton';
import { ExistingMemberInviteRow, GroupInviteRow, EmailMemberInviteRow } from '../SearchMemberInviteRow';
import MemberInviteRow, { EmailInvitedRow } from '../MemberInviteRow';
import { NavigationItem, NavigationItemHeader, NavigationItemCollection, NavigationItemDocument, NavigationItemProject, NavigationItemCompany } from '../NavigationItem';
import BasicButton from '../BasicButton';
import ButtonGroup from '../ButtonGroup';
import SwitchRegular from '../Switch';
import UploadInput from '../UploadInput';
import PageController from '../PageController';
import DraftDocument from '../DraftDocument';
import { TextInput, EmailInput, PasswordInput, SelectInput, ColourInput, ColourSelectInput, TextArea, VersionInput, CheckboxInput, RadioInput } from '../FormInput';
import TableActionButton from '../TableActionButton';

import Confirmation from '../modals/Confirmation';

const exampleBreadcrumb = [
    { 'id': 2, 'name': 'Project 1', 'permission_type': 'OWNER' },
    { 'id': 7, 'name': 'Old collection', 'permission_type': 'OWNER' },
    { 'id': 19, 'name': 'TEST PDF' },
];

const exampleAccount = {
    'account': {
        'id': 7,
        'name': 'Amazon',
        'logo_url': 'https://publicate-api.s3.eu-west-2.amazonaws.com/dev/avatar-images/account/4dda0ef7-864b-43c9-b0ec-cbc3bde2ac29',
    },
    'admin': true,
};

const exampleCollection = {
    'id': 19,
    'name': 'elwell testing edit?',
    'documents': [],
    'folders': [
        {
            'id': 21,
            'name': 'OOF',
            'documents': [],
            'folders': [],
            'permission_type': 'OWNER',
        },
    ],
    'permission_type': 'OWNER',
};

const exampleProject = {
    'id': 2,
    'name': 'Project 1',
    'created_at': '2019-05-03T10:32:04.810646',
    'cover_image_url': 'https://publicate-api.s3.eu-west-2.amazonaws.com/dev/cover-images/instance/77082142-4293-4d98-9895-cd7384d1e632',
};

const exampleDocument = {
    'document': {
        'id': 20,
        'name': "Luke's Document",
        'assets': [
            {
                'id': 139,
                'size': 291155,
                'index': false,
                'file_location': 'documents/pdf/20/macbook-charger-ebay.pdf',
                'created_at': '2019-05-10T09:30:00.72',
                'updated_at': '2019-05-10T09:30:00.72',
                'file_last_modified': '2017-11-16T11:54:01',
                'content_type': 'application/pdf',
            },
        ],
        'created_at': '2019-05-10T09:29:45.842',
        'instance': {
            'id': 2,
            'name': 'Project 1',
            'created_at': '2019-05-03T10:32:04.810646',
            'cover_image_url': 'https://publicate-api.s3.eu-west-2.amazonaws.com/dev/cover-images/instance/77082142-4293-4d98-9895-cd7384d1e632',
        },
        'published': false,
        'type': 'PDF',
        'account': {
            'id': 1,
            'name': 'SSG',
            'logo_url': 'https://publicate-api.s3.eu-west-2.amazonaws.com/dev/avatar-images/account/84c26fdd-0ca0-479e-8749-bed1414e9dd4',
        },
        'published_at': null,
        'cover_image_url': 'https://publicate-api.s3.eu-west-2.amazonaws.com/dev/cover-images/document/0d3f5880-bd33-4bb0-975d-f5031d1cffc4',
        'file_size': 291155,
    },
    'permission_type': 'OWNER',
};


const exampleUser = {
    'id': 2,
    'email': 'luke@solidstategroup.com',
    'roles': [
        {
            'id': 2,
            'name': 'user',
            'description': 'A standard user that can login to the system but can only see and amend instances, accounts and documents they are assigned to',
            'display_name': 'User',
        },
    ],
    'active': true,
    'first_name': 'Luke',
    'last_name': 'Fanning',
    'accounts': [],
    'join_date': '2019-05-01T18:21:40.587',
    'avatar_url': null,
    'last_logged_in_at': null,
    'publicate_admin': false,
};

const exampleAsset = {
    'id': 137,
    'size': 1621,
    'index': false,
    'file_location': 'documents/pdf/18/test-link-2.pdf',
    'created_at': '2019-05-03T18:10:54.132',
    'updated_at': '2019-05-03T18:10:54.132',
    'file_last_modified': '2017-11-20T13:44:41.019',
    'content_type': 'application/pdf',
};

const exampleFile = {
    lastModified: 1470571354000,
    lastModifiedDate: moment(1470571354000),
    name: '20160807_130233.jpg',
    path: '20160807_130233.jpg',
    size: 5420028,
    type: 'image/jpeg',
    webkitRelativePath: '',
};

export default hot(module)(class extends React.Component {
    static contextTypes = {
        router: propTypes.object.isRequired,
    };

    static displayName = 'HomePage';

    static state = {};

    componentDidMount() {
        // API.trackPage(Constants.pages.HOME_PAGE);
    }

    confirm = (user, accountId, isAdmin) => {
        openConfirm({
            body: <Confirmation modalTitle="Are you sure you want to delete FORTIS 2016" modalText="This action cannot be undone, all files in this collection will be deleted." />,
            className: 'modal--default',
            onYes: () => {},
            onNo: () => {},
        });
    }

    render = () => (
        <div className="app-container container">
            <h2>Stat Cards</h2>
            <div className="row">
                <div className="col-md-3 mb-5">
                    <StatCard
                      title="Read only"
                      content="123"
                      footer="17 New documents created this month"
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <StatCard
                      title="Documents"
                      content="123"
                      footer="17 New documents created this month"
                      onClick={() => alert('Tile clicked')}
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <StatCard
                      title="Collections"
                      content="4"
                      footer="0 new collections created this month"
                      onClick={() => alert('Tile clicked')}
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <StatCard
                      title="Usage"
                      content="20.00GB"
                      progress={60}
                      footer="FORTIS has 80GB storage space left"
                      onClick={() => alert('Tile clicked')}
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <UsersStatCard
                      title="Members"
                      footer="Members belong to this account"
                      users={exampleAccount.users}
                      onClick={() => alert('Tile clicked')}
                    />
                </div>
            </div>
            <h2>Tiles</h2>
            <div className="row mb-5">
                <div className="col-md-3 mb-5">
                    <CompanyTile
                      account={{ ...exampleAccount, image_thumbnail: 'https://www.watg.com/wp-content/uploads/2014/10/watg-720x512.jpg' }}
                      onClick={() => alert('Comany Tile Clicked')}
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <ProjectTile
                      project={{ ...exampleProject, logo_image: 'https://www.placecage.com/c/200/300' }}
                      onClick={() => alert('Project Tile Clicked')}
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <ProjectTile
                      project={{ ...exampleProject, logo_image: 'https://www.placecage.com/gif/200/300' }}
                      onClick={() => alert('Project Tile Clicked')}
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <CollectionTile
                      collection={exampleCollection}
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <CollectionTile
                      onClick={() => alert('Collection Tile Clicked')}
                      collection={{ ...exampleCollection, name: 'Really really really really' }}
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <DocumentTile
                      onClick={() => alert('Document Tile Clicked')}
                      document={{
                          ...exampleDocument,
                          cover_image: 'https://marketplace.canva.com/MACF4izrY9s/1/0/thumbnail_large/canva-orange-and-dark-grey-listing-presentation-MACF4izrY9s.jpg',
                      }}
                    />
                </div>
                <div className="col-md-3 mb-5">
                    <DocumentTile
                      onClick={() => alert('Document Tile Clicked')}
                      document={{
                          ...exampleDocument,
                          cover_image: 'https://marketplace.canva.com/MACF4izrY9s/1/0/thumbnail_large/canva-orange-and-dark-grey-listing-presentation-MACF4izrY9s.jpg',
                      }}
                    />
                </div>
                <div className="col-md-4 mb-5">
                    <DocumentTile
                      onClick={() => alert('Document Tile Clicked')}
                      document={{
                          ...exampleDocument,
                          cover_image: 'https://www.vexels.com/media/users//3/137507/raw/6e16558b213eac147e1d9d2f0ab94689.jpg',
                      }}
                    />
                </div>
            </div>
            <h2>Tables</h2>
            <Row>
                <TableActionButton
                  labelText="Download all" labelSubtext="4 selected" src="/images/icons/download.svg"
                  alt="Download"
                />
                <TableActionButton
                  labelText="Delete all" labelSubtext="4 selected" src="/images/icons/delete.svg"
                  alt="Delete"
                />
            </Row>
            <div className="row mb-5">
                <div className="col-md-12">
                    <Table>
                        <ProjectRow project={exampleProject}/>
                        <CollectionRow collection={exampleCollection}/>
                        <DocumentRow document={exampleDocument}/>
                        <DocumentRow document={{ ...exampleDocument, permissions: 'WRITE' }}/>
                    </Table>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-12">
                    <MemberTable>
                        <MemberRow user={exampleUser}/>
                        <MemberRow user={exampleUser}/>
                        <MemberRow user={exampleUser}/>
                        <MemberRow user={exampleUser}/>
                    </MemberTable>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-12">
                    <ItemMemberTable>
                        <ItemMemberRow user={exampleUser}/>
                        <ItemMemberRow user={exampleUser}/>
                        <ItemMemberRow user={exampleUser}/>
                        <ItemMemberRow user={exampleUser}/>
                    </ItemMemberTable>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-12">
                    <FileTable>
                        <FileRow file={exampleAsset} documentType="PDF" />
                        <FileRow file={exampleAsset} documentType="PDF"/>
                        <FileRow file={exampleAsset} documentType="PDF"/>
                        <FileRow file={exampleAsset} documentType="PDF" />
                    </FileTable>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-12">
                    <CompanyTable>
                        <CompanyRow account={exampleAccount}/>
                        <CompanyRow account={exampleAccount} />
                        <CompanyRow account={exampleAccount} />
                        <CompanyRow account={exampleAccount} />
                    </CompanyTable>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-12">
                    <RecentActivityTable>
                        <ActivityRow user={exampleDocument}/>
                        <ActivityRow user={exampleDocument}/>
                        <ActivityRow user={exampleDocument}/>
                        <ActivityRow user={exampleDocument}/>
                    </RecentActivityTable>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-8">
                    <DocumentTable>
                        <FileUpdateRow file={exampleFile}/>
                        <FileUpdateRow file={exampleFile}/>
                        <FileUpdateRow file={exampleFile} ignore />
                        <FileUpdateRow file={exampleFile}/>
                    </DocumentTable>
                </div>
            </div>
            <h2>User Nav</h2>
            <div className="row mb-5">
                <div className="col-md-4 mb-5">
                    <UserNav
                      user={exampleUser}
                    />
                </div>
                <div className="col-md-4 mb-5">
                    <UserNav
                      user={{ ...exampleUser, image_path: null }}
                    />
                </div>
                <div className="col-md-4 mb-5">
                    <UserNav
                      user={{ ...exampleUser, image_path: null, roles: [{ display_name: 'User' }] }}
                    />
                </div>
            </div>


            <h2>Draft Document</h2>
            <DraftDocument className="mb-5"/>

            <h2>Breadcrumb</h2>
            <div className="row mb-5">
                <div className="col-md-4 mb-5">
                    <Breadcrumb
                      account={exampleAccount}
                      breadcrumb={[exampleBreadcrumb[0]]}
                    />
                </div>
                <div className="col-md-4 mb-5">
                    <Breadcrumb
                      account={exampleAccount}
                      breadcrumb={[exampleBreadcrumb[0], exampleBreadcrumb[1]]}
                    />
                </div>
                <div className="col-md-4 mb-5">
                    <Breadcrumb
                      account={exampleAccount}
                      breadcrumb={exampleBreadcrumb}
                    />
                </div>
            </div>
            <h2>Grid toggle</h2>
            <div className="row mb-5">
                <div className="col-md-4 mb-5">
                    <GridToggleButton
                      onClick={() => alert('Toggle grid')}
                      disabled={false}
                    />
                    <GridToggleButton
                      onClick={() => alert('Toggle grid')}
                    />
                </div>
            </div>
            <h2>Buttons</h2>
            <div className="row mb-5">
                <div className="col-md-2 position-relative">
                    <CreateButton
                      onClick={() => alert('Create Button Clicked')}
                      disabled={false}
                    />
                </div>
                <div className="col-md-2 position-relative">
                    <UploadButton
                      onClick={() => alert('Upload Button Clicked')}
                      disabled={false}
                    />
                </div>
                <div className="col-md-2">
                    <BasicButton
                      onClick={() => this.confirm()}
                      disabled={false}
                      buttonText="Confirm"
                    />
                </div>

                <div className="col-md-2">
                    <BasicButton
                      className="btn--transparent"
                      onClick={() => alert('Primary Button Clicked')}
                      disabled={false}
                      buttonText="Cancel"
                    />
                </div>

                <div className="col-md-2">
                    <DisplayButton buttonText="Documents" dataValue="14"/>
                </div>

                <div className="col-md-2">
                    <ButtonGroup className="button-group">
                        <button type="button" className="btn text-small d-flex"><p>Full Screen</p></button>
                        <button type="button" className="btn btn--multi btn--active d-flex">
                            <p className=" btn__text text-small">Paginated</p>
                            <span className=" btn__subtext text-smaller muted">with sidebar</span>
                        </button>
                    </ButtonGroup>
                </div>
            </div>

            <h2> Switch</h2>
            <div className="col-md-2 mb-5">
                <SwitchRegular labelText="Make Live" labelSubtext="Not currently live"/>
            </div>

            <h2>Forms</h2>
            <div className="row mb-5">
                <form>
                    <Column>
                        <Row className="mb-4">
                            <TextInput placeholder="Name..."/>
                        </Row>
                        <Row className="mb-4">
                            <PasswordInput placeholder="Name..."/>
                        </Row>
                        <Row className="mb-4">
                            <EmailInput placeholder="Name..."/>
                        </Row>
                        <Row className="mb-4">
                            <VersionInput/>
                        </Row>
                        <Row className="mb-4">
                            <RadioInput document={exampleDocument}/>
                        </Row>
                        <Row className="mb-4">
                            <SelectInput placeholder="Name..."/>
                        </Row>
                        <Row className="mb-4">
                            <ColourInput placeholder="Name..." colour="#FC7000"/>
                        </Row>
                        <Row className="mb-4">
                            <ColourSelectInput placeholder="Name..." backgroundColour="#192025">
                                <option>Dark</option>
                                <option>Light</option>
                            </ColourSelectInput>
                        </Row>
                        <Row className="mb-4">
                            <TextArea/>
                        </Row>
                        <Row className="mb-4">
                            <UploadArea/>
                        </Row>
                    </Column>
                </form>
            </div>

            <div className="row mb-5">
                <div className="col-md-4">
                    <UploadInput inputTitle="Upload cover image" helperText="Recommended dimensions (pixels): Portrait (300x400), Landscape (400:300). Must not exceed 2MB" sizeLimit={2097152} />
                </div>
            </div>

            <div className="row mb-5">
                <PageController/>
            </div>

            <h2>Navigation</h2>
            <div className="row mb-5">
                <div className="col-md-3">
                    <NavigationItemHeader navText="Projects"/>
                    <NavigationItem
                      to="/" navText="WATG" className="nav-item"
                      active
                    />
                    <NavigationItemCompany to="/" navText="Publicate"/>
                    <NavigationItem to="/" navText="Publicate"/>
                    <NavigationItem to="/" navText="DAR Group"/>
                    <NavigationItemCollection to="/" navText="Fortis 2016"/>
                    <NavigationItemProject to="/" navText="FORTIS"/>
                    <NavigationItemCollection
                      to="/" navText="Fortis 2017" active
                      className="nav-item__collection"
                    />
                    <NavigationItemDocument to="/" navText="My Document name" className="nav-item__document"/>
                    <NavigationItemDocument
                      to="/" navText="My other document name" active
                      className="nav-item__document"
                    />
                    <NavigationItemHeader navText="Manage Members" to="/" />
                </div>
            </div>

            <h2>Form Select Panels</h2>
            <div className="row mb-5">
                <div className="col-md-3">
                    <CompanySelect panelTitle="Add Company" panelText="Add a new company to the Publicate platform" active/>
                </div>
                <div className="col-md-3">
                    <ProjectSelect
                      panelTitle="Add Project" active
                      panelText="Create a project to securely share documents within your project team"
                    />
                </div>
                <div className="col-md-3">
                    <CollectionSelect
                      panelTitle="Add Collection" active
                      panelText="Organise your project documents into specific collections"
                    >
                        <option value={1}>FORTIS</option>
                        <option value={2}>Four Seasons Hotels and Resort</option>
                        <option value={3}>Porto Montenegro</option>
                        <option value={4}>Palace Park</option>
                    </CollectionSelect>
                </div>
                <div className="col-md-3">
                    <DocumentSelect
                      panelTitle="Upload Document"
                      panelText="Upload a document into your company, project or collection" active
                    >
                        <option value={1}>Fortis 2016</option>
                        <option value={1}>Fortis 2017</option>
                    </DocumentSelect>
                </div>
            </div>

            <h2>Member Management</h2>
            <div className="col-md-6 mb-5">
                <MemberInviteRow user={exampleUser} />
                <EmailInvitedRow invitee={{ email: 'whatever@example.com' }} />
                <MemberInviteRow className="invite-row--invite" user={exampleUser}/>
            </div>

            <h2>Add Member Auto-complete</h2>
            <div className="col-md-6 mb-5">
                <div className="search-dropdown">
                    <ExistingMemberInviteRow user={exampleUser} />
                    <GroupInviteRow/>
                    <EmailMemberInviteRow email="someone@example.com" />
                </div>
            </div>

            <h2>Profile hero</h2>
            <div className="mb-5">
                <ProfileHero user={exampleUser} />
            </div>


        </div>
    );
});
