/**
 * Created by andrewmoffatt on 12/03/2019.
 */
import React from 'react';

import Select from 'react-select';
import data from '../../../common/stores/base/_data';
import BasicButton from '../BasicButton';
import ButtonGroup from '../ButtonGroup';
import UploadInput from '../UploadInput';
import PageController from '../PageController';
import { TextInput, ColourInput, TextArea, VersionInput, SelectInput, CheckboxInput, SearchableSelectInput } from '../FormInput';
import InviteUsers from '../InviteUsers';
import CreateDocumentUpload from './CreateDocumentUpload';
import CreateDocumentUploaded from './CreateDocumentUploaded';
import SwitchRegular from '../Switch';
import TableActionButton from '../TableActionButton';
import { ProjectSelect } from '../PanelSelect';

const STEPS = 4;

const documentTypeOptions = [{ value: 'PDF', label: 'PDF' }, { value: 'HYPE', label: 'Hype Document' }];

export default hot(module)(class extends React.Component {
    static displayName = 'CreateDocumentModal';

    static propTypes = {
        accountId: propTypes.number,
        projectId: propTypes.number.isRequired,
        collectionId: propTypes.number,
        edit: propTypes.bool,
        document: propTypes.object,
        onDelete: propTypes.func,
    };

    state = {
        name: '',
        description: '',
        settings: {
            background_colour: '#192025',
            full_screen: true,
            scale_to_fit: false,
            page_orientation: 'portrait',
        },
        version: '',
        step: 1,
        downloadable: false,
    };

    componentWillMount() {
        if (this.props.edit) {
            const state = Object.assign({}, this.state, this.props.document, { type: this.props.document.document_type });
            if (!state.settings) state.settings = this.state.settings;
            this.setState(state);
        }
    }

    edit = () => {
        AppActions.editDocument(this.props.document.id, this.state);
    }

    changed = () => {
        let changed = false;
        const { props: { document }, state: { name, type, description, hidden, coverImage, cover_image_url, downloadable, settings: { background_colour, full_screen, page_orientation } } } = this;
        if (name !== document.name || type !== document.document_type || description !== document.description || hidden !== document.hidden || coverImage || document.cover_image_url !== cover_image_url || document.downloadable !== downloadable
            || _.get(document, 'settings.background_colour') !== background_colour || _.get(document, 'settings.full_screen') !== full_screen || _.get(document, 'settings.page_orientation') !== page_orientation) {
            changed = true;
        }

        return changed;
    }

    isStep1Valid = () => {
        const { name, type, settings: { background_colour } } = this.state;
        return name && type && Utils.isValidHexColour(background_colour);
    }

    step1 = () => {
        const { props: { collectionId, projectId } } = this;
        AppActions.createDocument(collectionId || projectId, this.state);
    }

    onSave = (documentId) => {
        if (this.props.edit) {
            closeModal();
            return;
        }
        if (!this.state.documentId) {
            this.setState({ step: this.state.step + 1, documentId });
            return;
        }

        if (!AccountStore.isAdmin()) {
            closeModal();
            return;
        }
        this.setState({ step: this.state.step + 1 });
    }

    onDrop = (files) => {
        const isFolderDrop = Utils.isFolderDrop(files);
        this.setState({ files, isFolderDrop });
    }

    onPublish = () => {
        AppActions.publishDocument(this.state.documentId);
    }

    onSkipPublish = () => {
        closeModal();
    }

    onDocumentTypeChanged = (e) => {
        const type = Utils.safeParseEventValue(e).value;
        if (type === 'PDF') {
            // Force paginated for PDF's
            this.setState({ type, settings: Object.assign({}, this.state.settings, { full_screen: false }) });
        } else {
            this.setState({ type });
        }
    }

    upload = () => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${Project.api}documents/${this.state.documentId}/assets`, true);
        xhr.setRequestHeader('Authorization', `Bearer ${data.token}`);

        xhr.upload.onprogress = ({ loaded, total }) => {
            this.setState({ uploadProgress: loaded / total * 100 });
        };
        xhr.upload.onloadstart = () => {
            this.setState({ isUploading: true });
            this.forceUpdate();
        };
        xhr.upload.onerror = (uploadError) => {
            this.setState({ uploadError, isUploading: false });
        };
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200 || xhr.status === 201) {
                    this.setState({ uploadProgress: 100, uploadStatus: 'success' });
                    // Refresh project to get document file size
                    AppActions.getProject(this.props.projectId, null, true);
                    setTimeout(() => {
                        this.setState({ step: this.state.step + 1 });
                    }, 500);
                } else {
                    let uploadError;
                    if (xhr.status === 413) { // entity request too large
                        uploadError = 'One or more files were too large to upload. Contact a system administrator.';
                    } else {
                        try {
                            uploadError = JSON.parse(xhr.responseText);
                            uploadError = (uploadError && uploadError.error) || xhr.responseText || 'We could not complete your request';
                        } catch (e) {
                            uploadError = xhr.responseText || 'We could not complete your request';
                        }
                    }

                    this.setState({ uploadError, isUploading: false });
                }
            }
        };

        const formData = new FormData();
        const metadata = [];
        const isFolderDrop = Utils.isFolderDrop(this.state.files);
        _.each(this.state.files, (file) => {
            formData.append('files', file);
            metadata.push({
                last_modified: moment(file.lastModified).utc().toISOString(),
                path: Utils.parseDocumentFilePath(file.path, isFolderDrop),
            });
        });
        formData.append('metadata', JSON.stringify(metadata));

        xhr.send(formData);
    }

    setSetting = (setting) => {
        this.setState({ settings: Object.assign({}, this.state.settings, setting) });
    }

    renderStep = (step, isSaving, error) => {
        const { state: { files, type, isUploading, uploadProgress, uploadStatus, uploadError, documentId, isFolderDrop } } = this;
        switch (step) {
            case 1:
                return this.renderStep1(isSaving, error);
            case 2:
                return (
                    <CreateDocumentUpload
                      className="form__content" next={this.upload} onDrop={this.onDrop}
                      files={files} isUploading={isUploading} uploadProgress={uploadProgress}
                      uploadStatus={uploadStatus} error={error} uploadError={uploadError}
                      type={type} isFolderDrop={isFolderDrop}
                    />
                );
            case 3:
                return (
                    <CreateDocumentUploaded
                      className="form__content" onPublish={this.onPublish} onSkipPublish={this.onSkipPublish}
                      files={files} isPublishing={isSaving} error={error}
                    />
                );
            case 4:
                return <InviteUsers accountId={this.props.accountId} itemId={documentId} isDocument />;
            default:
                return null;
        }
    }

    renderStep1 = (isSaving, error) => {
        const owner = AccountStore.getUser();
        const {
            state: { name, type, description, settings: { background_colour, full_screen, scale_to_fit, page_orientation }, version, cover_image_url, downloadable, hidden },
            props: { projectId, collectionId, edit, onDelete },
        } = this;
        return (
            <form className="form">
                <div className="form__content">
                    <div className="flex-column flex-sm-row mx-0">
                        <Column className="mx-0 flex-1 mb-3 mb-sm-0 mt-4">
                            <label>Document Details</label>
                            <TextInput placeholder="Document Name..." value={name} onChange={e => this.setState({ name: Utils.safeParseEventValue(e) })}/>
                        </Column>
                        {/* <Column>
                            <SwitchRegular
                              containerName="mb-3 mb-sm-0" onChange={e => this.setState({ hidden: Utils.safeParseEventValue(e) })}
                              checked={hidden} labelText={hidden ? 'Hidden' : 'Visible'}
                            />
                            <p className="text-smaller muted mt-2">Determines whether the document is available at all on the Publicate app.</p>
                        </Column> */}
                    </div>
                    <div className="pt-4 mb-4">
                        <ButtonGroup >
                            <button type="button" className={`btn btn--multi d-flex${!page_orientation || page_orientation === 'portrait' ? ' btn--active' : ''}`} onClick={() => this.setSetting({ page_orientation: 'portrait' })}>
                                <p className=" btn__text text-small">Portrait</p>
                            </button>
                            <button type="button" className={`btn text-small d-flex${page_orientation === 'landscape' ? ' btn--active' : ''}`} onClick={() => this.setSetting({ page_orientation: 'landscape' })}><p>Landscape</p></button>
                        </ButtonGroup>
                    </div>
                    <div className="mt-4 mb-4">
                        {!edit ? <TextInput readOnly value={`${owner.first_name} ${owner.last_name}`} className="full"/> : (
                            <ItemUsersProvider itemId={projectId}>
                                {({ isLoading: usersLoading, users, error: usersError }) => {
                                    if (usersError) return null;
                                    if (usersLoading || !users) return <Loader />;
                                    return (
                                        <SearchableSelectInput placeholder={`${owner.first_name} ${owner.last_name}`} onChange={e => this.setState({ name: Utils.safeParseEventValue(e) })}>
                                            {_.map(users, ({ user }) => ({ value: user.id, label: `${user.first_name} ${user.last_name}` }))}
                                        </SearchableSelectInput>
                                    );
                                }}
                            </ItemUsersProvider>
                        )}
                    </div>
                    <div className="mb-4">
                        <TextArea
                          placeholder="Document Description..." value={description} onChange={e => this.setState({ description: Utils.safeParseEventValue(e) })}
                          className="full"
                        />
                    </div>
                    <div className="mb-4">
                        <CheckboxInput name="Downloadable" checked={downloadable} onChange={e => this.setState({ downloadable: Utils.safeParseEventValue(e) })} />
                    </div>

                    <div className="mb-4">
                        <Select
                          placeholder="Select document type" className="input full" onChange={this.onDocumentTypeChanged}
                          styles={Constants.reactSelectStyles}
                          options={documentTypeOptions}
                          defaultValue={this.props.edit ? { value: type, label: _.find(documentTypeOptions, option => option.value === type).label } : undefined}
                          isDisabled={this.props.edit}
                        />
                    </div>

                    {type !== 'PDF' && (
                        <div className="mb-4">
                            <ButtonGroup >
                                <button type="button" className={`btn text-small d-flex${full_screen ? ' btn--active' : ''}`} onClick={() => this.setSetting({ full_screen: true })}><p>Full Screen</p></button>
                                <button type="button" className={`btn btn--multi d-flex${!full_screen ? ' btn--active' : ''}`} onClick={() => this.setSetting({ full_screen: false })}>
                                    <p className=" btn__text text-small">Paginated</p>
                                    <span className=" btn__subtext text-smaller muted">with sidebar</span>
                                </button>
                            </ButtonGroup>
                        </div>
                    )}

                    {type === 'HYPE' && !edit && (
                        <Column>
                            <SwitchRegular
                              containerName="mb-3 mb-sm-0" onChange={e => this.setSetting({ scale_to_fit: Utils.safeParseEventValue(e) })}
                              checked={scale_to_fit} labelText={scale_to_fit ? 'Scale To Fit' : 'Not Scaled To Fit'}
                            />
                            <p className="text-smaller muted mt-2">Determines whether the document is scaled to fit on mobile, tablet and desktop.</p>
                        </Column>
                    )}

                    <div className="pt-4 mb-4">
                        <label>Document Design</label>
                        <UploadInput
                          inputTitle="Upload cover image" helperText="Recommended dimensions (pixels): Portrait (300:400), Landscape (400:300). Must not exceed 2MB" sizeLimit={2097152}
                          onChange={file => this.setState({ coverImage: file })} existingCoverImage={cover_image_url}
                          onRemoveExistingCoverImage={() => this.setState({ cover_image_url: null })}
                        />
                    </div>
                    <Column className="mb-4 mx-0">
                        <ColourInput
                          colour={background_colour} onChange={colour => this.setSetting({ background_colour: colour.hex })}
                          onChangeText={e => this.setSetting({ background_colour: Utils.safeParseEventValue(e) })}
                          popoverClassName="popover-top"
                        />
                        <p className="text-smaller muted mt-2">Select a colour that identifies the brand or document.  This will be applied for the users  consuming content on the tablet app.</p>
                    </Column>
                </div>

                {edit ? (
                    <TableActionButton
                      labelText="Delete" labelSubtext="this document" src="/images/icons/trash--red.svg"
                      alt="Delete" onClick={onDelete} className="mt-5 mr-4 btn--negative"
                    />
                ) : null}

                <div className="form__footer mt-5">
                    {error ? (
                        <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                    ) : null}
                    <Row className="justify-content-center">
                        <BasicButton buttonText="Cancel" className="btn--transparent flex-grow-1 flex-sm-grow-0 order-1 order-sm-0 order-1 order-sm-0" onClick={() => closeModal()} />
                        <Row>
                            {edit ? (
                                <BasicButton
                                  buttonText={isSaving ? 'Saving...' : 'Save Changes'} className=" flex-grow-1 flex-sm-grow-0" disabled={!this.changed() || isSaving}
                                  onClick={this.edit}
                                />
                            ) : (
                                <BasicButton
                                  buttonText={isSaving ? 'Creating...' : 'Create'} className=" " disabled={!this.isStep1Valid() || isSaving}
                                  onClick={this.step1}
                                />
                            )}
                            <VersionInput containerClassName="ml-4" value={version} onChange={e => this.setState({ version: Utils.safeParseEventValue(e) })} />
                        </Row>
                    </Row>
                </div>
            </form>
        );
    }

    render = () => {
        const { props: { projectId, collectionId, edit }, state: { step } } = this;
        return (
            <div className="fullscreen--modal content-pane">
                <ProjectProvider projectId={projectId} id={collectionId || projectId} onSave={this.onSave}>
                    {({ isSaving, error }) => (
                        <React.Fragment>
                            <Row className="justify-content-end">
                                <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                                    <img className="" src="/images/icons/remove.svg" alt="Remove" />
                                </button>
                            </Row>
                            <div className="col-lg-8 m-auto">
                                <div className="fullscreen-modal__content">
                                    <Row>
                                        <h2 className="flex-1 mb-1 mb-sm-5 mt-4 mt-sm-0">{`${edit ? 'Edit' : 'Create'} Document`}</h2>
                                        {!edit ? <PageController pageCount={STEPS} activePageIndex={step - 1}/> : null}
                                    </Row>
                                    {this.renderStep(step, isSaving, error)}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </ProjectProvider>
            </div>
        );
    }
});
