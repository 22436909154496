import React from 'react';
import MemberTable, { MemberRow } from '../MemberTable';
import AccountMemberTable, { AccountMemberRow } from '../AccountMemberTable';
import ItemMemberTable, { ItemMemberRow } from '../ItemMemberTable';
import InviteUsers from '../InviteUsers';
import InviteEmailToAccountModal from '../modals/InviteEmailToAccount';
import TableActionButton from '../TableActionButton';

export default hot(module)(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static propTypes = {
      accountId: propTypes.string,
      projectId: propTypes.string,
      collectionId: propTypes.string,
      documentId: propTypes.string,
  }

  static displayName = 'ManageMembersPage';

  state = {
      viewType: window.viewType,
      usersSelected: [],
      selectAll: false,
  };

  componentDidMount() {
      API.trackPage(Constants.pages.MANAGE_MEMBERS_PAGE);
  }

  onError = (error) => {
      toast(error);
  }

  onAccountNotFound = () => {
      this.context.router.history.replace('/dashboard');
      toast(Constants.errors.NO_USERS_FOR_ACCOUNT);
  }

  onDocumentNotFound = () => {
      this.context.router.history.replace('/dashboard');
      toast(Constants.errors.NO_USERS_FOR_DOCUMENT);
  }

  onCollectionNotFound = () => {
      this.context.router.history.replace('/dashboard');
      toast(Constants.errors.NO_USERS_FOR_COLLECTION);
  }

  onProjectNotFound = () => {
      this.context.router.history.replace('/dashboard');
      toast(Constants.errors.NO_USERS_FOR_PROJECT);
  }

  inviteUsers = (props) => {
      openModal({
          body: (
              <div className="content-pane">
                  <Row className="justify-content-end">
                      <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                          <img className="" src="/images/icons/remove.svg" alt="Remove"/>
                      </button>
                  </Row>
                  <div className="col-lg-8 m-auto">
                      <div className="fullscreen-modal__content">
                          <h2 className="mb-1 mt-4 mt-sm-0">Invite new member</h2>
                          <InviteUsers {...props} cancelButton/>
                      </div>
                  </div>
              </div>
          ),
          className: 'modal--fullscreen',
          disableDismissOnBackdrop: true,
      });
  }

  inviteEmailToAccount = (props) => {
      openModal({
          body: <InviteEmailToAccountModal {...props} />,
          className: 'modal--fullscreen',
          disableDismissOnBackdrop: true,
      });
  }

  onChangePermissions = (type, id, permissionType, userId, isDocument) => {
      switch (type) {
          case 'account':
              AppActions.updateAccountPermissionsForUser(id, userId, permissionType);
              break;
          case 'item':
              AppActions.updateItemPermissionsForUser(id, userId, permissionType, isDocument);
              break;
          default:
      }
  }

  onRemovePermissions = (type, id, user, isDocument, isCollection) => {
      openConfirm({
          body: <Confirmation modalTitle="Remove user?" modalText={`Are you sure you want to remove ${user.first_name} ${user.last_name} from this ${type === 'account' ? 'account' : isDocument ? 'document' : isCollection ? 'collection' : 'project'}?`} />,
          className: 'modal--default',
          onYes: () => {
              switch (type) {
                  case 'account':
                      AppActions.removeAccountUsers(id, [user.id]);
                      break;
                  case 'item':
                      AppActions.removeItemPermissionsForUsers(id, [user.id], isDocument);
                      break;
                  default:
              }
          },
      });
  }

  deactivateUser = (user) => {
      openConfirm({
          body: <Confirmation modalTitle="De-activate user?" modalText={`Are you sure you want to de-activate user ${user.first_name} ${user.last_name}?`} />,
          className: 'modal--default',
          onYes: () => {
              AppActions.deactivateUsers([user.id]);
          },
      });
  }

  activateUser = (user) => {
      openConfirm({
          body: <Confirmation modalTitle="Activate user?" modalText={`Are you sure you want to re-activate user ${user.first_name} ${user.last_name}?`} />,
          className: 'modal--default',
          onYes: () => {
              AppActions.activateUsers([user.id]);
          },
      });
  }

  onDeactivateUsers = () => {
      const { state: { usersSelected } } = this;
      openConfirm({
          body: <Confirmation modalTitle="De-activate users?" modalText="Are you sure you want to de-activate the selected user accounts?" />,
          className: 'modal--default',
          yesText: `De-activate (${usersSelected.length} ${usersSelected.length > 1 ? 'users' : 'user'})`,
          onYes: () => {
              this.setState({ usersSelected: [] });
              AppActions.deactivateUsers(usersSelected);
          },
      });
  }

  canDeactivateSelectedUsers = (users) => {
      const { state: { usersSelected } } = this;
      if (usersSelected.indexOf(AccountStore.getUser().id) !== -1) return false;

      return _.every(usersSelected, id => _.find(users, { id }).active);
  }

  onSelectAll = (checked, users, allUsers) => {
      this.setState({ selectAll: checked, usersSelected: checked ? _.map(users, allUsers ? 'id' : ({ user }) => user.id) : [] });
  }

  onUserSelected = (id, checked) => {
      const { usersSelected, selectAll } = this.state;
      if (checked) {
          if (usersSelected.indexOf(id) === -1) {
              usersSelected.push(id);
              this.setState({ usersSelected });
          }
      } else {
          const index = usersSelected.indexOf(id);
          if (index !== -1) {
              usersSelected.splice(index, 1);
              this.setState({ usersSelected, selectAll: !usersSelected.length ? false : selectAll });
          }
      }
  }

  changeRole = (user, e) => {
      const name = Utils.safeParseEventValue(e);
      if (name === 'admin') {
          openConfirm({
              body: <Confirmation modalTitle="Confirm Role Change" modalText={`Are you sure you want to promote user ${user.first_name} ${user.last_name} to an admin?`} />,
              className: 'modal--default',
              onYes: () => {
                  AppActions.setUserRole(user.id, name);
              },
          });
      } else {
          AppActions.setUserRole(user.id, name);
      }
  }

  onRemoveSelectedPermissions = (type, id, isDocument, isCollection) => {
      const { state: { usersSelected } } = this;
      openConfirm({
          body: <Confirmation modalTitle="Remove users?" modalText={`Are you sure you want to remove these users from this ${type === 'account' ? 'account' : isDocument ? 'document' : isCollection ? 'collection' : 'project'}?`} />,
          className: 'modal--default',
          yesText: `Remove (${usersSelected.length} users)`,
          onYes: () => {
              this.setState({ usersSelected: [] });
              switch (type) {
                  case 'account':
                      AppActions.removeAccountUsers(id, usersSelected);
                      break;
                  case 'item':
                      AppActions.removeItemPermissionsForUsers(id, usersSelected, isDocument);
                      break;
                  default:
              }
          },
      });
  }

  renderItemUsers = (documentId, collectionId, projectId) => {
      const itemId = parseInt(documentId) || parseInt(collectionId) || parseInt(projectId);
      const accountId = AccountStore.hasMultipleAccounts() || AccountStore.isAdmin() ? parseInt(this.props.accountId) : AccountStore.getUser().accounts[0].id;
      return (
          <ItemUsersProvider
            itemId={itemId}
            isDocument={!!documentId}
            onError={this.onError}
            onItemNotFound={documentId ? this.onDocumentNotFound : collectionId ? this.onCollectionNotFound : this.onProjectNotFound}
          >
              {({ isLoading, users }) => {
                  if (isLoading || !users) return <Loader />;
                  const { usersSelected, selectAll } = this.state;
                  return (
                      <React.Fragment>
                          <Row className="align-items-end">
                              <h2 className="mt-5">Manage members</h2>
                              <div className="btn-list ml-0 mb-3 ml-sm-5">
                                  <button type="button" className="btn btn--active btn--short px-4">Members</button>
                                  <button type="button" className="btn btn--short px-4 inactive-feature">Groups</button>
                              </div>

                              <div className="ml-auto">
                                  {usersSelected.length && usersSelected.indexOf(AccountStore.getUser().id) === -1 ? (
                                      <React.Fragment>
                                          <TableActionButton
                                            labelText="Remove users" labelSubtext={`${usersSelected.length} selected`} src="/images/icons/delete.svg"
                                            alt="Remove users" onClick={() => this.onRemoveSelectedPermissions('item', itemId, !!documentId, !!collectionId)}
                                          />
                                      </React.Fragment>
                                  ) : null}
                              </div>
                          </Row>
                          <button
                            type="button" className="btn--action round"
                            onClick={() => this.inviteUsers({ accountId, itemId, isDocument: !!documentId })}
                          >
                              <img src="/images/icons/create-white.svg" alt="Add" />
                          </button>
                          <div className="row mb-5">
                              <div className="col-md-12">
                                  <ItemMemberTable selectAll={selectAll} isDocument={!!documentId} onSelectAll={checked => this.onSelectAll(checked, users)}>
                                      {_.map(users, ({ user, permission_type }) => (
                                          <ItemMemberRow
                                            key={user.id} user={user} isDocument={!!documentId}
                                            permissionType={permission_type}
                                            selected={usersSelected.indexOf(user.id) !== -1}
                                            onUserSelected={this.onUserSelected}
                                            onClick={() => this.context.router.history.push(`/profile/${user.id}`)}
                                            onChangePermissions={e => this.onChangePermissions('item', itemId, Utils.safeParseEventValue(e), user.id, !!documentId)}
                                            onRemovePermissions={() => this.onRemovePermissions('item', itemId, user, !!documentId, !!collectionId)}
                                          />
                                      ))}
                                  </ItemMemberTable>
                              </div>
                          </div>
                      </React.Fragment>
                  );
              }}
          </ItemUsersProvider>
      );
  }

  renderAccountUsers = (accountId) => {
      accountId = parseInt(accountId);
      const { usersSelected, selectAll } = this.state;
      return (
          <AccountUsersProvider
            accountId={accountId}
            onError={this.onError}
            onAccountNotFound={this.onAccountNotFound}
          >
              {({ isLoading, users }) => {
                  if (isLoading || !users) return <Loader />;
                  return (
                      <React.Fragment>
                          <Row className="align-items-end">
                              <h2 className="mt-5">Manage members</h2>
                              <div className="btn-list ml-0 mb-3 ml-sm-5">
                                  <button className="btn btn--active btn--short px-4">Members</button>
                                  <button className="btn btn--short px-4 inactive-feature">Groups</button>
                              </div>

                              <div className="ml-auto">
                                  {usersSelected.length && usersSelected.indexOf(AccountStore.getUser().id) === -1 ? (
                                      <React.Fragment>
                                          <TableActionButton
                                            labelText="Remove users" labelSubtext={`${usersSelected.length} selected`} src="/images/icons/delete.svg"
                                            alt="Remove users" onClick={() => this.onRemoveSelectedPermissions('account', accountId)}
                                          />
                                      </React.Fragment>
                                  ) : null}
                              </div>
                          </Row>
                          <button type="button" className="btn--action round" onClick={() => this.inviteEmailToAccount({ inviteUser: details => AppActions.inviteUsersToAccount(accountId, [details]) })}>
                              <img src="/images/icons/create-white.svg" alt="Add" />
                          </button>
                          <div className="row mb-5">
                              <div className="col-md-12">
                                  <AccountMemberTable selectAll={selectAll} onSelectAll={checked => this.onSelectAll(checked, users)}>
                                      {_.map(users, user => (
                                          <AccountMemberRow
                                            key={user.id} user={user}
                                            permissionType=""
                                            selected={usersSelected.indexOf(user.id) !== -1}
                                            onUserSelected={this.onUserSelected}
                                            onClick={() => this.context.router.history.push(`/profile/${user.id}`)}
                                            onChangeCompanyRole={() => AppActions.toggleUserAdminStatus(accountId, user.id)}
                                            onRemoveUser={() => this.onRemovePermissions('account', accountId, user)}
                                            accountId={accountId}
                                          />
                                      ))}
                                  </AccountMemberTable>
                              </div>
                          </div>
                      </React.Fragment>
                  );
              }}
          </AccountUsersProvider>
      );
  }

  renderAllUsers = () => (
      <UsersProvider
        onError={this.onError}
      >
          {({ isLoading, users }) => {
              if (isLoading || !users) return <Loader />;
              const { usersSelected, selectAll } = this.state;
              // TODO invite user should offer account selection?
              return (
                  <React.Fragment>
                      <Row className="align-items-end">
                          <h2 className="mt-5">Manage members</h2>
                          <div className="btn-list ml-0 mb-3 ml-sm-5">
                              <button type="button" className="btn btn--active btn--short px-4">Members</button>
                              <button type="button" className="btn btn--short px-4">Groups</button>
                          </div>

                          <div className="ml-auto">
                              {usersSelected.length && this.canDeactivateSelectedUsers(users) ? (
                                  <React.Fragment>
                                      <TableActionButton
                                        labelText="De-activate users" labelSubtext={`${usersSelected.length} selected`} src="/images/icons/delete.svg"
                                        alt="De-activate users" onClick={this.onDeactivateUsers}
                                      />
                                  </React.Fragment>
                              ) : null}
                          </div>
                      </Row>
                      <button type="button" className="btn--action round" onClick={() => this.inviteUsers({ accountSelection: true })}>
                          <img src="/images/icons/create-white.svg" alt="Add" />
                      </button>
                      <div className="row mb-5">
                          <div className="col-md-12">
                              <MemberTable selectAll={selectAll} onSelectAll={checked => this.onSelectAll(checked, users, true)}>
                                  {_.map(users, user => (
                                      <MemberRow
                                        key={user.id} user={user}
                                        selected={usersSelected.indexOf(user.id) !== -1}
                                        onUserSelected={this.onUserSelected}
                                        onClick={() => this.context.router.history.push(`/profile/${user.id}`)}
                                        onDeactivate={() => this.deactivateUser(user)}
                                        onActivate={() => this.activateUser(user)}
                                        onChangeRole={e => this.changeRole(user, e)}
                                      />
                                  ))}
                              </MemberTable>
                          </div>
                      </div>
                  </React.Fragment>
              );
          }}
      </UsersProvider>
  )

  render = () => {
      const { accountId, projectId, collectionId, documentId } = this.props;
      const hasMultipleAccounts = AccountStore.hasMultipleAccounts();
      const isAdmin = AccountStore.isAdmin();
      return (
          <div className="app-container container">
              <AccountProvider>
                  {({ isLoading, user }) => {
                      return isLoading || !user ? <Loader/> : documentId || collectionId || projectId
                          ? this.renderItemUsers(documentId, collectionId, projectId)
                          : (accountId || (!hasMultipleAccounts && !isAdmin)) ? this.renderAccountUsers(accountId || user.accounts[0].id) : this.renderAllUsers();
                  }}
              </AccountProvider>
          </div>
      );
  }
});
