import React, { Component, PropTypes } from 'react';
import ProjectStore from '../stores/project-store';
import ThemeStore from '../stores/theme-store';

const TheComponent = class extends Component {
    static displayName = 'ProjectProvider';

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: !ProjectStore.model,
            allDocuments: ProjectStore.getAllDocuments(),
            isSaving: ProjectStore.isSaving,
            error: ProjectStore.error,
            data: ProjectStore.getData(this.props.id),
        };
    }

    componentDidMount() {
        ES6Component(this);
        if (!ProjectStore.model || ProjectStore.projectId !== this.props.projectId) {
            AppActions.getProject(this.props.projectId, this.props.folderId, false, this.props.isPublic);
        }

        if (this.state.data) {
            const theme = this.state.data && this.state.data.breadcrumb && this.state.data.breadcrumb[0].settings;
            ThemeStore.setTheme(theme);
            if (this.props.onThemeChange) {
                this.props.onThemeChange();
            }
        }
        if (this.props.onError) {
            this.listenTo(ProjectStore, 'problem', () => {
                this.props.onError(ProjectStore.error);
            });
        }

        this.listenTo(ProjectStore, 'loaded', () => {
            if (this.props.id !== this.props.projectId) {
                const data = ProjectStore.getData(this.props.id);
                if (!data && this.props.onCollectionNotFound) {
                    this.props.onCollectionNotFound();
                }
            }
        });

        this.listenTo(ProjectStore, 'change', () => {
            const state = {
                isLoading: ProjectStore.isLoading,
                isRefreshing: ProjectStore.isLoading && this.state.isRefreshing,
                isSaving: ProjectStore.isSaving,
                allDocuments: ProjectStore.getAllDocuments(),
                error: ProjectStore.error,
                data: ProjectStore.getData(this.props.id),
                stats: ProjectStore.stats,
            };

            if (ProjectStore.projectId == this.props.projectId) {
                const theme = state.data && state.data.breadcrumb && state.data.breadcrumb[0].settings;
                ThemeStore.setTheme(theme);
                this.props.onThemeChange && this.props.onThemeChange();
            }

            this.setState(state);
        });

        this.listenTo(ProjectStore, 'saved', () => {
            this.props.onSave && this.props.onSave(ProjectStore.savedId);
            this.refresh();
        });

        this.listenTo(ProjectStore, 'document-deleting', (id) => {
            if (this.props.onDocumentDeleting) this.props.onDocumentDeleting(id);
        });

        this.listenTo(ProjectStore, 'document-deleted', (id) => {
            if (this.props.onDocumentDeleted) this.props.onDocumentDeleted(id);
            this.refresh();
        });

        if (global.DeviceStateStore) {
            this.listenTo(global.DeviceStateStore, 'change', () => {
                if (global.DeviceStateStore.getLastSession() > Constants.sessionTimeout) {
                    this.refresh();
                }
            });
            this.listenTo(global.NetworkStore, 'change', () => {
                if (global.NetworkStore.getLastSession() > Constants.sessionTimeout) {
                    this.refresh();
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({
                data: ProjectStore.getData(this.props.id),
            });
        }
    }

    sort = (type) => {
        switch (type) {
            case 'A-Z': {

            }
        }
    };

    refresh = () => {
        this.setState({ isRefreshing: true }, () => {
            AppActions.getProject(this.props.projectId, this.props.folderId, true, this.props.isPublic);
            AppActions.getInstanceStats(this.props.id);
        });
    };

    render() {
        const { isLoading, isRefreshing, isSaving, allDocuments, data, stats, error } = this.state;
        const { sort, refresh } = this;
        return (
            this.props.children({
                isLoading,
                isSaving,
                isRefreshing,
                error,
                data,
                stats,
                allDocuments,
            }, { sort, refresh })
        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
