import AccountProvider from '../../common/providers/AccountProvider';
import AccountStore from '../../common/stores/account-store';
import ProjectsProvider from '../../common/providers/ProjectsProvider';
import ProjectProvider from '../../common/providers/ProjectProvider';
import DocumentPageProvider from '../../common/providers/DocumentPageProvider';
import AccountUsersProvider from '../../common/providers/AccountUsersProvider';
import UsersProvider from '../../common/providers/UsersProvider';
import ItemUsersProvider from '../../common/providers/ItemUsersProvider';
import UserProvider from '../../common/providers/UserProvider';
import ConfigStore from '../../common/stores/config-store';
import ConfigProvider from '../../common/providers/ConfigProvider';
import AccountHierarchyProvider from '../../common/providers/AccountHierarchyProvider';
import Confirmation from '../components/modals/Confirmation';

window.AccountProvider = AccountProvider;
window.AccountStore = AccountStore;
window.ProjectsProvider = ProjectsProvider;
window.ProjectProvider = ProjectProvider;
window.DocumentPageProvider = DocumentPageProvider;
window.AccountUsersProvider = AccountUsersProvider;
window.UsersProvider = UsersProvider;
window.ItemUsersProvider = ItemUsersProvider;
window.UserProvider = UserProvider;
window.AccountHierarchyProvider = AccountHierarchyProvider;
window.Confirmation = Confirmation;
window.ConfigStore = ConfigStore;
window.ConfigProvider = ConfigProvider;

// Useful for components used all the time within a project
window.Loader = () => (
    <div className="full-width text-center py-3">
        <img src="/images/publicate_logo_icon.png" className="loader rotating" alt="Loading" />
    </div>
);

window.Loader.displayName = 'Loader';
window.Tooltip = require('../components/Toolip');
