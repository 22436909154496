// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { CheckboxInput } from './FormInput';

export class DraftUpdateRow extends PureComponent {
    static displayName = 'DraftUpdateRow';

    render() {
        return (
            <tr className="table__row">
                <td className="table__cell">
                    <p>index.html</p>
                </td>
                {/* Permissions */}
                <td className="table__cell">
                    <p>HTML Document</p>
                </td>
                {/* Status */}
                <td className="table__cell">12 days ago</td>
                {/* Last Seen */}
                <td className="table__cell">12/01/2019</td>
                {/* Actions */}
                <td className="table__cell">
                    <img src="/images/icons/download.svg" alt="Download" className="pr-4"/>
                    <img src="/images/icons/delete.svg" alt="delete" className=""/>
                </td>
            </tr>
        );
    }
}

export default class extends PureComponent {
    static displayName = 'TableRow';

    static propTypes = {};

    render() {
        const { props: { children, className, ...rest } } = this;
        return (
            <div className="table-container">
                <div className="table-responsive">
                    <table
                        className={cn('table', className)} {...rest}
                    >
                        <thead className="table__head">
                        <tr>
                            <th className="table__head__cell">File name</th>
                            <th className="table__head__cell">File type</th>
                            <th className="table__head__cell">Last updated</th>
                            <th className="table__head__cell">Created</th>
                            <th className="table__head__cell">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {children}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
