/**
 * Created by andrewmoffatt on 12/03/2019.
 */
import React from 'react';

import { TextInput } from '../FormInput';
import BasicButton from '../BasicButton';
import PageController from '../PageController';
import InviteUsers from '../InviteUsers';
import TableActionButton from '../TableActionButton';
import UploadInput from '../UploadInput';

export default hot(module)(class extends React.Component {
    static displayName = 'EditProfile';

    static propTypes = {
    }

    constructor(props) {
        super(props);
        this.state = _.cloneDeep(AccountStore.getUser());
    }

    edit = () => {
        AppActions.editProfile(this.state);
    }

    valid = () => {
        const { first_name, last_name } = this.state;
        return first_name.length && last_name.length;
    }

    changed = () => {
        let changed = false;
        const currentUser = AccountStore.getUser();
        const { state: { first_name, last_name, avatar_url, avatarImage } } = this;
        if (first_name !== currentUser.first_name || last_name !== currentUser.last_name || avatar_url !== currentUser.avatar_url || avatarImage) {
            changed = true;
        }

        return changed;
    }

    render = () => {
        const { state: { first_name, last_name, avatar_url } } = this;
        return (
            <div className="fullscreen--modal content-pane">
                <AccountProvider onSave={() => closeModal()}>
                    {({ isSaving, error }) => (
                        <React.Fragment>
                            <Row className="justify-content-end">
                                <button type="button" className="btn btn--transparent" onClick={() => closeModal()}>
                                    <img className="" src="/images/icons/remove.svg" alt="Remove" />
                                </button>
                            </Row>
                            <div className="col-lg-8 m-auto">
                                <div className="fullscreen-modal__content">
                                    <Row>
                                        <h2 className="flex-1 mb-1 mb-sm-5 mt-4 mt-sm-0 ">Edit Profile</h2>
                                    </Row>
                                    <form
                                      className="form" onSubmit={(e) => {
                                          e.preventDefault();
                                          this.edit();
                                      }}
                                    >
                                        <div className="form__content">
                                            <Column className="flex-1 mb-4 mt-4 mx-0">
                                                <label>First Name</label>
                                                <TextInput
                                                  placeholder="First name..." onChange={e => this.setState({ first_name: Utils.safeParseEventValue(e) })}
                                                  value={first_name}
                                                />
                                            </Column>
                                            <Column className="flex-1 mb-4 mt-4 mx-0">
                                                <label>Last Name</label>
                                                <TextInput
                                                  placeholder="Last name..." onChange={e => this.setState({ last_name: Utils.safeParseEventValue(e) })}
                                                  value={last_name}
                                                />
                                            </Column>
                                            <Column className="flex-1 mb-4 mt-4 mx-0">
                                                <label>Avatar</label>
                                                <UploadInput
                                                  inputTitle="Upload avatar image" helperText="Recommended dimensions (pixels): Portrait (300:400), Landscape (400:300). Must not exceed 2MB." sizeLimit={2097152}
                                                  onChange={file => this.setState({ avatarImage: file })} existingCoverImage={avatar_url}
                                                  onRemoveExistingCoverImage={() => this.setState({ avatar_url: null })}
                                                />
                                            </Column>
                                        </div>

                                        <div className="form__footer mt-5">
                                            {error ? (
                                                <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                                            ) : null}
                                            <Row className="justify-content-center">
                                                <BasicButton buttonText="Cancel" className="btn--transparent flex-grow-1 flex-sm-grow-0 order-1 order-sm-0" onClick={() => closeModal()} />
                                                <BasicButton
                                                  type="submit"
                                                  buttonText={isSaving ? 'Saving...' : 'Save Changes'} className=" flex-grow-1 flex-sm-grow-0" disabled={!this.valid() || !this.changed()}
                                                />
                                            </Row>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </AccountProvider>
            </div>
        );
    }
});
