import { render } from 'react-dom';
/* eslint-disable */

import BasicButton from '../components/BasicButton';

const Provider = class extends React.Component {
  componentDidMount() {
    if (this.props.type !== 'confirm') {
      window.closeModal = this.close;
    }
    $(ReactDOM.findDOMNode(this)).on('hidden.bs.modal', this._closed);
    $(ReactDOM.findDOMNode(this)).on('shown.bs.modal', this._shown);
    $(ReactDOM.findDOMNode(this)).modal({ backdrop: !this.props.disableDismissOnBackdrop, keyboard: true, show: true });
  }

  show() {
    $(ReactDOM.findDOMNode(this)).on('hidden.bs.modal', this.unmount);
    $(ReactDOM.findDOMNode(this)).modal('show');
    $(ReactDOM.findDOMNode(this)).style = '';
  }

  close = () => { // use when you wish to trigger closing manually
    $(ReactDOM.findDOMNode(this)).off('hidden.bs.modal', this._closed);
    $(ReactDOM.findDOMNode(this)).off('shown.bs.modal', this._shown);
    $(ReactDOM.findDOMNode(this)).modal('hide');
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(document.getElementById(this.props.type == 'confirm' ? 'confirm' : 'modal'));
      document.body.classList.remove('modal-open');
    }, 500);
  }

  _closed = () => {
    this.props.onClose && this.props.onClose();
    ReactDOM.unmountComponentAtNode(document.getElementById(this.props.type == 'confirm' ? 'confirm' : 'modal'));
    document.body.classList.remove('modal-open');
  }

  _shown() {
    this.isVisible = true;
  }

  render() {
    return this.props.children;
  }
};

Provider.propTypes = {
  children: propTypes.node,
  onClose: propTypes.func,
};

const Modal = class extends React.Component {
  header() {
    return this.props.header || '';
  }

  body() {
    return this.props.body || '';
  }

  footer() {
    return this.props.footer || '';
  }

  render() {
    return (
      <Provider ref="modal" disableDismissOnBackdrop={this.props.disableDismissOnBackdrop}>
        <div tabIndex="-1" className={'modal fade expand ' + (this.props.className ? this.props.className : '')} role="dialog" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              {this.props.header ? <div className="modal-header">{this.header()}</div> : null}
              <div className="modal-body">{this.body()}</div>
              {this.props.footer ? <div className="modal-footer">{this.footer()}</div> : null}
            </div>
          </div>
        </div>
      </Provider>
    );
  }
};

Modal.propTypes = {
  header: propTypes.node,
  body: propTypes.node.isRequired,
  footer: propTypes.node,
};

const Confirm = class extends React.Component {
  header() {
    return this.props.header || '';
  }

  body() {
    return this.props.body || '';
  }

  onNo = () => {
    this.props.onNo && this.props.onNo();
    this.refs.modal.close();
  }

  onYes = () => {
    this.props.onYes && this.props.onYes();
    this.refs.modal.close();
  }

  closed() {
    this.onNo();
  }

  footer() {
    return this.props.footer || (
      <div className="form__footer mt-2">
          <Row className="justify-content-center">
              <BasicButton buttonText={this.props.noText || "Cancel"} className="btn--transparent" onClick={this.onNo} />
              <BasicButton buttonText={this.props.yesText || "Confirm"} onClick={this.onYes} />
          </Row>
      </div>
    );
  }

  render() {
    return (
      <Provider onClose={this.props.onNo} ref="modal" type="confirm">
        <div tabIndex="-1" className={"modal fade expand " + (this.props.className ? this.props.className : '')} role="dialog" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              {this.props.header ? <div className="modal-header">{this.header()}</div> : null}
              <div className="modal-body">{this.body()}</div>
              <div className="modal-footer flex-row justify-content-center">{this.footer()}</div>
            </div>
          </div>
        </div>
      </Provider>
    );
  }
};

Confirm.propTypes = {
  header: propTypes.node,
  body: propTypes.node.isRequired,
  footer: propTypes.node,
  onYes: propTypes.func,
  onNo: propTypes.func,
  yesText: propTypes.string,
  noText: propTypes.string,
};

export const openModal = (props) => {
  render(<Modal {...props} />, document.getElementById('modal'));
};

export const openConfirm = (props) => {
  render(<Confirm {...props} />, document.getElementById('confirm'));
};
