import { Component } from 'react';
import ItemUsersStore from '../stores/item-users-store';

const TheComponent = class extends Component {
    static displayName = 'ItemUsersProvider';

    static propTypes = {
        itemId: propTypes.number,
        isDocument: propTypes.bool,
        onError: propTypes.func,
        onItemNotFound: propTypes.func,
    }

    constructor(props, context) {
        super(props, context);
        const idHasChanged = props.itemId !== ItemUsersStore.itemId;
        this.state = {
            isLoadingInitial: true,
            isLoading: !ItemUsersStore.model || idHasChanged,
            isSaving: ItemUsersStore.isSaving,
            error: ItemUsersStore.error,
            users: idHasChanged ? null : ItemUsersStore.getUsers(),
        };
    }

    componentDidMount() { // Get Projects on mount, listen to store changing
        ES6Component(this);
        if (!ItemUsersStore.model || ItemUsersStore.itemId !== this.props.itemId) {
            AppActions.getUsersForItem(this.props.itemId, this.props.isDocument);
        }
        this.listenTo(ItemUsersStore, 'change', () => {
            this.setState({
                isLoadingInitial: ItemUsersStore.isLoading,
                isLoading: ItemUsersStore.isLoading,
                isSaving: ItemUsersStore.isSaving,
                error: ItemUsersStore.error,
                isRefreshing: ItemUsersStore.isLoading && this.state.isRefreshing,
                users: ItemUsersStore.getUsers(),
            });
        });
        this.listenTo(ItemUsersStore, 'problem', () => {
            if (this.props.onError) {
                this.props.onError(ItemUsersStore.error);
            } else {
                this.setState({
                    error: ItemUsersStore.error,
                });
            }
        });
        this.listenTo(ItemUsersStore, 'loaded', () => {
            if (!ItemUsersStore.model && this.props.onItemNotFound) {
                this.props.onItemNotFound();
            }
        });
    }

    sort = (type) => { // Sort users according to user sort constants
        switch (type) {
            case 'A-Z': {

            }
        }
        this.setState({
            users: users && _.sortBy(users, () => {

            }),
        });
    };

    refresh = () => {
        this.setState({ isRefreshing: true }, () => {
            AppActions.getUsersForItem(this.props.itemId, this.props.isDocument, true);
        });
    };

    render() { // Render with project store state and sort function
        const { isLoading, isSaving, users, error, isRefreshing } = this.state;
        const { sort, refresh } = this;
        return (
            this.props.children({
                isLoading,
                isSaving,
                error,
                isRefreshing,
                users,
            }, { sort, refresh })
        );
    }
};

TheComponent.propTypes = {};

module.exports = TheComponent;
