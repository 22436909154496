// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

import UploadArea from '../UploadArea';
import BasicButton from '../BasicButton';
import DocumentTable, { FileCreateRow } from '../DocumentTable';

export default class CreateDocumentUpload extends PureComponent {
    static displayName = 'CreateDocumentUpload';

    static propTypes = {
        onDrop: propTypes.func,
        files: propTypes.array,
        isUploading: propTypes.bool,
        className: propTypes.string,
        uploadProgress: propTypes.number,
        uploadStatus: propTypes.string,
        error: propTypes.string,
        type: propTypes.string,
        isFolderDrop: propTypes.bool,
    };

    render() {
        const { props: {
            className, onDrop, next, type,
            files, isUploading, uploadProgress, uploadStatus, error, uploadError,
            isFolderDrop,
            ...rest
        } } = this;
        return (
            <div className={cn('content', className)} {...rest}>
                {!files
                    ? <UploadArea onDrop={onDrop} type={type} /> : (
                        <div>
                            <div className="col-md-8 offset-md-2">
                                <DocumentTable
                                  isUploading={isUploading}
                                  uploadProgress={uploadProgress}
                                  uploadStatus={uploadStatus}
                                  create
                                >
                                    {_.map(files, file => (
                                        <FileCreateRow
                                          key={file.path} file={file} isFolderDrop={isFolderDrop}
                                        />
                                    ))}
                                </DocumentTable>
                            </div>
                            <div className="form__footer mt-5">
                                {error ? (
                                    <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                                ) : null}
                                {uploadError ? (
                                    <Column className="text-center"><p className="text-center mb-3">{uploadError}</p></Column>
                                ) : null}
                                <Row className="justify-content-center">
                                    <BasicButton
                                      buttonText="Cancel" disabled={isUploading} className="btn--transparent order-1 order-sm-0"
                                      onClick={() => closeModal()}
                                    />
                                    <BasicButton buttonText="Confirm & Create" onClick={next} disabled={!files || !files.length || isUploading}/>
                                </Row>
                            </div>
                        </div>
                    ) }
            </div>
        );
    }
}
