const BaseStore = require('./base/_store');
const data = require('./base/_data');
const endpoints = require('./endpoints');

const store = Object.assign({}, BaseStore, {
    id: 'users',
    getUsers() {
        return bulletTrain.hasFeature('no_users') ? [] : store.model;
    },
});

const controller = {
    getUsers() {
        store.loading();
        return endpoints.getUsers()
            .then(controller.loaded)
            .catch(error => API.handleError(store, error));
    },
    loaded(res) {
        store.model = _.sortBy(res, 'join_date');
        store.loaded();
    },
    deactivateUsers(users) {
        store.saving();
        endpoints.deactivateUsers(users)
            .then(controller.getUsers)
            .then(() => {
                toast(`User account${users.length > 1 ? 's' : ''} de-activated successfully`);
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    activateUsers(users) {
        store.saving();
        endpoints.activateUsers(users)
            .then(controller.getUsers)
            .then(() => {
                toast(`User account${users.length > 1 ? 's' : ''} activated successfully`);
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    setUserRole(userId, roleName) {
        store.saving();
        endpoints.setUserRole(userId, roleName)
            .then(controller.getUsers)
            .then(() => {
                toast('User role changed successfully');
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
};

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.GET_USERS:
            controller.getUsers(action.force);
            break;
        case Actions.DEACTIVATE_USERS:
            controller.deactivateUsers(action.users);
            break;
        case Actions.ACTIVATE_USERS:
            controller.activateUsers(action.users);
            break;
        case Actions.SET_USER_ROLE:
            controller.setUserRole(action.userId, action.roleName);
            break;
        case Actions.ON_LOGOUT:
            store.model = null;
            store.error = null;
            break;
        default:
    }
});

// AsyncStorage.getItem('projects', (err, res) => {
//     if (res) {
//         store.model = JSON.parse(res);
//     }
// });

controller.store = store;
module.exports = controller.store;
