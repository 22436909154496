const BaseStore = require('./base/_store');
const data = require('./base/_data');
const endpoints = require('./endpoints');

const controller = {
    register: (details) => {
        controller.clearStorage();
        store.loading();
        endpoints.register(details)
            .then((res) => {
                data.setToken(res.token);
                if (!details.avatar) {
                    return res;
                }
                return data.onRefresh().then(() => {
                    return endpoints.upload(details.avatar, 'USER')
                        .then(({ url: avatar_url }) => {
                            return endpoints.updateUser(res.user.id, { ...res.user, avatar_url });
                        })
                        .then(({ avatar_url }) => ({ user: { ...res.user, avatar_url }, token: res.token }));
                });
            })
            .then(res => controller.setUser(res, true))
            .then((res) => {
                data.onRefresh();
            })
            .catch(error => API.handleError(store, error));
    },
    getAccounts: () => {

    },
    login: (details) => {
        store.loading();
        controller.clearStorage();
        endpoints
            .login(details)
            .then(controller.setUser)
            .then((res) => {
                data.onRefresh();
            })
            .catch(error => API.handleError(store, error));
    },

    onLogin(res) {
        store.model = res;
        controller.setToken(res && res.token);
    },

    setUser(user, fromRegister) {
        if (user) {
            store.model = user;
            if (store.model.user.accounts.length) {
                store.model.user.accounts = _.sortBy(store.model.user.accounts, ({ account }) => account.name);
            }
            data.setToken(user.token);
            AsyncStorage.setItem('user', JSON.stringify(user));
            AppActions.onLogin();
            store.loaded(fromRegister);
        } else if (!user) {
            controller.clearStorage();
            data.setToken(null);
            store.model = user;
            store.error = null;
            store.trigger('logout');
        }
        if (!user) { AppActions.onLogout(); }
    },
    clearStorage() {
        AsyncStorage.getItem('download-progress', (err, res) => {
            AsyncStorage.clear(() => {
                if (res) {
                    AsyncStorage.setItem('download-progress', res);
                }
            });
        });
    },
    uploadLogoImage: (logoImage) => {
        if (!logoImage) {
            return Promise.resolve();
        }
        return endpoints.upload(logoImage, 'ACCOUNT')
            .then(({ url }) => {
                return Promise.resolve(url);
            });
    },
    createAccount(details) {
        store.saving();
        controller.uploadLogoImage(details.logoImage)
            .then(logo_url => endpoints.createAccount({ ...details, logo_url }))
            .then((res) => {
                const accounts = _.cloneDeep(store.model.user.accounts);
                accounts.push({ account: res, admin: true });
                store.model.user.accounts = _.sortBy(accounts, ({ account }) => account.name);
                store.savedId = res.id;
                AsyncStorage.setItem('user', JSON.stringify(store.model));
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    editAccount(id, details) {
        store.saving();
        controller.uploadLogoImage(details.logoImage)
            .then(logo_url => endpoints.editAccount(id, { ...details, logo_url }))
            .then((res) => {
                const accounts = _.cloneDeep(store.model.user.accounts);
                const index = _.findIndex(accounts, ({ account }) => account.id === id);
                if (index !== -1) {
                    accounts[index].account = res;
                }
                store.model.user.accounts = _.sortBy(accounts, ({ account }) => account.name);
                store.savedId = res.id;
                AsyncStorage.setItem('user', JSON.stringify(store.model));
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
    forbidden() {
        store.trigger('forbidden');
    },
    joinAccount(token, documentId) {
        store.saving();
        endpoints.joinAccount(token)
            .then(endpoints.getCurrentUser)
            .then((res) => {
                controller.setUser({ token: data.token, user: res });
                store.saved();
                if (documentId) {
                    store.trigger('documentRedirect', documentId);
                }
            })
            .catch(error => API.handleError(store, error));
    },
    refreshUser() {
        store.loading();
        endpoints.getCurrentUser()
            .then(res => controller.setUser({ token: data.token, user: res }))
            .catch(error => API.handleError(store, error));
    },
    editProfile(user) {
        store.saving();
        const promise = user.avatarImage ? endpoints.upload(user.avatarImage, 'USER')
            .then(({ url: avatar_url }) => {
                return { ...user, avatar_url };
            }) : Promise.resolve(user);
        promise.then((res) => {
            return endpoints.updateUser(res.id, res);
        })
            .then((res) => {
                AppActions.getUser(user.id, true);
                store.model.user = Object.assign({}, store.model.user, res);
                AsyncStorage.setItem('user', JSON.stringify(store.model));
                store.saved();
            })
            .catch(error => API.handleError(store, error));
    },
};


const store = Object.assign({}, BaseStore, {
    id: 'account',
    getUser() {
        return store.model && store.model.user;
    },
    getAccounts() {
        return store.model && store.model.user.accounts;
    },
    getLogoForProject(id) {
        const acc = _.find(AccountStore.getAccounts(), a => _.find(a.instances, { id }));
        return acc && acc.image_thumbnail;
    },
    hasMultipleAccounts() {
        return !!(store.model && store.model.user && store.model.user.accounts.length > 1);
    },
    setUser(user) {
        controller.setUser(user);
    },
    isAdmin() {
        return store.model && store.model.user && store.model.user.publicate_admin;
    },
    getSettings(id) {
        const acc = _.find(AccountStore.getAccounts(), ({ account }) => account.id === id);
        return acc && acc.account.settings;
    },
});

store.dispatcherIndex = Dispatcher.register(store, (payload) => {
    const action = payload.action; // this is our action from handleViewAction

    switch (action.actionType) {
        case Actions.SET_USER:
            controller.setUser(action.user);
            break;
        case Actions.LOGOUT:
            controller.setUser(null);
            break;
        case Actions.REGISTER:
            controller.register(action.details);
            break;
        case Actions.LOGIN:
            controller.login(action.details);
            break;
        case Actions.GET_ACCOUNTS:
            controller.getAccounts();
            break;
        case Actions.CREATE_ACCOUNT:
            controller.createAccount(action.details);
            break;
        case Actions.EDIT_ACCOUNT:
            controller.editAccount(action.id, action.details);
            break;
        case Actions.FORBIDDEN:
            controller.forbidden();
            break;
        case Actions.JOIN_ACCOUNT:
            controller.joinAccount(action.token, action.documentId);
            break;
        case Actions.EDIT_PROFILE:
            controller.editProfile(action.user);
            break;
        case Actions.REFRESH_USER:
            controller.refreshUser();
            break;
        default:
    }
});

controller.store = store;
module.exports = controller.store;
