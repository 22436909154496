import React from 'react';
import BasicButton, { TabButton } from '../BasicButton';

import UploadInput from '../UploadInput';

export default hot(module)(class extends React.Component {
  static contextTypes = {
      router: propTypes.object.isRequired,
  };

  static displayName = 'RegistrationPage';

  state = {
      email: '',
      avatar: '',
      first_name: '',
      last_name: '',
      password: '',
      repeatPassword: '',
  };

  componentDidMount() {
      API.trackPage(Constants.pages.REGISTRATION_PAGE);

      const { token, email } = Utils.fromParam();
      if (!token) {
          this.context.router.history.replace('/');
      } else {
          this.setState({ token, email: email || '' });
      }
  }

  canRegister = () => {
      const { email, first_name, last_name, password, repeatPassword } = this.state;
      // TODO password requirements
      return email && Utils.isValidEmail(email) && first_name && last_name && password && repeatPassword && password === repeatPassword;
  }

  render = () => {
      const { email, first_name, last_name, password, repeatPassword, token, avatar } = this.state;
      return (
          <div className="app-container centered-container">
              <div className="form-container">
                  <div className="text-center">
                      <img src="/images/publicate_logo_large.png" alt="Publicate" className="form__image"/>
                  </div>
                  <AccountProvider>
                      {({ isLoading, error }, { register, clearError }) => (
                          <div>
                              <h2 className="text-center mb-5">Register</h2>
                              <form onSubmit={(e) => {
                                  e.preventDefault();
                                  clearError();
                                  register({ email, first_name, last_name, password, invite_token: token, avatar }, true);
                              }}
                              >
                                  <label className="mb-0">Email</label>
                                  <Input
                                    className="mb-3" name="email" type="text"
                                    placeholder="Email address..."
                                    value={email}
                                    onChange={e => this.setState({ email: Utils.safeParseEventValue(e) })}
                                  />
                                  <label className="mb-0">First name</label>
                                  <Input
                                    className="mb-3" name="first_name" type="text"
                                    placeholder="First name..."
                                    value={first_name}
                                    onChange={e => this.setState({ first_name: Utils.safeParseEventValue(e) })}
                                  />
                                  <label className="mb-0">Last name</label>
                                  <Input
                                    className="mb-3" name="last_name" type="text"
                                    placeholder="Last name..."
                                    value={last_name}
                                    onChange={e => this.setState({ last_name: Utils.safeParseEventValue(e) })}
                                  />
                                  <label className="mb-0">Password</label>
                                  <Input
                                    className="mb-3" name="password" type="password"
                                    placeholder="Create password..."
                                    value={password}
                                    onChange={e => this.setState({ password: Utils.safeParseEventValue(e) })}
                                  />
                                  <label className="mb-0">Repeat password</label>
                                  <Input
                                    className="mb-3" name="repeatPassword" type="password"
                                    placeholder="Repeat password..."
                                    value={repeatPassword}
                                    onChange={e => this.setState({ repeatPassword: Utils.safeParseEventValue(e) })}
                                  />
                                  <div className="mt-4 centered-container">
                                      <UploadInput
                                        name="avatar" helperText="Must not exceed 2MB" inputTitle="Upload avatar image"
                                        onChange={file => this.setState({ avatar: file })}
                                      />
                                  </div>
                                  <div className="text-center">
                                      {isLoading ? <Loader /> : (
                                          <React.Fragment>
                                              <Row>
                                                  <Button type="submit" disabled={!this.canRegister()} className="btn btn--primary mt-4 mx-auto">Register</Button>
                                              </Row>
                                              <Row className="justify-content-center mt-2">
                                                  <NavLink className="link-style" to={`/${window.location.search}`}>Already have an account? Log in</NavLink>
                                              </Row>
                                          </React.Fragment>
                                      )}
                                  </div>
                              </form>
                              {error ? <p className="mt-3 text-center">{error}</p> : null}
                          </div>
                      )}
                  </AccountProvider>
              </div>
          </div>
      );
  }
});
