/**
 * Created by andrewmoffatt on 14/03/2019.
 */
// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

import BasicButton from '../BasicButton';
import DocumentTable, { FileCreateRow } from '../DocumentTable';

export default class CreateDocumentUploaded extends PureComponent {
    static displayName = 'CreateDocumentUploaded';

    static propTypes = {
        files: propTypes.array,
        onPublish: propTypes.func,
        onSkipPublish: propTypes.func,
        isPublishing: propTypes.bool,
        error: propTypes.string,
    };

    render() {
        const { props: { className, files, onPublish, onSkipPublish, isPublishing, error, ...rest } } = this;
        return (
            <div className={cn('content', className)} {...rest}>
                <div className="col-md-8 offset-md-2 px-0">
                    <p className="text-left mb-4 mt-4 mt-sm-0">
Great news! We've uploaded
                        {' '}
                        {files.length}
                        {' '}
                        {files.length > 1 ? 'files' : 'file'}
                        {' '}
successfully.
                    </p>
                    <DocumentTable uploaded>
                        {_.map(files, file => (
                            <FileCreateRow key={file.path} file={file} />
                        ))}
                    </DocumentTable>
                    <p className="text-center mt-5">Your new document has not yet been published.  Do you wish to publish this document now?</p>
                </div>
                <div className="form__footer mt-5">
                    {error ? (
                        <Column className="text-center"><p className="text-center mb-3">{error}</p></Column>
                    ) : null}
                    <Row className="justify-content-center">
                        <BasicButton
                          buttonText="I'll do it later" className="btn--transparent order-1 order-sm-0" onClick={onSkipPublish}
                          disabled={isPublishing}
                        />
                        <BasicButton buttonText="Publish now" onClick={onPublish} disabled={isPublishing} />
                    </Row>
                </div>
            </div>
        );
    }
}
