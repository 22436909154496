// import propTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cn from 'classnames';

export default class DisplayButton extends PureComponent {
    static displayName = 'DisplayButton';

    static propTypes = {};

    render() {
        const { props: { className, buttonText, dataValue, ...rest } } = this;
        return (
            <button
              type="button" className={cn('btn--display d-flex', className)}
              {...rest}
            >
                <p className="mx-3">{buttonText}</p>
                <span className="btn--display__value bold">
                    {dataValue}
                </span>
            </button>
        );
    }
}
